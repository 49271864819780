import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody,
    MDBBtn, MDBIcon, MDBBtnGroup, toast, ToastContainer
} from "../../../mdbreact";
import { loadCommittees } from '../../../store/actions/settingAction';
import { loadUsers } from '../../../store/actions/authAction';
import {
    loadCommitteeAccessByCommittee,
    loadCommitteeAccessByUser,
    insertCommitteeAccess,
    deleteCommitteeAccess
} from '../../../store/actions/committeeAccessAction';

let timer = 0;
let delay = 200;
let prevent = false;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCommitteeLoad: false,
            isUserLoad: false,
            committeeColor: 'indigo',
            committeeActiveColor: 'unique',
            committeeIdActive: null,
            userColor: 'cyan',
            userActiveColor: 'unique',
            memberIdActive: null,
            committeeAccessColor: 'purple',
            isStateChange: false,
            committeeId: null,
            memberId: null,
        }
    }

    componentDidMount() {
        this.props.loadCommittees();
        this.props.loadUsers();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.committeeAccess.statusCode == 200 && this.state.isStateChange == true) {
            toast.success(nextProps.committeeAccess.message, {
                closeButton: true
            });
            this.setState({
                ...this.state,
                isStateChange:false
            })
        }
    }

    committeClickHandler = (id, event) => {
        this.setState({
            ...this.state,
            committeeId: id,
            committeeIdActive: id,
            isCommitteeLoad: false,
            isUserLoad: true,
        });
        this.props.loadCommitteeAccessByCommittee(id);
    }

    userClickHandler = (id, event) => {
        let me = this;
        timer = setTimeout(function () {
            if (!prevent) {
                me.setState({
                    ...this.state,
                    memberId: id,
                    memberIdActive: id,
                    isUserLoad: false,
                    isCommitteeLoad: true
                });
                me.props.loadCommitteeAccessByUser(id);
            }
            prevent = false;
        }, delay);
    }

    userDoubleClick(id, event) {
        clearTimeout(timer);
        prevent = true;

        if (!this.state.committeeId) {
            toast.error('Please select a committee!', {
                closeButton: true
            });
            return;
        }

        this.setState({
            ...this.state,
            memberId: id,
            memberIdActive: id,
            isUserLoad: false,
            isCommitteeLoad: true,
            isStateChange: true
        });
        let access = {
            committeeId: this.state.committeeId,
            memberId: id,
            entryBy: this.props.auth.user.userId
        }
        this.props.insertCommitteeAccess(access);        
    }

    deleteHandler = event => {
        this.state.isStateChange = true;
        this.props.deleteCommitteeAccess(event);
    }

    render() {
        let { committeeIdActive, committeeActiveColor, committeeColor,
            userColor, userActiveColor, memberIdActive, committeeAccessColor,
            isCommitteeLoad, isUserLoad } = this.state;

        return (
            <div id='committeeAccessDashboard'>

                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Committee List</MDBCardHeader>
                            <MDBCardBody>
                                {
                                    this.props.setting.committees &&
                                    this.props.setting.committees.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.committeeId == committeeIdActive ? committeeActiveColor : committeeColor}
                                                id={c.committeeId}
                                                key={c.committeeId}
                                                onClick={this.committeClickHandler.bind(this, c.committeeId)}
                                            >
                                                {c.committeeName}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">User List</MDBCardHeader>
                            <MDBCardBody>
                                {
                                    this.props.auth.users &&
                                    this.props.auth.users.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.userId == memberIdActive ? userActiveColor : userColor}
                                                id={c.userId}
                                                key={c.userId}
                                                onClick={this.userClickHandler.bind(this, c.userId)}
                                                onDoubleClick={this.userDoubleClick.bind(this, c.userId)}
                                            >
                                                {c.name}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Committee Access</MDBCardHeader>
                            <MDBCardBody>
                                {
                                    isCommitteeLoad &&
                                    this.props.committeeAccess.committeeAccess &&
                                    this.props.committeeAccess.committeeAccess.map((c, index) => {
                                        return (
                                            <MDBBtnGroup key={index}>
                                                <MDBBtn
                                                    color={committeeAccessColor}
                                                    id={c.committeeAccessId}
                                                    className="btnCommitteeAccess"
                                                >
                                                    {c.committeeName}
                                                </MDBBtn>
                                                <MDBBtn color="danger"
                                                    className="btnCommitteeAccessDelete"
                                                    onClick={this.deleteHandler.bind(this, c.committeeAccessId)}
                                                >
                                                    <MDBIcon icon="trash" />
                                                </MDBBtn>
                                            </MDBBtnGroup>
                                        )
                                    })

                                }
                                {
                                    isUserLoad &&
                                        this.props.committeeAccess.committeeAccess &&
                                        this.props.committeeAccess.committeeAccess.map((c, index) => {
                                            return (
                                                <MDBBtnGroup key={index}>
                                                    <MDBBtn
                                                        color={committeeAccessColor}
                                                        id={c.committeeAccessId}
                                                        className="btnCommitteeAccess"
                                                    >
                                                        {c.name}
                                                    </MDBBtn>
                                                    <MDBBtn color="danger"
                                                        className="btnCommitteeAccessDelete"
                                                        onClick={this.deleteHandler.bind(this, c.committeeAccessId)}
                                                    >
                                                        <MDBIcon icon="trash" />
                                                    </MDBBtn>
                                                </MDBBtnGroup>
                                            )
                                        })

                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <ToastContainer
                        hideProgressBar={false}
                        newestOnTop={true}
                        autoClose={2000}
                    />
                </MDBRow>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting,
    committeeAccess: state.committeeAccess
})

export default connect(mapStateToProps, {
    loadCommittees,
    loadUsers,
    loadCommitteeAccessByCommittee,
    loadCommitteeAccessByUser,
    insertCommitteeAccess,
    deleteCommitteeAccess
})(Dashboard);