import '@fortawesome/fontawesome-free/css/all.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import 'bootstrap-css-only/css/bootstrap.min.css';
import "./assets/css/normalize.css";
import "./assets/css/common.css";
import './mdbreact/assets/mdb.css';
import "./assets/css/admin_style.css";
import "./assets/css/style.css";

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import * as Types from './store/actions/types';
import AuthHeaderToken from './utils/setAuthToken';
import registerServiceWorker from './registerServiceWorker';

const token = localStorage.getItem('auth_token');
if (token) {
    let auth_token = JSON.parse(token);
    AuthHeaderToken(token);
    store.dispatch({
        type: Types.SET_USER,
        payload: {
            user: auth_token
        }
    })
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();