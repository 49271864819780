import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBBtn,
    MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,
    MDBInputGroup, MDBInput, toast, ToastContainer, MDBBtnGroup, MDBIcon
} from "../../../mdbreact";
import {
    loadCommittees,
    loadMemberFields,
    loadThanas,
    loadCountries,
    loadOccupations,
    loadUnions,
    loadMunicipalities,
    loadUpazilas
} from '../../../store/actions/settingAction';
import {
    loadRelationsByCommittee,
    loadRelationsByMemberField,
    loadRelationsByCommitteeMemberField,
    insertCommitteeRelation,
    deleteCommitteeRelation
} from '../../../store/actions/committeeAccessAction';

let timer = 0;
let delay = 200;
let prevent = false;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            committeeColor: 'indigo',
            committeeActiveColor: 'unique',
            committeeIdActive: null,
            fieldColor: 'cyan',
            fieldActiveColor: 'unique',
            fieldValueColor: 'purple',
            fieldIdActive: null,
            committeeId: null,
            fieldId: null,
            modal: false,
            membeFieldCheckedValue: [],
            isStateChange : false
        };
    }

    componentDidMount() {
        this.props.loadCommittees();
        this.props.loadMemberFields();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.committeeAccess.statusCode == 200 && this.state.isStateChange == true) {
            toast.success(nextProps.committeeAccess.message, {
                closeButton: true
            });
            this.setState({
                ...this.state,
                membeFieldCheckedValue:[],
                modal: false,
                isStateChange:false
            })
        }
    }

    toggle = nr => () => {
        //let modalNumber = 'modal' + nr
        this.setState({
            modal: false
        });
    }

    committeClickHandler = (id, event) => {
        this.setState({
            ...this.state,
            committeeId: id,
            committeeIdActive: id
        });
        this.props.loadRelationsByCommittee(id);
    }

    fieldClickHandler = (id, event) => {
        let me = this;
        //console.log(me.state);
        //return;
        timer = setTimeout(function () {
            if (!prevent) {
                me.setState({
                    ...this.state,
                    fieldId: id,
                    fieldIdActive: id
                });
                if (me.state.committeeId != null) {
                    me.props.loadRelationsByCommitteeMemberField(me.state.committeeId,id);
                } else {
                    me.props.loadRelationsByMemberField(id);
                }
            }
            prevent = false;
        }, delay);        
    }

    handleDoubleClick(id, event) {
        clearTimeout(timer);
        prevent = true;

        if (id == 1)
            this.props.loadThanas();
        else if (id == 2)
            this.props.loadUpazilas();
        else if (id == 3)
            this.props.loadUnions();
        else if (id == 4)
            this.props.loadCountries();
        else if (id == 11)
            this.props.loadMunicipalities();
        else if (id == 12)
            this.props.loadOccupations();

        this.setState({
            ...this.state,
            fieldId: id,
            fieldIdActive: id,
            modal:true
        })
    }

    changeHandler = event => {
        let values = this.state.membeFieldCheckedValue;
        let filterValue = values.filter(c => c !== event.target.value)
        if (event.target.checked)
            filterValue.push(event.target.value);

        this.setState({
            ...this.state,
            membeFieldCheckedValue: filterValue
        });
    }

    validationHandler = () => {
        let error = true;
        if (!this.state.committeeId) {
            toast.error('Please select a committee!', {
                closeButton: true
            });
            return error;
        }
        if (this.state.membeFieldCheckedValue.length == 0) {
            toast.error('member field value is not found!', {
                closeButton: true
            });
            return error;
        }
        return error = false;
    }

    handleSubmission = () => {
        let error = this.validationHandler();
        if (error)
            return;
        let { committeeId, fieldId, membeFieldCheckedValue } = this.state;
        let userId = this.props.auth.user.userId;
        membeFieldCheckedValue = membeFieldCheckedValue.map(c => {
            return {
                committeeId: committeeId,
                fieldId: fieldId,
                fieldValueId: parseInt(c),
                entryBy: userId
            }
        });
        this.state.isStateChange = true;
        this.props.insertCommitteeRelation(membeFieldCheckedValue);
    }

    deleteHandler = event => {
        this.state.isStateChange = true;
        this.props.deleteCommitteeRelation(event);
    }

    render() {
        let { fieldId, committeeIdActive, committeeActiveColor, committeeColor,
            fieldIdActive, fieldActiveColor, fieldColor, fieldValueColor } = this.state;
        return (            
            <div id='committeeRelationDashboard'>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4" className="committeeRelation">

                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Committee List</MDBCardHeader>
                            <MDBCardBody>
                                {
                                    this.props.setting.committees &&
                                    this.props.setting.committees.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.committeeId == committeeIdActive ? committeeActiveColor : committeeColor}
                                                id={c.committeeId}
                                                key={c.committeeId}
                                                onClick={this.committeClickHandler.bind(this, c.committeeId)}
                                            >
                                                {c.committeeName}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>

                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4" className="committeeRelation">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Member Field </MDBCardHeader>
                            <MDBCardBody>
                                {
                                    this.props.setting.fields &&
                                    this.props.setting.fields.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.fieldId == fieldIdActive ? fieldActiveColor : fieldColor}
                                                id={c.fieldId}
                                                key={c.fieldId}
                                                onClick={this.fieldClickHandler.bind(this, c.fieldId)}
                                                onDoubleClick={this.handleDoubleClick.bind(this, c.fieldId)}
                                            >
                                                {c.fieldName}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Committee & Member Field Relationship</MDBCardHeader>
                            <MDBCardBody>
                                {
                                    this.props.committeeAccess.fieldValues &&
                                    this.props.committeeAccess.fieldValues.map((c,index) => {
                                        return (
                                            <MDBBtnGroup key={index}>
                                                <MDBBtn
                                                    color={fieldValueColor}
                                                    id={c.fieldValueId}
                                                    className="btnCommitteeAccess"
                                                >
                                                    {c.fieldValueName}
                                                </MDBBtn>
                                                <MDBBtn color="danger"
                                                    className="btnCommitteeAccessDelete"
                                                    onClick={this.deleteHandler.bind(this,c.id)}
                                                >
                                                    <MDBIcon icon="trash" />
                                                </MDBBtn>
                                            </MDBBtnGroup>                                           
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                </MDBRow>

                {
                /*******MODAL*********/
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle()} fullHeight position="right">
                        <MDBModalHeader toggle={this.toggle()} className="primary-color-dark">Member Field Value List</MDBModalHeader>
                        <MDBModalBody className="memberFieldBtn">
                            {
                                fieldId == 1 ?
                                    this.props.setting.thanas &&
                                    this.props.setting.thanas.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.thanaId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.thanaId}
                                                    >
                                                        {c.thana}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.thanaId}
                                                            value={c.thanaId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />
                                        )
                                    })
                                    : null
                            }
                            {
                                fieldId == 2 ?
                                    this.props.setting.upazilas &&
                                    this.props.setting.upazilas.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.upazilaId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.upazilaId}                                                        
                                                    >
                                                        {c.upazilaName}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.upazilaId}
                                                            value={c.upazilaId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />                                          
                                        )
                                    })                                       
                                : null
                            }
                            {
                                fieldId == 3 ?
                                    this.props.setting.unions &&
                                    this.props.setting.unions.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.unionId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.unionId}                                                        
                                                    >
                                                        {c.unionName}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.unionId}
                                                            value={c.unionId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />                                          
                                        )
                                    })                                       
                                : null
                            }
                            {
                                fieldId == 4 ?
                                    this.props.setting.countries &&
                                    this.props.setting.countries.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.countryId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.countryId}                                                        
                                                    >
                                                        {c.countryName}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.countryId}
                                                            value={c.countryId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />                                          
                                        )
                                    })                                       
                                : null
                            }
                            {
                                fieldId == 11 ?
                                    this.props.setting.municipalities &&
                                    this.props.setting.municipalities.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.municipalityId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.municipalityId}                                                        
                                                    >
                                                        {c.municipalityName}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.municipalityId}
                                                            value={c.municipalityId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />                                          
                                        )
                                    })                                       
                                : null
                            }
                            {
                                fieldId == 12 ?
                                    this.props.setting.occupations &&
                                    this.props.setting.occupations.map(c => {
                                        return (
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0"
                                                key={c.occupationId}
                                                inputs={
                                                    <MDBBtn
                                                        color={fieldColor}
                                                        id={c.occupationId}                                                        
                                                    >
                                                        {c.occupation}
                                                    </MDBBtn>
                                                }
                                                prepend={
                                                    <div className="input-group-text md-addon">
                                                        <MDBInput
                                                            label
                                                            filled
                                                            type="checkbox"
                                                            id={'checkboxMaterial' + c.occupationId}
                                                            value={c.occupationId}
                                                            onChange={this.changeHandler}
                                                        />
                                                    </div>
                                                }
                                            />                                          
                                        )
                                    })                                       
                                : null
                            }
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggle(8)}>Close</MDBBtn>
                            <MDBBtn color="success" onClick={this.handleSubmission}>Save changes</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                }
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting,
    committeeAccess: state.committeeAccess
})

export default connect(mapStateToProps,
    {
        loadCommittees,
        loadMemberFields,
        loadThanas,
        loadCountries,
        loadOccupations,
        loadUnions,
        loadMunicipalities,
        loadUpazilas,
        loadRelationsByCommittee,
        loadRelationsByMemberField,
        loadRelationsByCommitteeMemberField,
        insertCommitteeRelation,
        deleteCommitteeRelation
    })(Dashboard);