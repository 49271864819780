import * as Types from '../actions/types';
const init = {
    isAuthenticated: false,
    user: {},
    error: {}
} 
const authReucer = (state = [], action) => {
    switch (action.type) {
        case Types.SET_USER: {
            return {
                ...state,
                user: action.payload.user,
                isAuthenticated: Object.keys(action.payload.user).length !== 0 ? true : false,
                error: {},
                statusCode: action.payload.user.statusCode
            }
        }
        case Types.USERS_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                status: action.payload.status
            }
        }
        case Types.LOAD_USERS: {
            return {
                ...state,
                users: action.payload.users
            }
        }
        case Types.LOAD_USER:  {
            return {
                ...state,
                user: action.payload.user
            }
        }
        case Types.REGISTER_USER: {
            let users = [...state.users];
            let filterUsers = users.filter(c => c.userId !== action.payload.user.userId);
            filterUsers.unshift(action.payload.user);
            return {
                ...state,
                users: filterUsers,
                statusCode: action.payload.user.statusCode
            }
        }
        //case types.update_user: {
        //    return {
        //        user: action.payload.user,
        //        statuscode: action.payload.user.statuscode
        //    }
        //}
        default: return state
    }
}
export default authReucer