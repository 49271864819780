import * as Types from './types';
import Axios from 'axios';

export const insertDesignation = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveDesignation', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_DESIGNATION,
                payload: {
                    designation: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadDesignations = () => dispatch => {
    Axios.get('api/Setting/GetDesignation')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_DESIGNATIONS,
                payload: {
                    designations: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertCommittee = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveCommittee', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_COMMITTEE,
                payload: {
                    committee: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadCommittees = () => dispatch => {
    Axios.get('api/Setting/GetCommittees')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_COMMITTEES,
                payload: {
                    committees: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertOccupation = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveOccupation', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_OCCUPATION,
                payload: {
                    occupation: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadOccupations = () => dispatch => {
    Axios.get('api/Setting/GetOccupations')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_OCCUPATIONS,
                payload: {
                    occupations: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertCountry = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveCountry', data)
        .then(response => {
            dispatch({
                type: Types.SAVE_COUNTRY,
                payload: {
                    country: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadCountries = () => dispatch => {
    Axios.get('api/Setting/GetCountries')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_COUNTRIES,
                payload: {
                    countries: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertUpazila = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveUpazila', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_UPAZILA,
                payload: {
                    upazila: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadUpazilas = () => dispatch => {
    Axios.get('api/Setting/GetUpazilas')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_UPAZILAS,
                payload: {
                    upazilas: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertUnion = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveUnion', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_UNION,
                payload: {
                    union: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadUnions = () => dispatch => {
    Axios.get('api/Setting/GetUnions')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_UNIONS,
                payload: {
                    unions: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadUnionsByUpazila = (uazilaId) => dispatch => {
    Axios.get('api/Setting/GetUnionsByUpazila/' + uazilaId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_UNIONS_BY_UPAZILA,
                payload: {
                    unions: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertMunicipality = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveMunicipality', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_MUNICIPALITY,
                payload: {
                    municipality: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadMunicipalities = () => dispatch => {
    Axios.get('api/Setting/GetMunicipalities')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_MUNICIPALITIES,
                payload: {
                    municipalities: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadMunicipalitiesByDistrict = () => dispatch => {
    Axios.get('api/Setting/GetMunicipalitiesByDistrict')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_MUNICIPALITIES_BY_DISTRICT,
                payload: {
                    municipalities: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertThana = (data) => dispatch => {
    //console.log(data);
    //return;
    Axios.post('api/Setting/SaveThana', data)
        .then(response => { 
            dispatch({
                type: Types.SAVE_THANA,
                payload: {
                    thana: response.data,
                    statusCode: response.data.statusCode
                }
            });
        })
        .catch(error => {
            if (error.response) {
                this.errors(error.response.message);
            } else if (error.request) {
                console.log('error.request');
            } else {
                console.log('Error', error);
            }
            console.log("rejected");
        })
}

export const loadThanas = () => dispatch => {
    Axios.get('api/Setting/GetThanas')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_THANAS,
                payload: {
                    thanas: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadThanasByDistrict = () => dispatch => {
    Axios.get('api/Setting/GetThanasByDistrict')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_THANAS_BY_DISTRICT,
                payload: {
                    thanas: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadMemberFields = () => dispatch => {
    Axios.get('api/Setting/GetMemberFields')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_MEMBER_FIELDS,
                payload: {
                    fields: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

