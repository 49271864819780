import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from "../../../mdbreact";
import Create from './Create';
import List from './List';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            parentObject: null
        }
        this.editHandler = this.editHandler.bind(this);
    }

    /*=======================
     * ACTION TEMPLATE
     * =====================*/

    editHandler(id, childObjects) {
        let childObject = childObjects.filter(d => d.countryId === id);
        this.setState({
            ...this.state,
            parentObject: childObject[0]
        });
    }

    render() {
        return (
            <div id='countryDashboard'>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="6">
                        <Create updateObject={this.state.parentObject} />
                    </MDBCol>
                    <MDBCol size="12" sm="12" lg="6">
                        <List editHandler={this.editHandler} />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Dashboard);