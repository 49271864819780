import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FrontendLayout } from './layout/FrontendLayout';
import NavMenu from './menu/NavMenu';
import Home from "./pages/public/Home";
import Footer from "./pages/public/Footer";
import Registration from "./pages/public/Registration";
import Login from './pages/public/Login';
import Page from './pages/public/Page';
import Committee from './pages/public/committee/Committee';
import Donar from './pages/public/Donar/Donar';
import Contact from './pages/public/Contact';
import Mission from './pages/public/Mission';

class PublicRoutes extends Component {
    render() {        
        return (
            <React.Fragment>
                <div id="clientApp">
                    <NavMenu history={this.props.history}/>
                    <FrontendLayout>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route path='/login' component={Login} />
                            <Route path='/registration' component={Registration} />
                            <Route path='/page/:slug' component={Page} />
                            <Route path='/committee/:cid' component={Committee} />
                            <Route path='/donar' component={Donar} />
                            <Route path='/mission' component={Mission} />
                            <Route path='/contact' component={Contact} />
                        </Switch>
                    </FrontendLayout>
                    <div className="clearfix"></div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}
export default PublicRoutes;