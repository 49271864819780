import React from 'react';
import '../../../lib/chosen/chosen.jquery';
import '../../../lib/chosen/chosen.css';
const Chosen = (props) => {
    const elmt = React.useRef()

    React.useLayoutEffect(() => {
        const $elmt = window.$(elmt.current)
        const handleChange = (e) => {
            props.onChange(e);
        }
        $elmt.chosen({width:'100%'})
        $elmt.on('change', handleChange)
        $elmt.trigger("chosen:updated")
        $elmt.trigger("chosen:updated")

        return () => {
            $elmt.off('change', handleChange);
            $elmt.chosen('destroy');
            //console.log("unmounted")
        }
    }, [props.children])

    return (
        <div>
            <select className="chosen" ref={elmt} name={props.name}>
                {props.children}
            </select>
            {/* <select className="Chosen-select" ref={elmt} name={props.name}>
            
          </select> */}
        </div>
    )
}
export default Chosen;