import * as Types from '../actions/types';

const greetingCardReducer = (state = [], action) => {
    switch (action.type) {
        case Types.LOAD_CARDS: {
            return {
                cards: action.payload.cards,
            }
        }
        case Types.SAVE_CARD: {
            let message = null;
            let filerCards = [];

            if (action.payload.card.statusCode === 200) {
                let cards = [...state.cards];
                filerCards = cards.filter(c => c.cardId !== action.payload.card.cardId);
                filerCards.unshift(action.payload.card);
                message = filerCards.length !== cards.length
                                            ? 'Greeting card saved successfully.' 
                                            : 'Greeting card updated successfully.';
            }
            return {
                cards: filerCards,
                statusCode: action.payload.card.statusCode,
                message: message,
            }
        }
        default: return state;
    }
}
export default greetingCardReducer;
