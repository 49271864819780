import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter,
    MDBInput, MDBCard, MDBCardBody, MDBCardUp, MDBAvatar, toast, ToastContainer
} from '../../mdbreact';

import Logo from '../../assets/images/Logo.png';
import { login } from '../../store/actions/authAction';

class Login extends Component {
    state = {
        userName: '',
        password: '',
        isLogin: false,
        message: '',
        isSent: false,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.auth.isAuthenticated && this.state.isSent == true) {
            this.setState({
                ...this.state,
                isSent: false
            })
            toast.error('Login failed.Try again later!', {
                closeButton: true
            });
        }
    }

    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }
    submitHandler = () => {
        let { userName, password } = this.state;
        this.state.isSent = true;
        this.props.login({ userName, password }, this.props.history);
    }
    render() {
        return (
            <div>
            <MDBModal isOpen={this.props.openModal} toggle={this.props.toggle(2)}>
                <MDBModalHeader toggle={this.props.toggle(2)}></MDBModalHeader>
                <MDBModalBody>
                    <MDBCard testimonial>
                        <MDBCardUp gradient='aqua' />
                        <MDBAvatar className='mx-auto white'>
                            <img
                                src={Logo}
                                alt=''
                            />
                        </MDBAvatar>
                        <MDBCardBody>
                            <h4 className='card-title'>User Login</h4>
                            <hr />
                            <MDBInput label="User Name"
                                name="userName"
                                className="mt-4"
                                value={this.state.userName}
                                onChange={this.changeHandler}
                            />
                            <MDBInput label="Password"
                                type="password"
                                name="password"
                                className="mt-4"
                                value={this.state.password}
                                onChange={this.changeHandler}
                            />
                            <MDBBtn color="primary" onClick={this.submitHandler}>Login</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBModalBody>
                </MDBModal>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />

                </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(mapStateToProps, { login })(Login);
