import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBContainer, MDBView, MDBRow, MDBCol, MDBIcon
} from '../../mdbreact';

class Contact extends Component {
    state = {
    }
    changeHandler = event => {
    }
    submitHandler = () => {
    }
    render() {
        return (
            <MDBView className="contactWrapper">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol>
                            <div id="contact">
                                <ul>
                                    <li>
                                        <MDBIcon icon="map-marker-alt"/> নবাব সলিমুল্লাহ রোড,হকার’স মার্কেট, ৮ নং গলি, চাষারা, নারায়ণগঞ্জ।
                                    </li>
                                    <li>
                                        <MDBIcon icon="mobile-alt"/> +৮৮০ ১৬৪৩-৫৫৭৯০৯, +৮৮০ ১৮১৭-২৮৯৯৬১, +৯৬৬ ৫৬ ৬৫২-৬০৭৭
                                    </li>
                                    <li>
                                        <MDBIcon far icon="envelope"/> narayanganjprobashi@gmail.com
                                    </li>
                                </ul>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBView>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(mapStateToProps)(Contact);
