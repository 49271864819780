import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody } from "../../../mdbreact";
import GenerateCard from '../greetingCard/Generate';
import { SearchForm } from '../../UIMaterial/Search';

const ApprovedList = ({ isLoading, members, member, searchTerm, modal,
    toggle,
    changeHandler,
    clickHandler,
    fbHandler,
    editHandler,
    downloadMembers,
    downloadProfileHandler,
    viewProfileHandler,
    greetingCardHandler }) => {

    /*=======================
     * PROFILE PIC TEMPLATE
     * =====================*/
    const profileTemplate = (rowData) => {
        let imgData = 'np_default_avatar.jpg';
        if (rowData.userProfileName)
            imgData = rowData.userProfileName;
        const src = window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + imgData;
        return (
            <>
                <img alt={"test purpose"} src={src} width="32" style={{ verticalAlign: 'middle', borderRadius: '50%', width: '50px', height: '50px', border: '3px solid rgba(233,230,230,1)' }} />
                <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.memberNameBd}</span>
            </>
        );
    }

    /*=======================
     * ACTION TEMPLATE
     * =====================*/

    const actionTemplate = (rowData, column) => {
        return <div id='admin_pending_btn'>
            <MDBBtn tag="a" size="sm" floating
                gradient="blue"
                onClick={() => fbHandler(rowData.fbLink)}>
                <MDBIcon fab icon="facebook" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                gradient="aqua"
                onClick={() => editHandler(rowData.memberId)}>
                <MDBIcon icon="edit" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                className="ripe-malinka-gradient"
                onClick={() => downloadProfileHandler(rowData.memberId)}>
                <MDBIcon far icon="file-pdf" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                gradient="peach"
                onClick={() => viewProfileHandler(rowData.memberId)}>
                <MDBIcon icon="user-tie" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                gradient="aqua"
                onClick={() => greetingCardHandler(rowData)}>
                <MDBIcon far icon="address-card" />
            </MDBBtn>
        </div>;
    }

    return (
        <div>
            {
                //Generate Greeting Card
                <MDBModal isOpen={modal} toggle={toggle} centered size="lg">
                    <MDBModalHeader toggle={toggle}>Generate Greeting Card</MDBModalHeader>
                    <MDBModalBody>
                        <GenerateCard memberProfile={member} memberList={members} />
                    </MDBModalBody>
                </MDBModal>
            }

            <MDBCard wide >
                <MDBCardHeader color="success-color">
                    <div className="listTitle"><h3>Approved Member List</h3></div>
                    <div className="searchContainer">
                        <div style={{ width: '90%', float: 'left' }}>
                            <SearchForm
                                placeholder="Search member..."
                                searchTerm={searchTerm}
                                isSearch={true}
                                changeHandler={changeHandler}
                                searchHandler={clickHandler}
                            />
                        </div>
                        <span onClick={downloadMembers} style={{ float: "right", cursor: "pointer" }} >
                            <img src={require('../../../assets/images/download.png')} alt="Download member list" />
                        </span>
                    </div>
                </MDBCardHeader>
                <MDBCardBody>
                    {
                        isLoading === true ? (
                            <div><p>Waiting until data loading...</p></div>
                        ) :
                            members &&
                                members.length > 0 ? (
                                <DataTable value={members} paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                    responsive={true}>
                                    <Column field="memberNameBd" header="" body={profileTemplate} headerStyle={{ width: '17em' }} />
                                    <Column field="memberCode" header="ID" />
                                    <Column field="thana" header="Thana" />
                                    <Column field="immigrationCountry" header="Country" />
                                    <Column field="mobileNo" header="Mobile No" />
                                    <Column field="occupation" header="Occupation" />
                                    <Column field="thana" header="" body={actionTemplate} headerStyle={{ width: '22em' }} />
                                </DataTable>
                            ) : <div><p>No, Member records found!</p></div>
                    }
                </MDBCardBody>
            </MDBCard>
        </div>
    );
};

export default ApprovedList;