import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    MDBTabPane, MDBTabContent, MDBNav, MDBNavItem,
    MDBNavLink, MDBIcon
} from "../../../mdbreact";
import Create from './Create';
import Generate from './Generate';
import List from './List';
import { getMembersBasicInfo } from '../../../store/actions/memberAction';

class GreetingCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeItemClassicTabs3: "1",
            memberList: null //this used for 'generate' component used member list  
        }
    }
    componentDidMount() {
        this.props.getMembersBasicInfo();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {        
        if (nextProps.member.members) {
            this.state.memberList = nextProps.member.members;
        }
    }

    toggleClassicTabs3 = tab => () => {
        if (this.state.activeItemClassicTabs3 !== tab) {
            this.setState({
                activeItemClassicTabs3: tab
            });
        }
    }

    render() {
        
        return (
            <div id="greetingCard">
                <div className="classic-tabs">
                    <MDBNav classicTabs color="green" className="mt-5">
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "1"} onClick={this.toggleClassicTabs3("1")}>
                                <MDBIcon icon="chalkboard-teacher" size="2x" />
                                <br />
                                Greeting Cards
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "2"} onClick={this.toggleClassicTabs3("2")}>
                                <MDBIcon icon="user" size="2x" />
                                <br />
                                New Greeting Card
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "3"} onClick={this.toggleClassicTabs3("3")}>
                                <MDBIcon fas icon="plane-departure" size="2x" />
                                <br />
                                Generate Greeting Card
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent className="card mb-5" activeItem={this.state.activeItemClassicTabs3}>
                        <MDBTabPane tabId="1">
                            <List/>
                        </MDBTabPane>
                        <MDBTabPane tabId="2">
                            <Create/>
                        </MDBTabPane>
                        <MDBTabPane tabId="3">
                            <Generate memberList={this.state.memberList}/>
                        </MDBTabPane>
                    </MDBTabContent>
                </div>
                
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer
});

export default connect(mapStateToProps, { getMembersBasicInfo })(GreetingCard);