import * as Types from '../actions/types';

const memberReducer = (state = [],action) => {

    switch (action.type) {
        case Types.LOAD_PENDING_MEMBERS: {
            return {
                members: action.payload.members
            }
        }
        case Types.LOAD_PENDING_MEMBER: {
            return {
                members: action.payload.members
            }
        }
        case Types.UPDATE_PENDING_MEMBER: {
            return {
                members: [...state.members],
                statusCode: action.payload.statusCode,
                isSuccess: action.payload.isSuccess,
                message: action.payload.message,
            }
        }
        case Types.LOAD_APPROVED_MEMBERS: {
            return {
                members: action.payload.members
            }
        }
        case Types.LOAD_APPROVED_MEMBER: {
            return {
                members: action.payload.members
            }
        }
        case Types.UPDATE_APPROVED_MEMBER: {
            return {
                members: [...state.members],
                statusCode: action.payload.statusCode,
                isSuccess: action.payload.isSuccess,
                message: action.payload.message,
            }
        }
        case Types.LOAD_DONAR_MEMBERS: {
            return {
                members: action.payload.members
            }
        }
        case Types.LOAD_MEMBERS_BASIC_INFO: {
            return {
                ...state,
                members: action.payload.members
            }
        }
        case Types.LOAD_MEMBER_DESIGNATIONS: {
            return {
                ...state,
                members: [...state.members],
                designations: action.payload.designations
            }
        }
        case Types.STORE_MEMBER_GREETING_CARD: {
            return {
                ...state,
                member: action.payload.member
            }
        }
        default: return state;
    }
}
export default memberReducer;