import * as Types from './types';
import Axios from 'axios';

export const loadRelationsByCommittee = (committeeId) => dispatch => {
    //console.log(userId);
    Axios.get('api/CommitteeAccess/GetRelationsByCommittee/' + committeeId)
        .then(response => {
            dispatch({
                type: Types.LOAD_COMMITTEE_RELATION,
                payload: {
                    fieldValues: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadRelationsByMemberField = (fieldId) => dispatch => {
    //console.log(userId);
    Axios.get('api/CommitteeAccess/GetRelationsByMemberField/' + fieldId)
        .then(response => {
            dispatch({
                type: Types.LOAD_COMMITTEE_RELATION,
                payload: {
                    fieldValues: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadCommitteeAccessByUser = (valueId) => dispatch => {
    //console.log(userId);
    Axios.get('api/CommitteeAccess/GetCommitteeAccessByUser/' + valueId)
        .then(response => {
            dispatch({
                type: Types.LOAD_COMMITTEE_ACCESS,
                payload: {
                    committeeAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadCommitteeAccessByCommittee = (valueId) => dispatch => {
    //console.log(userId);
    Axios.get('api/CommitteeAccess/GetCommitteeAccessByCommittee/' + valueId)
        .then(response => {
            dispatch({
                type: Types.LOAD_COMMITTEE_ACCESS,
                payload: {
                    committeeAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadRelationsByCommitteeMemberField = (committeeId,fieldId) => dispatch => {
    //console.log(userId);
    Axios.get('api/CommitteeAccess/GetRelationsByCommitteeMemberField/' + committeeId + '/' + fieldId)
        .then(response => {
            dispatch({
                type: Types.LOAD_COMMITTEE_RELATION,
                payload: {
                    fieldValues: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertCommitteeRelation = (committeeRelation) => dispatch => {    
    Axios.post('api/CommitteeAccess/SaveCommitteeRelation', committeeRelation)
        .then(response => {
            dispatch({
                type: Types.SAVE_COMMITTEE_RELATION,
                payload: {
                    fieldValues: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const deleteCommitteeRelation = (id) => dispatch => {
    Axios.delete('api/CommitteeAccess/DeleteCommitteeRelation/' + id)
        .then(response => {
            dispatch({
                type: Types.DELETE_COMMITTEE_RELATION,
                payload: {
                    id: id,
                    isDelete: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertCommitteeAccess = (committeeAccess) => dispatch => {    
    //console.log(committeeAccess);
    Axios.post('api/CommitteeAccess/SaveCommitteeAccess', committeeAccess)
        .then(response => {
            dispatch({
                type: Types.SAVE_COMMITTEE_ACCESS,
                payload: {
                    committeeAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const deleteCommitteeAccess = (id) => dispatch => {
    Axios.delete('api/CommitteeAccess/DeleteCommitteeAccess/' + id)
        .then(response => {
            dispatch({
                type: Types.DELETE_COMMITTEE_ACCESS,
                payload: {
                    id: id,
                    isDelete: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}