import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
//import { BackendLayout } from './layout/BackendLayout';
import AdminNavMenu from './menu/AdminNavMenu';
import Dashboard from './pages/private/Dashboard';
// import PendingMember from './components/private/member/PendingController';
// import ApprovedMember from './components/private/member/ApprovedController';
// import UpdatePendingMember from './components/private/member/UpdatePendingMember';
// import UpdateApprovedMember from './components/private/member/UpdateApprovedMember';
// import Committee from './components/private/committee';
// import Country from './components/private/country';
// import Designation from './components/private/designation';
// import Donation from './components/private/donation';
// import GreetingCard from './components/private/greetingCard';
// import Municipality from './components/private/municipality';
// import MembershipCard from './components/private/membershipCard/Generate';
// import Occupation from './components/private/occupation';
// import Thana from './components/private/thana';
// import Upazila from './components/private/upazila';
// import Union from './components/private/union';
// import CommitteeRelation from './components/private/committeeRelation';
// import CommitteeAccess from './components/private/committeeAccess';
// import User from './components/private/user';
// import MyAccount from './components/private/user/MyAccount';
// import MenuAccess from './components/private/menuAcces';
// import { MediaController } from './components/private/media';
import { 
    PendingMember,
    ApprovedMember,
    UpdatePendingMember,
    UpdateApprovedMember,
    Committee,
    Country,
    Designation,
    Donation,
    GreetingCard,
    Municipality,
    MembershipCard,
    Occupation,
    Thana,
    Upazila,
    Union,
    CommitteeRelation,
    CommitteeAccess,
    User,
    MyAccount,
    MenuAccess,
    MediaController
} from './components/private';

class PrivateRoutes extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem('auth_token');
        if (!token) {
            props.history.push('./');
        }
    }
    render() {
        return (
            <React.Fragment>
                <AdminNavMenu history={this.props.history}>                    
                    <Switch>
                        <Route exact path="/admin" component={Dashboard} />
                        <Route path="/admin/pendingmember/:cid" component={PendingMember} />
                        <Route path="/admin/approvedmember/:cid" component={ApprovedMember} />
                        <Route path="/admin/updatependingmember/:id/:cid" component={UpdatePendingMember} />
                        <Route path="/admin/updateapprovedmember/:id/:cid" component={UpdateApprovedMember} />
                        <Route path="/admin/committee_setup" component={Committee} />
                        <Route path="/admin/country_setup" component={Country} />
                        <Route path="/admin/designation_setup" component={Designation} />
                        <Route path="/admin/member_donation" component={Donation} />
                        <Route path="/admin/greeting_card" component={GreetingCard} />
                        <Route path="/admin/occupation_setup" component={Occupation} />
                        <Route path="/admin/municipality_setup" component={Municipality} />
                        <Route path="/admin/thana_setup" component={Thana} />
                        <Route path="/admin/upazila_setup" component={Upazila} />
                        <Route path="/admin/union_setup" component={Union} />
                        <Route path="/admin/committee_relation" component={CommitteeRelation} />
                        <Route path="/admin/committee_access" component={CommitteeAccess} />
                        <Route path="/admin/user_management" component={User} />
                        <Route path="/admin/myAccount" component={MyAccount} />
                        <Route path="/admin/menuAccess" component={MenuAccess} />
                        <Route path="/admin/generateMembershipCard" component={MembershipCard} />
                        <Route path="/admin/media" component={MediaController} />
                    </Switch>
                </AdminNavMenu>
            </React.Fragment>
        );
    }
}
export default PrivateRoutes;