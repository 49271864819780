import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { SITE } from '../../components/common/setup';
import {
    MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle,
    MDBCol, MDBContainer, MDBIcon, MDBNavLink, MDBRow, MDBView
} from '../../mdbreact';
import { Link } from "react-router-dom";

const customCard = {
    border: "none"
}

class Home extends Component {
    
    render() {
        return (
            <div>
                <MDBView>
                    <Carousel
                        autoPlay={true}
                        showThumbs={false}
                        interval={2000}
                        infiniteLoop={true}
                        stopOnHover={true}
                        showStatus={false}>
                        {
                            //<div>
                            //    <img src={SITE.url + '/Uploads/Sliders/slider_01.jpg'} />
                            //</div>
                            //<div>
                            //    <img src={SITE.url + '/Uploads/Sliders/slider_02.jpg'} />
                            //</div>
                            //<div>
                            //    <img src={SITE.url + '/Uploads/Sliders/slider_03.jpg'} />
                            //</div>
                            //<div>
                            //    <img src={SITE.url + '/Uploads/Sliders/slider_04.jpg'} />
                            //</div>
                        }
                        <div>
                            <img src={SITE.url + '/Uploads/Sliders/Bannar1.jpg'} />
                        </div>
                        <div>
                            <img src={SITE.url + '/Uploads/Sliders/Bannar2.jpg'} />
                        </div>
                    </Carousel>
                </MDBView>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol size="12" sm="12" lg="12" md="12" xs="12">
                            <div className="profileWrapper">
                                <img src={SITE.url + '/Uploads/logo.png'} alt="profile" />
                                <div className="aboutUs">
                                    <p>বিশ্বের বিভিন্ন দেশে অবস্থানরত নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাস ফেরতদের সমন্বয়ে একটি অরাজনৈতিক সেবামূলক সংগঠন।
                                </p>
                                    <div id="about_home_icon">
                                        <MDBBtn size="sm" tag="a" floating social="fb">
                                            <a href="https://www.facebook.com/groups/201462730449425/">
                                                <MDBIcon fab icon="facebook-f" />
                                            </a>
                                        </MDBBtn>
                                        <MDBBtn tag="a" floating color="dark-green" size="sm">
                                            <Link to="/page/about_us">
                                                <MDBIcon icon="list-ul" className="mr-1" />
                                            </Link>
                                        </MDBBtn>
                                        <MDBBtn tag="a" floating color="purple" size="sm">
                                            <a href="/contact">
                                                <MDBIcon icon="map-marker-alt" />
                                            </a>
                                        </MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <MDBView className="sloganWrapper">
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol>
                                <h3>লক্ষ্য ও উদ্দেশ্য</h3>
                                <ul>
                                    <li>
                                        <p style={{ color: '#6b9602' }}>
                                            নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাস ফেরতদের সমন্বয়ে একটি অরাজনৈতিক সংগঠন গঠন করে প্রবাসী ও প্রবাস ফেরতদের কল্যাণে এবং
                                            রেমিট্যান্স যোদ্ধা স্বীকৃতি আদায়ের চেষ্টা করা।
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{ color: '#c10248' }}>
                                            নারায়ণগঞ্জ জেলার সাতটি থানার প্রতিটি থানায় প্রবাসী ও প্রবাস ফেরতদের সকল প্রকার সুযোগ সুবিধার জন্য যেমন- স্কুল, কলেজ,
                                        মাদ্রাসা, কমিউনিটি সেন্টার, বিনোদন সেন্টার, সরকারি হাসপাতাল, প্রাইভেট ক্লিনিক, ডায়াগনস্টিক সেন্টার গুলোতে আমাদের
                                        সংগঠনের মেম্বারদের বিশেষ সুযোগ সুবিধার জন্য সকল প্রকার চেষ্টা ও পদক্ষেপ গ্রহণ করা।
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{ color: '#019858' }}>
                                            অত্র সংগঠনের সদস্যগণ কোনরূপ হয়রানি অথবা জোর জুলুমের শিকার হলে তার জন্য ঐক্যবদ্ধভাবে সকলে তার পাশে থাকা এবং
                                           তাকে সহযোগিতা করা।
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{ color: '#580288' }}>
                                            প্রবাসী সদস্যদের প্রবাসের কর্মজীবন সম্বন্ধে সকল প্রকার দিক নির্দেশনা এবং সহযোগিতা করা।
                                        </p>
                                    </li>
                                    <li>
                                        <p style={{ color: '#1ab2b4' }}>
                                            নারায়ণগঞ্জ জেলার প্রবাসীদের জন্য জেলা প্রশাসকের বরাবর প্রবাসীদের ও প্রবাস ফেরতদের জন্য প্রশাসনিক হেল্পডেস্ক চালু করার জন্য আবেদন করা ও
                                            তা বাস্তবায়নে সক্রিয় ভূমিকা পালন করা।
                                        </p>
                                    </li>
                                </ul>
                                <MDBBtn href="/mission" rounded color="red" size="lg" className="readMore">আরও জানুন</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBView>               

                <MDBContainer>
                    <MDBRow>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/logo.png'} />
                                <a href="/committee/center_committee" style={{ padding: 0 }}><h3>কেন্দ্রীয় কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/sonargon.png'} />
                                <a href="/committee/637245730314622921" style={{ padding: 0 }}><h3>সোনারগাঁও থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/rupgonj.png'} />
                                <a href="/committee/637245730225240065" style={{ padding: 0 }}><h3>রূপগঞ্জ থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/sidirgonj.png'} />
                                <a href="/committee/637245730115235334" style={{ padding: 0 }}><h3>সিদ্ধিরগঞ্জ থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/sodor.png'} />
                                <a href="/committee/637245730029583428" style={{ padding: 0 }}><h3>সদর থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/bondor.png'} />
                                <a href="/committee/637245729723647182" style={{ padding: 0 }}><h3>বন্দর থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/faullah.png'} />
                                <a href="/committee/637245733079858935" style={{ padding: 0 }}><h3>ফতুল্লা থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                        <MDBCol size="12" sm="12" lg="3" md="6" xs="12">
                            <div className="committeeWrapper">
                                <img src={SITE.url + '/Uploads/arihazarpsd.png'} />
                                <a href="/committee/637245730369555158" style={{ padding: 0 }}><h3>আড়াইহাজার থানা কমিটি</h3></a>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer className="committee_title">
                    <MDBRow>
                        <MDBCol>
                            <h2>কেন্দ্রীয় কমিটি পরিচালনা পরিষদবৃন্দের বাণী</h2>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer>
                    <MDBRow className="cardWrapper">
                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/robiul">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/robi.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Robi Vai'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>রবিউল আলম</strong>
                                        <span>প্রতিষ্ঠাতা ও সভাপতি</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, আপনাদের অবগত করছি যে, আমরা নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাসী ফেরত সমন্বয় একটি অরাজনৈতিক
                                        সেবামূলক সংগঠন গঠন করেছি। বিশ্বের বিভিন্ন দেশে প্রবাসী ও দেশে ফেরত প্রবাসীদের কল্যাণে..............
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>
                                        <a href='!#' className='px-2 fa-lg fb-ic'>
                                            <a href="https://www.facebook.com/profile.php?id=100005135838861">
                                                <MDBIcon fab icon='facebook-f'></MDBIcon>
                                            </a>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/kajol_siddik">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/kajol_siddik.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Kajol Siddque'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>মোঃ কাজল সিদ্দিক</strong>
                                        <span>সাধারণ সম্পাদক,কেন্দ্রীয় কমিটি</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, মহাকালের জীবন স্রোতের কাছে "মানব জীবন" বুদবুদের মতোই ক্ষণস্থায়ী।
                                        পৃথিবীর সকল জীবকেই সময় নামক মহাযঞ্জে আত্নহুতি দিতে হবে। এটাই অমোঘ বিধান।
                                        জীবনের এই ক্ষুদ্র পরিসরে মানুষ নিজেকে বাঁচিয়ে ..............
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>

                                        <a href='https://www.facebook.com/mk.siddiq.31' className='px-2 fa-lg fb-ic'>
                                            <MDBIcon fab icon='facebook-f'></MDBIcon>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                                {
                                //<MDBCardBody cascade className='text-center'>
                                //    <MDBCardTitle className='card-title'>
                                //        <strong>ইকবাল হোসেন</strong>
                                //        <span>সাধারণ সম্পাদক,কেন্দ্রীয় কমিটি</span>
                                //        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                //    </MDBCardTitle>
                                //    <MDBCardText>
                                //        আসসালামু আলাইকুম, মহাকালের জীবন স্রোতের কাছে "মানব জীবন" বুদবুদের মতোই ক্ষণস্থায়ী।
                                //        পৃথিবীর সকল জীবকেই সময় নামক মহাযঞ্জে আত্নহুতি দিতে হবে। এটাই অমোঘ বিধান।
                                //        জীবনের এই ক্ষুদ্র পরিসরে মানুষ নিজেকে বাঁচিয়ে ..............
                                //    </MDBCardText>

                                //    <MDBCol md='12' className='d-flex justify-content-center'>

                                //        <a href='https://www.facebook.com/iqra.bintayiqbal' className='px-2 fa-lg fb-ic'>
                                //            <MDBIcon fab icon='facebook-f'></MDBIcon>
                                //        </a>

                                //        <a href='!#' className='px-2 fa-lg li-ic'>
                                //            <MDBIcon far icon="envelope-open" />
                                //        </a>

                                //        <a href='!#' className='px-2 fa-lg tw-ic'>
                                //            <MDBIcon fab icon='twitter'></MDBIcon>
                                //        </a>

                                //    </MDBCol>
                                //</MDBCardBody>
                                }
                            </MDBCard>
                        </MDBCol>

                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/bosir_ahmed">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/bosir_ahmed.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Bosir Ahmed'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>বশির আহমেদ</strong>
                                        <span>সাংগঠনিক সম্পাদক,কেন্দ্রীয় কমিটি</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, প্রথমেই মহান রাব্বুল আলামীনের নাম স্বরণ করছি। আল্লাহ তায়ালা মানব জাতিকে
                                        একমাত্র তারই এবাদতের জন্য সৃষ্টি করেছেন। সমাজের উন্নয়নে অবদান রাখা .....
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>

                                        <a href='https://www.facebook.com/profile.php?id=100019125506697' className='px-2 fa-lg fb-ic'>
                                            <MDBIcon fab icon='facebook-f'></MDBIcon>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer className="committee_title">
                    <MDBRow>
                        <MDBCol>
                            <h2>প্রবাসফেরত কমিটি পরিচালনা পরিষদবৃন্দের বাণী</h2>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer>
                    <MDBRow className="cardWrapper">
                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/roton">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/roton.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Roton Sad'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>রতন সাদ</strong>
                                        <span>প্রবাস ফেরত সভাপতি</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, আপনাদের অবগত করছি যে, আমরা নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাসী ফেরত সমন্বয় একটি অরাজনৈতিক
                                        সেবামূলক সংগঠন গঠন করেছি। বিশ্বের বিভিন্ন দেশে প্রবাসী ও দেশে ফেরত প্রবাসীদের কল্যাণে..............
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>
                                        <a href='!#' className='px-2 fa-lg fb-ic'>
                                            <a href="https://www.facebook.com/profile.php?id=100005135838861">
                                                <MDBIcon fab icon='facebook-f'></MDBIcon>
                                            </a>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/abu_raihan">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/abu_raihan.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Abu Raihan'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>আবু রায়হান</strong>
                                        <span>প্রবাস ফেরত সাধারণ সম্পাদক</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, মহাকালের জীবন স্রোতের কাছে "মানব জীবন" বুদবুদের মতোই ক্ষণস্থায়ী।
                                        পৃথিবীর সকল জীবকেই সময় নামক মহাযঞ্জে আত্নহুতি দিতে হবে। এটাই অমোঘ বিধান।
                                        জীবনের এই ক্ষুদ্র পরিসরে মানুষ নিজেকে বাঁচিয়ে ..............
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>

                                        <a href='https://www.facebook.com/iqra.bintayiqbal' className='px-2 fa-lg fb-ic'>
                                            <MDBIcon fab icon='facebook-f'></MDBIcon>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol size="12" sm="12" lg="4" md="6">
                            <MDBCard className="customCard" wide cascade style={customCard}>
                                <MDBView cascade>
                                    <a href="/page/saidur_rahman">
                                        <MDBCardImage
                                            hover
                                            overlay='white-slight'
                                            className='card-img-top'
                                            src={require('../../assets/images/saidur_rahman.jpg')}
                                            style={{ width: 350, height: 360 }}
                                            alt='Saidur Rahman'
                                        />
                                    </a>
                                </MDBView>

                                <MDBCardBody cascade className='text-center'>
                                    <MDBCardTitle className='card-title'>
                                        <strong>কাজী সাইদুর রহমান শাহীন</strong>
                                        <span>প্রবাস ফেরত সাংগঠনিক সম্পাদক</span>
                                        <u>বিসমিল্লাহির রাহমানির রাহীম</u>
                                    </MDBCardTitle>
                                    <MDBCardText>
                                        আসসালামু আলাইকুম, প্রথমেই মহান রাব্বুল আলামীনের নাম স্বরণ করছি। আল্লাহ তায়ালা মানব জাতিকে
                                        একমাত্র তারই এবাদতের জন্য সৃষ্টি করেছেন। সমাজের উন্নয়নে অবদান রাখা .....
                                    </MDBCardText>

                                    <MDBCol md='12' className='d-flex justify-content-center'>

                                        <a href='https://www.facebook.com/profile.php?id=100019125506697' className='px-2 fa-lg fb-ic'>
                                            <MDBIcon fab icon='facebook-f'></MDBIcon>
                                        </a>

                                        <a href='!#' className='px-2 fa-lg li-ic'>
                                            <MDBIcon far icon="envelope-open" />
                                        </a>

                                        <a href='!#' className='px-2 fa-lg tw-ic'>
                                            <MDBIcon fab icon='twitter'></MDBIcon>
                                        </a>

                                    </MDBCol>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }
}
export default Home