import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

export default () => (
    <BrowserRouter>
        <Switch>
            <Route path='/admin' component={PrivateRoutes} />
            <Route path='/' component={PublicRoutes} />
        </Switch>
    </BrowserRouter> 
)