import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn, MDBIcon, toast, ToastContainer
} from "../../../mdbreact";
//import Previews from '../../common/imagePreview';
import Dropzone from 'react-dropzone';
import { insertCommittee } from '../../../store/actions/settingAction';

const imgMaxSize = 700000;
const imgMinSize = 200000;

let init = {
    committeeId: 0,
    committeeName: '',
    description: '',
    orderNo: '',
    banner: [],
    srcUrl: '',
    isSrcUrl: {
        'display': 'none'
    },
    slug: '',
    sendText: 'Submit',
    isUpdateTriger : false,
    
    isBtnDisable:false
}

class Create extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidUpdate(prevProps) {
        //console.log("Start Component did Update");
        //console.log(prevProps);
        //console.log("End Component Did Update");
        //if (this.props.dwelling !== prevProps.dwelling) {
        //    this.setState(() => ({ dwelling: this.props.dwelling }));
        //}
    }

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...init
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("Start Component Receive Props");
        //console.log(nextProps);
        //console.log(this.state.committeeId);
        //console.log("End Component Receive Props");

        if (nextProps.updateObject) {
            if (nextProps.updateObject.committeeId !== this.state.committeeId) {

                if (nextProps.updateObject.banner) {
                    let path = window.location.protocol + '//' + window.location.host + '/Uploads/Committee/Images/';
                    this.setState({
                        ...this.state,
                        committeeId: nextProps.updateObject.committeeId,
                        committeeName: nextProps.updateObject.committeeName,
                        slug: nextProps.updateObject.slug,
                        srcUrl: nextProps.updateObject.banner !== null ? path + nextProps.updateObject.banner : '',
                        description: nextProps.updateObject.description == null ? '' : nextProps.updateObject.description,
                        orderNo: nextProps.updateObject.orderNo == null ? 0 : nextProps.updateObject.orderNo,
                        sendText: 'Update',
                        isSrcUrl: {
                            'display': 'block'
                        },
                        //srcUrl: path + nextProps.updateObject.banner
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        committeeId: nextProps.updateObject.committeeId,
                        committeeName: nextProps.updateObject.committeeName,
                        slug: nextProps.updateObject.slug,
                        srcUrl: nextProps.updateObject.banner !== null ? nextProps.updateObject.banner : '',
                        description: nextProps.updateObject.description == null ? '' : nextProps.updateObject.description,
                        orderNo: nextProps.updateObject.orderNo == null ? 0 : nextProps.updateObject.orderNo,
                        sendText: 'Update',
                        isSrcUrl: {
                            'display': 'none'
                        }
                    })
                }
            }
        }
        if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Sending...'
            && this.state.isUpdateTriger == true
        ) {
            this.loadMessage('Committee saved successfully.');
        }
        //else if (nextProps.setting.statusCode == 200
        //    && this.state.sendText == 'Update') {
        //    this.loadMessage('Committee updated successfully.');
        //}
    }

    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.committeeName == '') {
            toast.error('Committee name is required!', {
                closeButton: true
            });
            return error = true;
        }
        //if (this.state.imageFile != null) {

        //    if (this.state.imageFile.size > imgMaxSize) {
        //        toast.error('Maximum image size will 700kb!', {
        //            closeButton: true
        //        });
        //        return error = true;
        //    }
        //    if (this.state.imageFile.size < imgMinSize) {
        //        toast.error('Minimum image size will 200kb!', {
        //            closeButton: true
        //        });
        //        return error = true;
        //    }
        //    else {
        //        let ext = ['jpg', 'jpeg', 'png'];
        //        let imgExt = this.state.imageFile.name.split('.');
        //        if (!ext.includes(imgExt.pop().toLowerCase())) {
        //            toast.error('Image extension is invalid!', {
        //                closeButton: true
        //            });
        //            return error = true;
        //        }
        //    }
        //}
        return error;
    }

    handleSubmission = () => {
        let error = this.validationHandler();
        if (error)
            return;
        if (this.state.orderNo)
            this.state.orderNo = parseInt(this.state.orderNo);

        let { committeeId, committeeName, description, orderNo, banner, slug } = this.state;

        let entryBy = this.props.auth.user.userId;
        let editBy = this.props.auth.user.userId;

        let formData = new FormData();
        formData.append('committeeId', committeeId);
        formData.append('committeeName', committeeName);
        formData.append('slug', slug);
        formData.append('description', description);
        formData.append('orderNo', orderNo);
        formData.append('banner', banner);
        formData.append('entryBy', entryBy);
        formData.append('editBy', editBy);

        if (this.state.isUpdateTriger == false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true,
                isBtnDisable: true,
                sendText:'Sending...'
            });
        }
        this.props.insertCommittee(formData);
    }

    handleClear = () => {
        this.setState({
            ...init
        });
    }

    /*============ IMAGE PREVIEW ========== */
    imagePreviewHandler = (files, rejectedFiles) => {
        //console.log(files);
        //return;
        //rejected image
        if (rejectedFiles.length > 0) {
            rejectedFiles[0].errors.map(err => {
                toast.error(err.code, {
                    closeButton: true
                });
            })            
        }
        else {
            //process image preview
            const currentFile = files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                //console.log(reader.result);
                this.setState({
                    ...this.state,
                    banner: currentFile,
                    srcUrl: reader.result,
                    isSrcUrl: {
                        'display':'block'
                    }
                })
            }, false);
            reader.readAsDataURL(currentFile);
        }   
    }

    render() {   

        let { committeeName, description, orderNo, isSrcUrl, srcUrl, sendText, isBtnDisable } = this.state
        return (
            <div id='committeeNameCreate'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Create Committee </MDBCardHeader>
                    <MDBCardBody>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Committee Name"
                                    name="committeeName"
                                    className="mt-4"
                                    value={committeeName}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Description"
                                    type="textarea"
                                    rows="5"
                                    name="description"
                                    className="mt-4"
                                    value={description}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Serial No."
                                    name="orderNo"
                                    className="mt-4"
                                    value={orderNo}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>                        

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                {
                                    <div>
                                        <div className="dropzoneImagePreview" style={isSrcUrl}>
                                            <div className="thumbsContainer">
                                                <div className="thumb">
                                                    <div className="thumbInner">
                                                        <img
                                                            src={srcUrl}
                                                            className="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Dropzone onDrop={this.imagePreviewHandler} multiple={false} minSize={imgMinSize} maxSize={imgMaxSize} accept="image/*">
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzoneContainer">
                                                    <div
                                                        {...getRootProps({
                                                            className: 'dropzone',
                                                            //onDrop: event => event.stopPropagation()
                                                        })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <p>Drag 'n' drop committee banner here, or click to select banner</p>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                }
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBBtn color="success" rounded onClick={this.handleSubmission} disabled={isBtnDisable}>
                                    {sendText}
                                </MDBBtn>
                                {
                                    //<MDBBtn color="danger" rounded onClick={this.handleClear}>
                                    //    Clear
                                    //</MDBBtn>
                                }
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCardBody>
                </MDBCard>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting
})

export default connect(mapStateToProps, { insertCommittee })(Create);