import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBTabPane, MDBTabContent, MDBNav, MDBNavItem,
    MDBNavLink, MDBIcon, MDBRow, MDBCol, MDBAvatar,
    MDBInput, MDBDatePicker, MDBBtn, MDBFileInput, toast, ToastContainer
} from "../../../mdbreact";
import Create from './Create';
import List from './List';

class Dashboard extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
        this.editHandler = this.editHandler.bind(this);
    }

    //static getDerivedStateFromProps(nextProps, prevState) {
    //    return null;
    //}

    /*=======================
     * ACTION TEMPLATE
     * =====================*/

    editHandler(id, users) {
        //console.log(committees);
        let filterUser = users.filter(d => d.userId === id);
        this.setState({
            ...this.state,
            user: filterUser[0]
        });
        //console.log(designation);
        //return () => {
        //    this.setState({
        //        ...this.state,
        //        designation: designation
        //    });
        //    console.log(this.state);
        //}
        //console.log(this.state);
    }

    render() {
        return (
            <div id='designationDashboard'>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="6">
                        <Create updateObject={this.state.user} />
                    </MDBCol>
                    <MDBCol size="12" sm="12" lg="6">
                        <List editHandler={this.editHandler} />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Dashboard);