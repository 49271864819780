import Axios from 'axios';
import * as Types from './types';
import AuthHeaderToken from '../../utils/setAuthToken';

export const loadUsers = () => dispatch => {
    Axios.get('api/Authentication/GetUsers')
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_USERS,
                payload: {
                    users: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadUser = (userId) => dispatch => {
    Axios.get('api/Authentication/GetUser/' + userId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_USER,
                payload: {
                    user: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const register = (user) => dispatch => {
    Axios.post('api/Authentication/RegisterUser', user)
        .then(response => {
            dispatch({
                type: Types.REGISTER_USER,
                payload: {
                    user: response.data
                }
            });
            //history.push('/login');
        })
        .catch(error => {
            console.log(error);
        })
}

export const updateUser = (user) => dispatch => {
    Axios.post('api/Authentication/RegisterUser', user)
        .then(response => {
            const token = JSON.stringify(response.data);
            localStorage.setItem('auth_token', token);
            AuthHeaderToken(token);
            dispatch({
                type: Types.SET_USER,
                payload: {
                    user: response.data
                }
            });
            //history.push('/login');
        })
        .catch(error => {
            console.log(error);
        })
}

export const login = (user, history) => dispatch => {
    Axios.post('api/Authentication/LoginUser', user)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.SET_USER,
                payload: {
                    user: response.data
                }
            })
            if (response.data) {
                const token = JSON.stringify(response.data);
                localStorage.setItem('auth_token', token);
                AuthHeaderToken(token);                
                history.push('/admin');
            }
        })
        .catch(error => {
            console.log(error);
        })
}

export const logout = history => {
    localStorage.removeItem('auth_token');
    history.push('/');
    return {
        type: Types.SET_USER,
        payload: {
            user: {}
        }
    }
}