import * as Types from '../actions/types';

const transactionReducer = (state = [], action) => {
    switch (action.type) {
        case Types.LOAD_PAYMENT_MODE: {
            return {
                paymentModes: action.payload.paymentModes,
            }
        }
        case Types.LOAD_SERVICE_HOLDER_BY_PAYMENT_MODE: {
            return {
                paymentModes: [...state.paymentModes],
                serviceHolders: action.payload.serviceHolders,
            }
        }
        case Types.SAVE_DONATION: {
            let statusCode = null;
            let message = null;
            if (action.payload.status) {
                statusCode = 200;
                message = 'Donation collection successfully.';
            }
            return {
                //...state,
                paymentModes: [...state.paymentModes],
                statusCode: statusCode,
                message: message,
            }
        }
        default: return state;
    }
}
export default transactionReducer;
