import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SearchForm } from '../../../components/UIMaterial/Search';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon, MDBContainer } from "../../../mdbreact";

const DonarList = ({ members, searchTerm, isLoading, changeHandler, clickHandler }) => {
    //const [first,setFirst] = useState(0);

    /*=======================
     * PROFILE PIC TEMPLATE
     * =====================*/
    const profileTemplate = (rowData) => {
        // console.log(rowData);
        // return;
        let imgData = 'np_default_avatar.jpg';
        if (rowData.imagePath)
            imgData = rowData.imagePath;
        const src = imgData; //window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + imgData;
        return (
            <>
                <img alt={"test purpose"} src={src} style={{ verticalAlign: 'middle', borderRadius: '50%', width: '80px', height: '80px', border: '3px solid rgba(233,230,230,1)' }} />
                {
                    //<span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.memberNameBd}</span>
                }
            </>
        );
    }

    const actionTemplate = (rowData, column) => {
        return <div id='admin_pending_btn'>
            <MDBBtn tag="a" size="sm" floating
                gradient="blue"
                onClick={() => fbHandler(rowData.fbLink)}>
                <MDBIcon fab icon="facebook" />
            </MDBBtn>
        </div>;
    }

    const fbHandler = (url) => {
        //url = 'https://www.facebook.com/' + url;
        window.open(url, '_blank');
    }

    return (
        <>
            <MDBContainer className="pageContainer">
                <MDBCard wide >
                    <MDBCardHeader color="success-color" style={{ textAlign: 'center', fontSize: 25 }}>
                        <div className="listTitle">অর্থদাতা তালিকা</div>
                        <div className="searchContainer">
                            <SearchForm
                                placeholder="অনুসন্ধান করুন"
                                searchTerm={searchTerm}
                                isSearch={true}
                                changeHandler={changeHandler}
                                searchHandler={clickHandler} />
                        </div>
                    </MDBCardHeader>
                    <MDBCardBody>
                        {
                            isLoading === true ? (
                                <div><p>অনুসন্ধান চলছে।অপেক্ষা করুন... </p></div>
                            ) :
                                members &&
                                    members.length > 0 ? (
                                    <DataTable value={members} paginator
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                        responsive={true}>
                                        <Column field="memberNameBd" header="" body={profileTemplate} headerStyle={{ width: '8em' }} />
                                        <Column field="memberNameBd" header="নাম" />
                                        <Column field="memberCode" header="আইডি" />
                                        <Column field="thana" header="থানা" />
                                        <Column field="designation" header="পদবি" />
                                        <Column field="country" header="ইমিগ্রেশন দেশ" />
                                        <Column field="mobileNo" header="মোবাইল" />
                                        <Column field="thana" header="" body={actionTemplate} headerStyle={{ width: '8em' }} />
                                    </DataTable>
                                ) : <div><p>কোন সদস্য খুঁজে পাওয়া যায়নি।</p></div>
                        }

                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </>
    );
};

export default DonarList;