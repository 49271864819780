import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from '../../../mdbreact';
let src = window.location.protocol + '//' + window.location.host + '/Uploads/GreetingCard/thumb/';
class List extends Component {

    render() {
        return (
            <MDBRow id="greetingCardList">
            {
                this.props.cards.cards &&
                    this.props.cards.cards.map(cnt => {
                        return (
                            <MDBRow key={cnt.cardId}>
                                <MDBCol>
                                    <div style={{
                                        backgroundImage: 'url("' + src + cnt.imageName + '")',
                                        backgroundSize: 'cover',
                                        border: '3px solid hsl(14, 100%, 53%)',
                                        height: '300px',
                                        width: '200px',
                                        margin: '10px',
                                        position: 'relative'
                                    }}>
                                        <div className="cardTitleWrapper">{cnt.cardTitle}</div>
                                    </div>
                                </MDBCol>
                            </MDBRow>    
                        )
                    })

            }
            </MDBRow>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    cards: state.cardReducer
})

export default connect(mapStateToProps)(List);
