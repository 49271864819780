import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBContainer, MDBBtn, MDBRow, MDBCol, MDBView
} from '../../mdbreact';

class Mission extends Component {
    state = {
    }
    changeHandler = event => {
    }
    submitHandler = () => {
    }
    render() {
        return (
            <MDBView className="sloganWrapper">
                <MDBContainer>
                    <MDBRow>
                        <MDBCol>
                            <h3>লক্ষ্য ও উদ্দেশ্য</h3>
                            <ul>
                                <li>
                                    <p style={{ color: '#6b9602' }}>
                                        নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাস ফেরতদের সমন্বয়ে একটি অরাজনৈতিক সংগঠন গঠন করে প্রবাসী ও প্রবাস ফেরতদের কল্যাণে এবং
                                        রেমিট্যান্স যোদ্ধা স্বীকৃতি আদায়ের চেষ্টা করা।
                                        </p>
                                </li>
                                <li>
                                    <p style={{ color: '#c10248' }}>
                                        নারায়ণগঞ্জ জেলার সাতটি থানার প্রতিটি থানায় প্রবাসী ও প্রবাস ফেরতদের সকল প্রকার সুযোগ সুবিধার জন্য যেমন- স্কুল, কলেজ,
                                    মাদ্রাসা, কমিউনিটি সেন্টার, বিনোদন সেন্টার, সরকারি হাসপাতাল, প্রাইভেট ক্লিনিক, ডায়াগনস্টিক সেন্টার গুলোতে আমাদের
                                    সংগঠনের মেম্বারদের বিশেষ সুযোগ সুবিধার জন্য সকল প্রকার চেষ্টা ও পদক্ষেপ গ্রহণ করা।
                                        </p>
                                </li>
                                <li>
                                    <p style={{ color: '#019858' }}>
                                        অত্র সংগঠনের সদস্যগণ কোনরূপ হয়রানি অথবা জোর জুলুমের শিকার হলে তার জন্য ঐক্যবদ্ধভাবে সকলে তার পাশে থাকা এবং
                                       তাকে সহযোগিতা করা।
                                        </p>
                                </li>
                                <li>
                                    <p style={{ color: '#580288' }}>
                                        প্রবাসী সদস্যদের প্রবাসের কর্মজীবন সম্বন্ধে সকল প্রকার দিক নির্দেশনা এবং সহযোগিতা করা।
                                        </p>
                                </li>
                                <li>
                                    <p style={{ color: '#1ab2b4' }}>
                                        নারায়ণগঞ্জ জেলার প্রবাসীদের জন্য জেলা প্রশাসকের বরাবর প্রবাসীদের ও প্রবাস ফেরতদের জন্য প্রশাসনিক হেল্পডেস্ক চালু করার জন্য আবেদন করা ও
                                        তা বাস্তবায়নে সক্রিয় ভূমিকা পালন করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#6b9602' }}>
                                        অসহায় প্রবাসী ও প্রবাস ফেরত ভাই বোনদের সমস্যায় তাদের সহযোগিতা করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#c10248' }}>
                                        নারায়ণগঞ্জ জেলার উন্নয়নের জন্য জনপ্রতিনিধিদের সাথে আলোচনার মাধ্যমে প্রবাসীদের রেমিট্যান্সে বিনিয়োগ করে জেলার উন্নয়নের সহায়তা করা এবং নিজেদের লাভবান করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#019858' }}>
                                        নারায়ণগঞ্জ জেলা প্রবাসীদের ছেলে-মেয়েদের জন্য স্কুল, কলেজ, সরকারী চাকুরীতে কোটা পদ্ধতির চালু করতে সরকারের কাছে আবেদন করে বাস্তবায়ন করতে অনুরোধ করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#580288' }}>
                                        নারায়ণগঞ্জ জেলার সরকারি বাণিজ্যিক ভবন গুলোতে অংশীদারীত্ব মূলক প্রবাসীদের কোটা পদ্ধতি রাখতে প্রশাসন ও জনপ্রতিনিধিদের সাথে আলোচনা করে বাস্তবায়ন করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#1ab2b4' }}>
                                        নারায়ণগঞ্জ জেলার সরকারি বাণিজ্যিক ভবন গুলোতে অংশীদারীত্ব মূলক প্রবাসীদের কোটা পদ্ধতি রাখতে প্রশাসন ও জনপ্রতিনিধিদের সাথে আলোচনা করে বাস্তবায়ন করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#6b9602' }}>
                                        নারায়ণগঞ্জ জেলার প্রবাসী যদি প্রবাসে মৃত্যুবরণ করে তাহলে আমাদের এই সংগঠনের মাধ্যমে তার মৃতদেহ দেশে আনার জন্য ব্যবস্থা গ্রহণ করা এবং তার মৃত্যু অনুদান যাতে সঠিক ভাবে পায় সে ব্যবস্থা করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#c10248' }}>
                                        প্রবাসী কল্যাণে ব্যাংক থেকে ঋণ পাওয়ার ব্যাপারে আমাদের সংগঠন থেকে সহায়তা করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#019858' }}>
                                        এয়ারপোর্ট এবং বিদেশী দূতাবাস গুলোতে যে কোন সমস্যায় বিপদ আপদে আমাদের সংগঠনের সকল সদস্যকে যে কোন সমস্যায় সহযোগিতা করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#580288' }}>
                                        প্রবাসে আগমন ইচ্ছুক নতুন কর্মীদের কে প্রয়োজনীয় তথ্য প্রদান করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#1ab2b4' }}>
                                        নারায়ণগঞ্জ জেলার প্রবাসী ও প্রবাস ফেরত সকল থানার সদস্যদের জন্য সংগঠনের মাধ্যমে মৃত্যু অনুদান ও চিকিৎসা অনুদান বীমা ব্যবস্থা চালু করার উদ্যোগ গ্রহণ করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#6b9602' }}>
                                        প্রবাস ফেরত বেকার সদস্যদের কর্মসংস্থান সৃষ্টি করতে সরকারের নিকট দাবি উপস্থাপন করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#c10248' }}>
                                        রেমিট্যান্স যোদ্ধা বেকার ভাতা চালু করতে সরকারের কাছে দাবি উপস্থাপন করা তাহা বাস্তবায়নের চেষ্টা করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#019858' }}>
                                        নারায়ণগঞ্জ জেলা প্রবাসী ও প্রবাস ফেরত কল্যাণ পরিষদের পক্ষ হতে সামাজিক ও অসহায় মানুষের সেবা প্রদানের লক্ষ্যে কাজ করা।
                                    </p>
                                </li>
                                <li>
                                    <p style={{ color: '#580288' }}>
                                        বিদেশে গমন ইচ্ছুক কর্মীদের প্রশিক্ষণের ব্যবস্থা করা।
                                    </p>
                                </li>
                            </ul>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </MDBView>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(mapStateToProps)(Mission);
