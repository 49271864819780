import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApprovedList from './ApprovedList';
import { getApprovedMemberList, storeMemberforGreetingCard } from '../../../store/actions/memberAction';
// import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from "../../../mdbreact";
// import GenerateCard from '../greetingCard/Generate';
//import * as jsPDF from 'jspdf'
//import html2canvas from 'html2canvas';

class ApprovedMemberList extends Component {

    state = {
        committee: 0,
        userId: 0,
        modal: false,
        member: {},
        memberList: [],
        isSearch: false,
        isLoading:true,
    }

    constructor(props) {
        super(props);
        this.editHandler = this.editHandler.bind(this);
        this.greetingCardHandler = this.greetingCardHandler.bind(this);
        this.downloadMembers = this.downloadMembers.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         first: 0,
    //         rows: 10,
    //         totalRecords: 0,
    //         committee: props.match.params.cid,
    //         userId: props.auth.user.userId,
    //         modal: false,
    //         member: null,
    //         memberList: null
    //     }

    //     //let committee = props.match.params.cid;
    //     //let userId = props.auth.user.userId;
    //     props.getApprovedMemberList(this.state.committee, this.state.userId);
    // }

    componentDidMount() {
        this.setState({
            ...this.state,
            committee: this.props.match.params.cid,
            userId: this.props.auth.user.userId            
        }, () => {
            this.props.getApprovedMemberList(this.state.committee, this.state.userId);
        })
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.member.members) !== JSON.stringify(prevProps.member.members) && this.state.isSearch === false) {
            this.setState({
                ...this.state,
                isLoading: false,
                memberList: this.props.member.members
            })
        }
    }


    // UNSAFE_componentWillUpdate(nextProps, nextState) {

    //     let committee = nextProps.match.params.cid;
    //     if (this.state.committee !== committee) {
    //         this.state.committee = committee;
    //         let userId = nextProps.auth.user.userId;
    //         this.props.getApprovedMemberList(committee, userId);
    //     }
    //     if (nextProps.member.members) {
    //         this.state.memberList = nextProps.member.members;
    //     }
    // }
    // /*=======================
    //  * PROFILE PIC TEMPLATE
    //  * =====================*/
    // profileTemplate(rowData) {
    //     let imgData = 'np_default_avatar.jpg';
    //     if (rowData.userProfileName)
    //         imgData = rowData.userProfileName;
    //     const src = window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + imgData;
    //     return (
    //         <>
    //             <img alt={"test purpose"} src={src} width="32" style={{ verticalAlign: 'middle', borderRadius: '50%', width: '50px', height: '50px', border: '3px solid rgba(233,230,230,1)' }} />
    //             <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.memberNameBd}</span>
    //         </>
    //     );
    // }

    // /*=======================
    //  * ACTION TEMPLATE
    //  * =====================*/

    // actionTemplate = (rowData, column) => {
    //     return <div id='admin_pending_btn'>
    //         <MDBBtn tag="a" size="sm" floating
    //             gradient="blue"
    //             onClick={() => this.fbHandler(rowData.fbLink)}>
    //             <MDBIcon fab icon="facebook" />
    //         </MDBBtn>
    //         <MDBBtn tag="a" size="sm" floating
    //             gradient="aqua"
    //             onClick={() => this.editHandler(rowData.memberId)}>
    //             <MDBIcon icon="edit" />
    //         </MDBBtn>
    //         <MDBBtn tag="a" size="sm" floating
    //             className="ripe-malinka-gradient"
    //             onClick={() => this.downloadProfileHandler(rowData.memberId)}>
    //             <MDBIcon far icon="file-pdf" />
    //         </MDBBtn>
    //         <MDBBtn tag="a" size="sm" floating
    //             gradient="peach"
    //             onClick={() => this.viewProfileHandler(rowData.memberId)}>
    //             <MDBIcon icon="user-tie" />
    //         </MDBBtn>
    //         <MDBBtn tag="a" size="sm" floating
    //             gradient="aqua"
    //             onClick={() => this.greetingCardHandler(rowData)}>
    //             <MDBIcon far icon="address-card" />
    //         </MDBBtn>
    //     </div>;
    // }

    fbHandler(url) {
        //url = 'https://www.facebook.com/' + url;
        window.open(url, '_blank');
    }

    viewProfileHandler(memberId) {
        let url = '/api/Report/MemberCV/2/' + memberId + '?format=png';
        window.open(url, '_blank');
    }

    greetingCardHandler(member) {
        this.setState({
            ...this.state,
            modal: true,
            member: member
        });
        //console.log(member);
        //this.props.storeMemberforGreetingCard(member);
        //let url = '/api/Report/MemberCV/2/' + memberId +'?format=png';
        //window.open(url, '_blank');
    }

    downloadProfileHandler(memberId) {
        let url = '/api/Report/MemberCV/2/' + memberId + '?format=pdf';
        window.open(url, '_blank');
    }

    editHandler(id) {
        //console.log(this.state);
        window.location.href = '/admin/updateapprovedmember/' + id + '/' + this.state.committee;
        //this.props.history.push('/admin/updateapprovedmember/' + id);
    }

    deleteHandler(rowData) {
        console.log(rowData);
    }

    // generateData = () => {
    //     var result = [];
    //     this.props.member.members.map((c, index) => {
    //         var data = {
    //             Serial: index + 1,
    //             Name: c.memberNameBd,
    //             Id: c.memberCode,
    //             Thana: c.thana,
    //             Country: c.immigrationCountry,
    //             Mobile: c.mobileNo

    //         };
    //         result.push(Object.assign({}, data));
    //     });
    //     return result;
    // };

    // createHeaders = (keys) => {
    //     var result = [];
    //     for (var i = 0; i < keys.length; i += 1) {
    //         result.push({
    //             id: keys[i],
    //             name: keys[i],
    //             prompt: keys[i],
    //             width: 65,
    //             align: "center",
    //             padding: 0
    //         });
    //     }
    //     return result;
    // }

    downloadMembers = () => {
        let url = '/api/Report/GetMemberList/6/'
            + this.state.committee
            + '/'
            + this.state.userId
            + '?format=pdf';
        window.open(url, '_blank');
    }

    toggle = () => {
        this.setState({
            ...this.state,
            modal: !this.state.modal
        });
    }

    searchHandler = (term) => {
        const data = this.props.member.members;
        let result = data.filter(c => (c.immigrationCountry && c.immigrationCountry.toLowerCase().includes(term))
            || (c.occupation && (c.occupation.toLowerCase().includes(term)))
            || (c.memberCode && c.memberCode.toLowerCase().includes(term))
            || (c.memberNameBd && c.memberNameBd.toLowerCase().includes(term))
            || (c.memberNameEn && c.memberNameEn.toLowerCase().includes(term))
            || (c.mobileNo && c.mobileNo.toLowerCase().includes(term))
            || (c.thana && c.thana.toLowerCase().includes(term))
        );
        return result;
    }

    changeHandler = (event) => {        
        let term = event.target.value.toLowerCase();
        let result = this.searchHandler(term);
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            memberList: result,
            isSearch: true
        });
    }    

    clickHandler = (term) => {
        let result = this.searchHandler(term);
        //console.log(result);
        this.setState({
            ...this.state,            
            memberList: result,
            isSearch: true
        });        
    }

    render() {
        const { memberList, member, searchTerm, modal, isLoading } = this.state;
        return (
            <ApprovedList
                isLoading={isLoading}
                members={memberList}
                member={member}
                searchTerm={searchTerm}
                modal={modal}
                toggle={this.toggle}
                changeHandler={this.changeHandler}
                clickHandler={this.clickHandler}
                fbHandler={this.fbHandler}
                editHandler={this.editHandler}
                downloadMembers={this.downloadMembers}
                downloadProfileHandler={this.downloadProfileHandler}
                viewProfileHandler={this.viewProfileHandler}
                greetingCardHandler={this.greetingCardHandler}
            />
            // <div>
            //     {
            //         //Generate Greeting Card
            //         <MDBModal isOpen={this.state.modal} toggle={this.toggle} centered size="lg">
            //             <MDBModalHeader toggle={this.toggle}>Generate Greeting Card</MDBModalHeader>
            //             <MDBModalBody>
            //                 <GenerateCard memberProfile={this.state.member} memberList={this.state.memberList} />
            //             </MDBModalBody>
            //         </MDBModal>
            //     }

            //     <MDBCard wide >
            //         <MDBCardHeader color="success-color">
            //             <span>Approved Member List</span>
            //             <span onClick={this.downloadMembers} style={{ float: "right", cursor: "pointer" }} >
            //                 <MDBIcon far icon="file-pdf" size="lg" />
            //             </span>
            //         </MDBCardHeader>
            //         <MDBCardBody>
            //             <DataTable value={this.state.memberList}
            //                 selectionMode="single"
            //                 paginator={true}
            //                 rows={10}
            //                 first={this.state.first}
            //                 onPage={(e) => this.setState({ first: e.first })}
            //                 responsive={true}>
            //                 <Column field="memberNameBd" header="" body={this.profileTemplate} headerStyle={{ width: '17em' }} />
            //                 <Column field="memberCode" header="ID" />
            //                 <Column field="thana" header="Thana" />
            //                 <Column field="immigrationCountry" header="Country" />
            //                 <Column field="mobileNo" header="Mobile No" />
            //                 <Column field="occupation" header="Occupation" />
            //                 <Column field="thana" header="" body={this.actionTemplate} headerStyle={{ width: '22em' }} />
            //             </DataTable>                       

            //         </MDBCardBody>
            //     </MDBCard>
            // </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer
})

export default connect(mapStateToProps, {
    getApprovedMemberList,
    storeMemberforGreetingCard
})(ApprovedMemberList);