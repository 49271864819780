import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn,toast, ToastContainer
} from "../../../mdbreact";
import Dropzone from 'react-dropzone';
import { updateUser } from '../../../store/actions/authAction';

const imgMaxSize = 150000;
const imgMinSize = 80000;

let init = {
    userId: 0,
    name: '',
    mobile: '',
    phone: '',
    email: '',
    userName: '',
    password: '',
    userType: '',
    banner: [],
    srcUrl: '',
    isSrcUrl: {
        'display': 'none'
    },
    sendText: 'Update Account',
    isUpdateTriger : false,    
    isBtnDisable:false
}

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidUpdate(prevProps) {
    }

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...this.state,
            sendText: 'Update Account'
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log(nextProps.auth.user);
        if (nextProps.auth.user.imageName) {
            let path = window.location.protocol + '//' + window.location.host + '/Uploads/Users/';
            this.setState({
                ...this.state,
                userId: nextProps.auth.user.userId,
                name: nextProps.auth.user.name,
                mobile: nextProps.auth.user.mobile,
                phone: nextProps.auth.user.phone,
                email: nextProps.auth.user.email,
                userName: nextProps.auth.user.userName,                
                srcUrl: nextProps.auth.user.imageName !== null ? path + nextProps.auth.user.imageName : '',                
                isSrcUrl: {
                    'display': 'block'
                }
            })
        }
        else {
            this.setState({
                ...this.state,
                userId: nextProps.auth.user.userId,
                name: nextProps.auth.user.name,
                mobile: nextProps.auth.user.mobile,
                phone: nextProps.auth.user.phone,
                email: nextProps.auth.user.email,
                userName: nextProps.auth.user.userName,                
                srcUrl: nextProps.auth.user.imageName !== null ? nextProps.auth.user.imageName : '',
                isSrcUrl: {
                    'display': 'none'
                }
            })
        }
        if (nextProps.auth.statusCode == 200
            && this.state.isUpdateTriger == true
        ){
            this.loadMessage('Account updated successfull.');
        }
    }

    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.name == '') {
            toast.error('User full name is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.email == '') {
            toast.error('Email is required!', {
                closeButton: true
            });
            return error = true;
        }
        return error;
    }

    handleSubmission = () => {
        //console.log(this.state);
        //return;
        let error = this.validationHandler();
        if (error)
            return;  
        
        let { userId, name, mobile,email, phone, userName, password, userType, banner} = this.state;

        let formData = new FormData();
        formData.append('userId', userId);
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('userName', userName);
        formData.append('password', password);
        formData.append('userType', userType);
        formData.append('userImage', banner);

        if (this.state.isUpdateTriger == false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true,
                isBtnDisable: true,
                sendText:'Sending...'
            });
        }
        this.props.updateUser(formData);
    }

    /*============ IMAGE PREVIEW ========== */
    imagePreviewHandler = (files, rejectedFiles) => {
        //rejected image
        if (rejectedFiles.length > 0) {
            rejectedFiles[0].errors.map(err => {
                toast.error(err.code, {
                    closeButton: true
                });
            })            
        }
        else {
            //process image preview
            const currentFile = files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                //console.log(reader.result);
                this.setState({
                    ...this.state,
                    banner: currentFile,
                    srcUrl: reader.result,
                    isSrcUrl: {
                        'display':'block'
                    }
                })
            }, false);
            reader.readAsDataURL(currentFile);
        }   
    }

    render() {   

        let { name, isSrcUrl, mobile, phone, email,userName, password, srcUrl, sendText, isBtnDisable } = this.state
        return (
            <div id='myAccount'>
                <MDBRow>
                    <MDBCol size="12" xl="6" lg="6" md="6" sm="12">
                    
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">My Account </MDBCardHeader>
                            <MDBCardBody>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="User Full Name"
                                            name="name"
                                            className="mt-4"
                                            value={name}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="User Name"
                                            name="userName"
                                            className="mt-4"
                                            value={userName}
                                            onChange={this.changeHandler}
                                            disabled
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="Password"
                                            type="password"
                                            name="password"
                                            className="mt-4"
                                            value={password}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="Mobile"
                                            name="mobile"
                                            className="mt-4"
                                            value={mobile}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="Phone"
                                            name="phone"
                                            className="mt-4"
                                            value={phone}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBInput label="Email"
                                            name="email"
                                            className="mt-4"
                                            value={email}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>                        

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        {
                                            <div>
                                                <div className="dropzoneImagePreview" style={isSrcUrl}>
                                                    <div className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={srcUrl}
                                                                    className="img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Dropzone onDrop={this.imagePreviewHandler} multiple={false} minSize={imgMinSize} maxSize={imgMaxSize} accept="image/*">
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzoneContainer">
                                                            <div
                                                                {...getRootProps({
                                                                    className: 'dropzone',
                                                                    //onDrop: event => event.stopPropagation()
                                                                })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <p>Drag 'n' drop profile picture</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        }
                                    </MDBCol>
                                </MDBRow>

                                <MDBRow>
                                    <MDBCol size="12" sm="12" lg="12">
                                        <MDBBtn color="success" rounded onClick={this.handleSubmission} disabled={isBtnDisable}>
                                            {sendText}
                                        </MDBBtn>
                                        {
                                            //<MDBBtn color="danger" rounded onClick={this.handleClear}>
                                            //    Clear
                                            //</MDBBtn>
                                        }
                                    </MDBCol>
                                </MDBRow>
                        
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { updateUser })(MyAccount);