import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler,
    MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBIcon,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from '../mdbreact';
import Login from '../pages/public/Login';
import { SITE } from '../components/common/setup';
import { loadCommittees } from '../store/actions/settingAction';

class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            modal2: false
        };
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        this.props.loadCommittees();
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }
    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }
    render() {
        return (
            <div>
                <header>
                    <MDBNavbar color="bg-primary" className="clientAppTopNav" dark expand="md" scrolling>
                        <MDBContainer>
                        <MDBNavbarBrand href="/">
                            <strong>
                                <img src={SITE.url + '/Uploads/logo_sm.png'} alt="logo" />
                            </strong>
                        </MDBNavbarBrand>
                            {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
                            <MDBCollapse isOpen={this.state.collapse} navbar>
                            <MDBNavbarNav left>
                                <MDBNavItem active>
                                    <MDBNavLink to="/">
                                        <MDBIcon icon="home" size="lg" className="pink-text"/><span>হোম</span>
                                    </MDBNavLink>
                                </MDBNavItem>
                                    {
                                        //<MDBNavItem>
                                        //    <MDBDropdown>
                                        //        <MDBDropdownToggle nav caret >
                                        //            <div className="d-none d-md-inline">
                                        //                <MDBIcon icon="users" size="lg" className="pink-text" />
                                        //                <span>আমাদের সম্পর্কে</span>
                                        //            </div>
                                        //        </MDBDropdownToggle>
                                        //        <MDBDropdownMenu>
                                        //            <MDBDropdownItem href="/page/about_us">আমাদের সম্পর্কে জানুন </MDBDropdownItem>
                                        //            <MDBDropdownItem href="/page/mission_vision">লক্ষ্য ও উদ্দেশ্য</MDBDropdownItem>
                                        //        </MDBDropdownMenu>
                                        //    </MDBDropdown>
                                        //</MDBNavItem>
                                    }
                                    <MDBNavItem>
                                        <MDBDropdown>
                                            <MDBDropdownToggle nav caret>
                                                <div className="d-none d-md-inline"><MDBIcon far icon="handshake" size="lg" className="pink-text" />
                                                    <span>কমিটি</span>
                                                </div>
                                            </MDBDropdownToggle>
                                            <MDBDropdownMenu>
                                                {
                                                    this.props.setting.committees &&
                                                    this.props.setting.committees.map(c => {
                                                        return (
                                                            <MDBDropdownItem key={ c.committeeId } href={`/committee/${c.slug}`}>{c.committeeName}</MDBDropdownItem>   
                                                        )
                                                    })
                                                }
                                                
                                            </MDBDropdownMenu>
                                        </MDBDropdown>
                                    </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/donar">
                                            <MDBIcon icon="donate" size="lg" className="pink-text" /><span>অর্থদাতা</span>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/registration">
                                        <MDBIcon icon="book" size="lg" className="pink-text"/> <span>সদস্য নিবন্ধন</span>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="#" onClick={this.toggle(2)}>
                                        <MDBIcon icon="lock" size="lg" className="pink-text"/><span>লগইন</span>
                                    </MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/contact">
                                        <MDBIcon icon="envelope-open-text" size="lg" className="pink-text"/><span>যোগাযোগ</span>
                                    </MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                            </MDBCollapse>
                        </MDBContainer>
                    </MDBNavbar>
                </header>
                <div className="client_login">
                    <Login openModal={this.state.modal2} toggle={this.toggle} history={this.props.history} />
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    setting: state.setting
})
export default connect(mapStateToProps, { loadCommittees })(NavMenu);