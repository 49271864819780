import React from 'react';
import SearchMedia from './searchMedia';
import MediaList from './mediaList';
const MediaController = () => {
    return (
        <>
            <SearchMedia />
            <MediaList />
        </>
    );
};

export default MediaController;