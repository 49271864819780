import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn, MDBIcon, toast, ToastContainer
} from "../../../mdbreact";

import { insertOccupation } from '../../../store/actions/settingAction';

let init = {
    occupationId: 0,
    occupation: '',
    description: '',
    orderNo: '',
    sendText: 'Submit',
    isUpdateTriger : false
}

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
    }

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...init
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log(nextProps)
        if (nextProps.updateObject) {
            if (nextProps.updateObject.occupationId !== this.state.occupationId) {
                this.setState({
                    ...this.state,
                    occupationId: nextProps.updateObject.occupationId,
                    occupation: nextProps.updateObject.occupation,                    
                    description: nextProps.updateObject.description == null ? '' : nextProps.updateObject.description,
                    orderNo: nextProps.updateObject.orderNo == null ? 0 : nextProps.updateObject.orderNo,
                    sendText: 'Update',
                });
            }
        }
        if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Submit'
            && this.state.isUpdateTriger == true
            ) {
            this.loadMessage('Occupation saved successfully.');
        }
        else if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Update') {
            this.loadMessage('Occupation updated successfully.');
        }
    }
    
    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }
    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.occupation == '') {
            toast.error('Occupation is required!', {
                closeButton: true
            });
            return error = true;
        }
        return error;
    }

    handleSubmission = () => {
        let error = this.validationHandler();
        if (error)
            return;
        if (this.state.orderNo)
            this.state.orderNo = parseInt(this.state.orderNo);
        else
            this.state.orderNo = 0;

        let { occupationId, occupation, description, orderNo } = this.state;

        let entryBy = this.props.auth.user.userId;
        let editBy = this.props.auth.user.userId;

        if (this.state.isUpdateTriger == false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true
            });
        }
        this.props.insertOccupation({
            occupationId, occupation, description, orderNo, entryBy, editBy
        });
    }

    handleClear = () => {
        this.setState({
            ...init
        });
    }

    render() {
        let { occupation, description, orderNo, sendText } = this.state
        return (
            <div id='unionCreate'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Create Occupation</MDBCardHeader>
                    <MDBCardBody>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Occupation Name"
                                    name="occupation"
                                    className="mt-4"
                                    value={occupation}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Description"
                                    type="textarea"
                                    rows="5"
                                    name="description"
                                    className="mt-4"
                                    value={description}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Serial No."
                                    name="orderNo"
                                    className="mt-4"
                                    value={orderNo}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                    {sendText}
                                </MDBBtn>
                                {
                                //    <MDBBtn color="danger" rounded onClick={this.handleClear}>
                                //        Clear
                                //  </MDBBtn>
                                }
                            </MDBCol>
                        </MDBRow>

                    </MDBCardBody>
                </MDBCard>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting
})

export default connect(mapStateToProps, { insertOccupation })(Create);