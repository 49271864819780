export const SET_USER = 'SET_USER';
export const USERS_ERROR = 'USERS_ERROR';
export const REGISTER_MEMBER = 'REGISTER_MEMBER';
export const REGISTER_USER = 'REGISTER_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USER = 'LOAD_USER';
export const UPDATE_USER = 'UPDATE_USER';

/**********************************
            MEMBER
*********************************/
export const LOAD_PENDING_MEMBERS = 'LOAD_PENDING_MEMBERS';
export const LOAD_PENDING_MEMBER = 'LOAD_PENDING_MEMBER';
export const UPDATE_PENDING_MEMBER = 'UPDATE_PENDING_MEMBER';
export const UPDATE_APPROVED_MEMBER = 'UPDATE_APPROVED_MEMBER';
export const LOAD_APPROVED_MEMBERS = 'LOAD_APPROVED_MEMBERS';
export const LOAD_APPROVED_MEMBER = 'LOAD_APPROVED_MEMBER';
export const LOAD_DONAR_MEMBERS = 'LOAD_DONAR_MEMBERS';
export const LOAD_MEMBERS_BASIC_INFO = 'LOAD_MEMBERS_BASIC_INFO';
export const LOAD_MEMBER_DESIGNATIONS = 'LOAD_MEMBER_DESIGNATIONS';
export const STORE_MEMBER_GREETING_CARD = 'STORE_MEMBER_GREETING_CARD';

/**********************************
            MENU
*********************************/
export const LOAD_MENUS = 'LOAD_MENUS';
export const LOAD_MENU_ACCESS = 'LOAD_MENU_ACCESS';
export const SAVE_MENU_ACCESS = 'SAVE_MENU_ACCESS';
export const DELETE_MENU_ACCESS = 'DELETE_MENU_ACCESS';

/**********************************
            SETUP
*********************************/
export const SAVE_DESIGNATION = 'SAVE_DESIGNATION';
export const LOAD_DESIGNATIONS = 'LOAD_DESIGNATIONS';
export const SAVE_COMMITTEE = 'SAVE_COMMITTEE';
export const LOAD_COMMITTEES = 'LOAD_COMMITTEES';
export const SAVE_OCCUPATION = 'SAVE_OCCUPATION';
export const LOAD_OCCUPATIONS = 'LOAD_OCCUPATIONS';
export const SAVE_COUNTRY = 'SAVE_COUNTRY';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const SAVE_UPAZILA = 'SAVE_UPAZILA';
export const LOAD_UPAZILAS = 'LOAD_UPAZILAS';
export const SAVE_UNION = 'SAVE_UNION';
export const LOAD_UNIONS = 'LOAD_UNIONS';
export const LOAD_UNIONS_BY_UPAZILA = 'LOAD_UNIONS_BY_UPAZILA';
export const SAVE_MUNICIPALITY = 'SAVE_MUNICIPALITY';
export const LOAD_MUNICIPALITIES = 'LOAD_MUNICIPALITIES';
export const LOAD_MUNICIPALITIES_BY_DISTRICT = 'LOAD_MUNICIPALITIES_BY_DISTRICT';
export const SAVE_THANA = 'SAVE_THANA';
export const LOAD_THANAS = 'LOAD_THANAS';
export const LOAD_THANAS_BY_DISTRICT = 'LOAD_THANAS_BY_DISTRICT';
export const LOAD_MEMBER_FIELDS = 'LOAD_MEMBER_FIELDS';

/**********************************
           COMMITTEE ACCESS
*********************************/
export const LOAD_COMMITTEE_RELATION = 'LOAD_COMMITTEE_RELATION';
export const SAVE_COMMITTEE_RELATION = 'SAVE_COMMITTEE_RELATION';
export const DELETE_COMMITTEE_RELATION = 'DELETE_COMMITTEE_RELATION';
export const LOAD_COMMITTEE_ACCESS = 'LOAD_COMMITTEE_ACCESS';
export const SAVE_COMMITTEE_ACCESS = 'SAVE_COMMITTEE_ACCESS';
export const DELETE_COMMITTEE_ACCESS = 'DELETE_COMMITTEE_ACCESS';

/**********************************
           TRANSACTION
*********************************/
export const LOAD_PAYMENT_MODE = 'LOAD_PAYMENT_MODE';
export const LOAD_SERVICE_HOLDER_BY_PAYMENT_MODE = 'LOAD_SERVICE_HOLDER_BY_PAYMENT_MODE';
export const SAVE_DONATION = 'SAVE_DONATION';

/**********************************
            GREEDING CARD
*********************************/
export const LOAD_CARDS = 'LOAD_CARDS';
export const SAVE_CARD = 'SAVE_CARD';

