import React from 'react';

const mediaList = () => {
    return (
        <div>
            <p>Media List</p>
        </div>
    );
};

export default mediaList;
