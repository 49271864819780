import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon } from "../../../mdbreact";
import { loadUsers } from '../../../store/actions/authAction';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 10,
            totalRecords: 0,
        }
    }
    componentDidMount() {
        this.props.loadUsers();
    }

    /*=======================
     * ACTION TEMPLATE
     * =====================*/
    actionTemplate = (rowData) => {
        return <div id='admin_pending_btn'>
            <MDBBtn tag="a" size="sm" floating
                gradient="aqua"
                onClick={() => this.props.editHandler(rowData.userId, this.props.auth.users)}>
                <MDBIcon icon="edit" />
            </MDBBtn>
        </div>;
    }

    render() {
        return (
            <div id='designationList'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Account List</MDBCardHeader>
                    <MDBCardBody>
                        {this.props.auth.users && 
                            <DataTable value={this.props.auth.users }
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                first={this.state.first}
                                onPage={(e) => this.setState({ first: e.first })}
                                responsive={true}
                            >
                            <Column field="name" header="User" />
                            <Column field="email" header="Email"/>
                            <Column field="mobile" header="Mobile"/>
                            <Column field="userType" header="User Type"/>
                            <Column field="mobile" header="" body={this.actionTemplate} headerStyle={{ width: '8em' }}/>
                            </DataTable>
                        }
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { loadUsers })(List);