import * as Types from '../actions/types';

const menuReducer = (state = [], action) => {

    switch (action.type) {
        case Types.LOAD_MENUS: {
            return {
                ...state,
                menus: action.payload.menus
            }
        }
        case Types.LOAD_MENU_ACCESS: {
            return {
                ...state,
                menuAccess: action.payload.menuAccess
            }
        }
        case Types.SAVE_MENU_ACCESS: {
            return {
                ...state,
                statusCode: 200,
                message: 'Menu access successfully.',
                menuAccess: action.payload.menuAccess
            }
        }
        case Types.DELETE_MENU_ACCESS: {
            let statusCode = null;
            let menuAccess = [...state.menuAccess];

            if (action.payload.isDelete) {
                statusCode = 200;
                menuAccess = menuAccess.filter(c => c.menuAccessId !== action.payload.id);
            }
            else
                statusCode = 420;

            return {
                ...state,
                statusCode: statusCode,
                message: 'Menu access delete successfully.',
                menuAccess: menuAccess
            }
        }
        default: return state;
    }
}
export default menuReducer;