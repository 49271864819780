import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import {
    MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBCard,
    MDBCardBody, MDBInput, MDBSelect, MDBDatePicker,
    MDBView, MDBInputGroup, MDBSelectOption,
    MDBSelectOptions, toast, ToastContainer, MDBSelectInput
} from '../../mdbreact';
import Dropzone from 'react-dropzone';
import ReactLoading from 'react-loading';
import { registerMember, uploadProfile } from '../../store/actions/registerAction';
import {
    loadThanas, loadOccupations, loadCountries, loadUpazilas
    , loadMunicipalities, loadUnionsByUpazila
} from '../../store/actions/settingAction';

//const imgMaxSize = 150000;
//const fileMaxSize = 500000;
const sinc = [];
//const thanas = [];
//const countries = [];
//const occupations = [];
//const upazila = [];
//const municipality = [];
//const unions = [];
const init = {    
    memberNameBd : "",
    memberNameEn : "",
    fatherName : "",
    motherName : "",
    birthPlace: "নারায়ণগঞ্জ",
    education : "",
    dob : "",
    genderId : 0,
    religiousId: "",
    spouse: "",
    hasSpouse: 'd-none',
    occupationId : 0,
    //occupations : [],
    bloodGroup : "",
    maritalStatusId : "",
    immigrationStatusId : "",
    immigrationCountryId: "",
    isImmigrate : 'd-none',
    immigrationStart : "",
    immigrationEnd : "",
    mobileNo: "",
    mobileNoOp1: "",
    mobileNoOp2: "",
    hasMultipleContact: 'd-none',
    email : "",
    fbLink : "",
    relContactNo: "",
    relContactNoOp1: "",
    relContactNoOp2: "",
    hasrelMultiContact: 'd-none',
    roadNo: "",
    //upazila : [],
    upazilaId : 0,
    unionId: 0,
    isUnionHide : 'd-none',
    municipalityId: 0,
    isMunicipalityHide : 'd-none',
    wordNo: "",
    nationalId: "",
    passportNo:"",
    isWordNoHide : 'd-none',
    formActivePanel3: 1,
    formActivePanel1Changed: false,
    thanaId: 0,
    //thanas: [],
    gender: [
        {
            text: "পুরুষ",
            value: "1"
        },
        {
            text: "মহিলা",
            value: "2"
        },
        {
            text: "অন্যান্য",
            value: "3"
        }
    ],
    bloodGroups: [
        {
            text: "A+",
            value: "A+"
        },
        {
            text: "A-",
            value: "A-"
        },
        {
            text: "B+",
            value: "B+"
        },
        {
            text: "B-",
            value: "B-"
        },
        {
            text: "AB+",
            value: "AB+"
        },
        {
            text: "AB-",
            value: "AB-"
        },
        {
            text: "O+",
            value: "O+"
        },
        {
            text: "O-",
            value: "O-"
        }
    ],
    religious: [
        {
            text: "ইসলাম",
            value: "1"
        },
        {
            text: "হিন্দু",
            value: "2"
        },
        {
            text: "বৌদ্ধ",
            value: "3"
        },
        {
            text: "খ্রিস্টান",
            value: "4"
        },
        {
            text: "অন্যান্য",
            value: "5"
        }
    ],
    maritalStatus: [
        {
            text: "বিবাহিত",
            value: "1"
        },
        {
            text: "অবিবাহিত",
            value: "2"
        },
    ],
    immigrationStatus: [
        {
            text: "প্রবাসী",
            value: "1"
        },
        {
            text: "প্রবাস ফেরত",
            value: "2"
        },
    ],
    since: [],
    //countries: [],
    cityUnions: [
        {
            text: "নারায়ণগঞ্জ সিটি কর্পোরেশন",
            value: "1"
        },
        {
            text: "ইউনিয়ন",
            value: "2"
        },
        {
            text: "পৌরসভা",
            value: "3"
        }
    ],
    //municipality: [],
    //unions: [],
    message: "",
    isSaved: null,
    dobConvert: '',
    imageFile: null,
    docFile: null,
    reglabelClass: 'reglabelClassError',
    preLoading:'d-none',
    postLoading: 'd-block',
    isThanaLoad: true,
    isOccupationLoad: true,
    isCountryLoad: true,
    formOrientation: window.screen.width < 768 ? true : false,
    srcUrl: '',
    isSrcUrl: {
        'display': 'none'
    },
}
class Registration extends Component {

    constructor(props) {
        super(props);
        this.state = init;
        //console.log("Width:", window.screen.width);
        //console.log("Height:", window.screen.height);
    }    

    componentDidMount() {
        this.incSinc();      
        this.props.loadThanas();
        this.props.loadOccupations();
        this.props.loadCountries();
        this.props.loadUpazilas();
        this.props.loadMunicipalities();
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.reg.isSuccess) {
            nextProps.reg.isSuccess = null;
            toast.success('Registration completed successfully.', {
                closeButton: true,
                onClose: () => {
                    window.location.reload()
                }
            });
        }
        else if (nextProps.reg.isSuccess == false && nextProps.reg.statusCode) {
            nextProps.reg.isSuccess = null;
            toast.error('Registration failed.', {
                closeButton: true
            });
            return {
                preLoading:'d-none',
                postLoading:'d-block'
            }
        }
    }
    /*==================================
     * INIT LOADER
     *=================================*/

    incSinc() {
        
        let curYear = new Date().getFullYear();
        for (var i = curYear; i >= 1960; i--) {
            sinc.push({text : i,value : i });
        }
        this.setState({
            ...this.state,
            since: sinc
        })
    }

    swapFormActive = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
    }

    handleNextPrevClick = (a) => (param) => (e) => {
        this.setState({
            ['formActivePanel' + a]: param,
            ['formActivePanel' + a + 'Changed']: true
        });
        window.scrollTo(0,0);
    }

    calculateAutofocus = (a) => {
        if (this.state['formActivePanel' + a + 'Changed']) {
            return true
        }
    }

    changeHandler = event => {
        //const value = event.target.innerHTML;
        //console.log(event);
        //console.log(event.target.name + '//' + event.target.value);
        this.setState({
            ...this.state,
            [event.target.name] : event.target.value 
        });
    }

    formatDate = date => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    getPickerValue = (value) => {
        var date = this.formatDate(value);
        this.setState({
            ...this.state,
            dob: date
        });
    }

    //regThanaChangeHandler = event => {
    //    this.setState({
    //        ...this.state,
    //        thanaId: parseInt(event)
    //    });
    //}

    //genderChangeHandler = event => {
    //    this.setState({
    //        ...this.state,
    //        genderId: parseInt(event)
    //    });
    //}

    //religiousChangeHandler = event => {
    //    this.setState({
    //        ...this.state,
    //        religiousId: parseInt(event)
    //    });
    //}

    //occupationChangeHandler = event => {
    //    this.setState({
    //        ...this.state,
    //        occupationId: parseInt(event)
    //    });
    //}

    //bloodGroupChangeHandler = event => {
    //    this.setState({
    //        ...this.state,
    //        bloodGroup:event[0]
    //    });
    //}
    
    maritalStatusChangeHandler = event => {
        let hasSpouse = 'd-none';
        if (event.target.value === "1") {
            hasSpouse = 'd-block';
        }
        this.setState({
            ...this.state,
            hasSpouse: hasSpouse,
            maritalStatusId: parseInt(event.target.value)
        });
    }

    multipleContactHandler = () => {
        let hide = '';
        if (this.state.hasMultipleContact == 'd-none')
            hide = 'd-block';
        else
            hide = 'd-none';

        this.setState({
            ...this.state,
            hasMultipleContact: hide
        });
    }

    relmultiContactHandler = () => {
        let hide = '';
        if (this.state.hasrelMultiContact == 'd-none')
            hide = 'd-block';
        else
            hide = 'd-none';

        this.setState({
            ...this.state,
            hasrelMultiContact: hide
        });
    }

    immigrationStatusHandler = (event) => {
        let hide = 'd-none';
        if (event.target.value === "2") {
            hide = 'd-block';
        }

        this.setState({
            ...this.state,
            immigrationStatusId: parseInt(event.target.value),
            isImmigrate: hide
        });
    }

    //immigrationCountryHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationCountryId: parseInt(event)
    //    });
    //}

    //immigrationStartHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationStart: parseInt(event)
    //    });
    //}

    //immigrationEndHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationEnd: parseInt(event)
    //    });
    //}

    upazilaChangeHandler = (event) => {
        let upazilaId = event.target.value;
        if (this.state.isUnionHide == 'd-block')
            this.props.loadUnionsByUpazila(upazilaId)
        this.setState({
            ...this.state,
            upazilaId: parseInt(upazilaId)
        });
    }

    cityUnionsChangeHandler = (event) => {
        let data = event.target.value;
        let municipalityHide = 'd-none';
        let unionHide = 'd-none';
        let wordNoHide = 'd-none';

        if (data === '1') {
            wordNoHide = 'd-block';
        }
        else if (data === '2') {
            unionHide = 'd-block';
            wordNoHide = 'd-block';
        }
        else if (data === '3') {
            municipalityHide = 'd-block';
            wordNoHide = 'd-block';
        }
        this.setState({
            ...this.state,
            isMunicipalityHide: municipalityHide,
            isUnionHide: unionHide,
            isWordNoHide: wordNoHide
        });
    }

    municipalityHandler = (event) => {

        this.setState({
            ...this.state,
            municipalityId: parseInt(event)
        });
    }

    unionsChangeHandler = (event) => {

        this.setState({
            ...this.state,
            unionId: parseInt(event)
        });
    }

    /*=========UPLOAD HANDLER=========*/
    profileUploadHandler = event => {
        ///console.log(event[0]);
        this.setState({
            ...this.state,
            imageFile: event[0]
        });

        //if (event[0].size > imgMaxSize) {
        //    toast.error('ছবির সাইজ 150kb রাখুন!', {
        //        closeButton: true
        //    });
        //}
        let ext = ['jpg', 'jpeg', 'png','pdf'];
        let imgExt = event[0].name.split('.');
        if (!ext.includes(imgExt.pop().toLowerCase())) {
            toast.error('ছবির এক্সটেনশন ঠিক নেই', {
                closeButton: true
            });
        }
    }

    //docUploadHandler = event => {
    //    //console.log(event);
    //    this.setState({
    //        ...this.state,
    //        docFile: event[0]
    //    });
    //    if (event[0].size > fileMaxSize) {
    //        toast.error('ডকুমেন্ট সাইজ 500kb রাখুন!', {
    //            closeButton: true
    //        });
    //    }
    //    else {
    //        let ext = ['doc', 'docx', 'pdf'];
    //        let docExt = event[0].name.split('.');
    //        if (!ext.includes(docExt.pop().toLowerCase())) {
    //            toast.error('ডকুমেন্ট এক্সটেনশন ঠিক নেই', {
    //                closeButton: true
    //            });
    //        }
    //    }
    //}

    /*===========VALIDATION===========*/
    validationHandler = () => {
        let error = false;
        
        if (this.state.thanaId == 0) {
            toast.error('থানার নাম দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.memberNameBd == "") {
            toast.error('আপনার নাম বাংলায় দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.memberNameEn == "") {
            toast.error('আপনার নাম ইংরেজীতে দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.fatherName == "") {
            toast.error('আপনার পিতার নাম দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.motherName == "") {
            toast.error('আপনার মাতার নাম দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.motherName == "") {
            toast.error('জন্ম তারিখ দিন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.genderId == 0) {
            toast.error('লিঙ্গ নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.religiousId == 0) {
            toast.error('ধর্ম নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.occupationId == 0) {
            toast.error('পেশা নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.bloodGroup == "") {
            toast.error('রক্তের গ্রুপ নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.maritalStatusId == "") {
            toast.error('বৈবাহিক অবস্থা নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.immigrationStatusId == "") {
            toast.error('বর্তমান অবস্থান নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.immigrationCountryId == 0) {
            toast.error('অবস্থানকৃত দেশের নাম নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.immigrationStart == "") {
            toast.error('প্রবাস জীবন কখন শুরু!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.mobileNo == "") {
            toast.error('মোবাইল নাম্বার প্রদান করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.roadNo == "") {
            toast.error('রাস্তা/গ্রাম/মহল্লা প্রদান করুন!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.upazilaId == 0) {
            toast.error('উপজেলা নির্বাচন করুন!', {
                closeButton: true
            });
            return error = true;
        }

        if (this.state.imageFile == null) {
            toast.error('ছবি আপলোড করুন!', {
                closeButton: true
            });
            return error = true;
        }
        else {

            let ext = ['jpg', 'jpeg', 'png','pdf'];
            let imgExt = this.state.imageFile.name.split('.');
            if (!ext.includes(imgExt.pop().toLowerCase())) {
                toast.error('ছবির এক্সটেনশন ঠিক নেই', {
                    closeButton: true
                });
                return error = true;
            }

            //if (this.state.imageFile.size > imgMaxSize) {
            //    toast.error('ছবির সাইজ 150kb রাখুন!', {
            //        closeButton: true
            //    });
            //    return error = true;
            //}
            //else {
            //    let ext = ['jpg', 'jpeg', 'png'];
            //    let imgExt = this.state.imageFile.name.split('.');
            //    if (!ext.includes(imgExt.pop().toLowerCase())) {
            //        toast.error('ছবির এক্সটেনশন ঠিক নেই', {
            //            closeButton: true
            //        });
            //        return error = true;
            //    }
            //}
        }
        //if (this.state.docFile == null) {
        //    toast.error('ডকুমেন্ট আপলোড করুন!', {
        //        closeButton: true
        //    });
        //    return error = true;
        //}
        //else {
        //    if (this.state.docFile.size > fileMaxSize) {
        //        toast.error('ডকুমেন্ট সাইজ 500kb রাখুন!', {
        //            closeButton: true
        //        });
        //        return error = true;
        //    }
        //    else {
        //        let ext = ['doc', 'docx', 'pdf'];
        //        let docExt = this.state.docFile.name.split('.');
        //        if (!ext.includes(docExt.pop().toLowerCase())) {
        //            toast.error('ডকুমেন্ট এক্সটেনশন ঠিক নেই', {
        //                closeButton: true
        //            });
        //            return error = true;
        //        }
        //    }
        //}
        return error;
    }
    
    /*=========SUBMIT HANDLER=========*/
    handleSubmission = () => {

        let error = this.validationHandler();
        
        if(error)
            return;

        if (this.state.hasSpouse === 'd-none')
            this.state.spouse = '';
        if (this.state.isImmigrate === 'd-none')
            this.state.immigrationEnd = 0;
        if (this.state.hasMultipleContact === 'd-none') {
            this.state.mobileNoOp1 = '';
            this.state.mobileNoOp2 = '';
        }
        if (this.state.hasrelMultiContact === 'd-none') {
            this.state.relContactNoOp1 = '';
            this.state.relContactNoOp2 = '';
        }
        if (this.state.isUnionHide === 'd-none')
            this.state.unionId = 0;
        if (this.state.isMunicipalityHide === 'd-none')
            this.state.municipalityId = 0;

        if (this.state.dob)
            this.state.dobConvert = this.state.dob;

        let {
            thanaId,memberNameBd,memberNameEn,fatherName,motherName,dobConvert,birthPlace,education,
            genderId,religiousId,spouse,occupationId,bloodGroup,maritalStatusId,immigrationStatusId,
            immigrationCountryId,immigrationStart,immigrationEnd,mobileNo,mobileNoOp1,mobileNoOp2,
            email,fbLink,relContactNo,relContactNoOp1,relContactNoOp2,roadNo,upazilaId,unionId,
            municipalityId, wordNo, nationalId, passportNo } = this.state       

        let member = {
            thanaId: thanaId,
            memberNameBd: memberNameBd,
            memberNameEn: memberNameEn,
            fatherName: fatherName,
            motherName: motherName,
            birthPlace: birthPlace,
            education: education,
            genderId: genderId,
            dobConvert: dobConvert,
            religiousId: religiousId,
            spouse: spouse,
            occupationId: occupationId,
            bloodGroup: bloodGroup,
            maritalStatusId: maritalStatusId,
            immigrationStatusId: immigrationStatusId,
            immigrationCountryId: immigrationCountryId,
            immigrationStart: immigrationStart,
            immigrationEnd: immigrationEnd,
            mobileNo: mobileNo,
            mobileNoOp1: mobileNoOp1,
            mobileNoOp2: mobileNoOp2,
            email: email,
            fbLink: fbLink,
            relContactNo: relContactNo,
            relContactNoOp1: relContactNoOp1,
            relContactNoOp2: relContactNoOp2,
            roadNo: roadNo,
            upazilaId: upazilaId,
            unionId: unionId,
            municipalityId: municipalityId,
            wordNo: wordNo,
            nationalId: nationalId,
            passportNo: passportNo
        }

        let formData = new FormData();
        formData.append('member', JSON.stringify(member));
        formData.append('imageFile', this.state.imageFile);
        //formData.append('docFile', this.state.docFile);

        this.setState({
            ...this.state,
            postLoading: 'd-none',
            preLoading: 'd-block'
        });
        //console.log(member);
        this.props.registerMember(formData);
        //this.setState({
        //    ...init
        //});


        //member.append("FileUploads", this.state.imageFile);

        //console.log(this.state.imageFile);
        //console.log('..............');
        //console.log(Array.from(member));
        //for (let obj of member) {
        //    console.log(obj);
        //}
        
    }

    //uploadHandler = () => {
    //    let member = {
    //        name: 'walif',
    //        userId: 123
    //    };
    //    let formData = new FormData();
    //    formData.append('member', JSON.stringify(member));
    //    formData.append('imageFile', this.state.imageFile);
    //    formData.append('docFile', this.state.docFile);
    //    this.props.uploadProfile(formData);
    //}

    //static getDerivedStateFromProps(nextProps, prevState) {
    //    //console.log(nextProps);

    //    if (nextProps.reg.isSuccess) {
    //        nextProps.reg.isSuccess = null;
    //        toast.success('Registration completed successfully.', {
    //            closeButton: true,
    //            onClose: () => nextProps.history.push('/')
    //        });
    //    }
    //    else if (nextProps.reg.isSuccess == false && nextProps.reg.statusCode) {
    //        nextProps.reg.isSuccess = null;
    //        toast.error('Registration failed.', {
    //            closeButton: true
    //        });
    //        return {
    //            preLoading:'d-none',
    //            postLoading:'d-block'
    //        }
    //    }
    //    return null;
    //}

    /*============ IMAGE PREVIEW ========== */
    imagePreviewHandler = (files, rejectedFiles) => {
        //console.log(files);
        //return;
        //rejected image
        if (rejectedFiles.length > 0) {
            rejectedFiles[0].errors.map(err => {
                toast.error(err.code, {
                    closeButton: true
                });
            })
        }
        else {
            //process image preview
            const currentFile = files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                //console.log(reader.result);
                this.setState({
                    ...this.state,
                    imageFile: currentFile,
                    srcUrl: reader.result,
                    isSrcUrl: {
                        'display': 'block'
                    }
                })
            }, false);
            reader.readAsDataURL(currentFile);
        }
    }

    render() {

        let {
            gender, genderId, religious, religiousId, bloodGroups, occupationId, bloodGroup,
            maritalStatus, maritalStatusId, thanaId, immigrationStatus, immigrationStatusId, since,
            immigrationCountryId, immigrationStart, immigrationEnd, cityUnions, hasSpouse, hasMultipleContact,
            hasrelMultiContact, isImmigrate, isMunicipalityHide, isUnionHide, isWordNoHide, memberNameBd,
            memberNameEn, fatherName, motherName, birthPlace, education, spouse, mobileNo, mobileNoOp1,
            mobileNoOp2, fbLink,relContactNo, relContactNoOp1, relContactNoOp2, roadNo, wordNo, nationalId,
            passportNo, email, reglabelClass, preLoading, postLoading, formOrientation, isSrcUrl, srcUrl,
            porosobaId, upazilaId, municipalityId, unionId
        } = this.state;
        //console.log(countries);
        return (
            <div id="client_reg">
                <MDBView className="client_reg_banner" waves>
                    <img
                        src={require('../../assets/images/np_banner_01.jpg')}
                        className="img-fluid"
                        alt=""
                    />
                    {
                        //<MDBMask className="flex-center client_title" overlay="teal-light">
                        //    <h1 className="white-text">সদস্য নিবন্ধন ফর্ম</h1>
                        //</MDBMask>
                    }
                </MDBView>

                <MDBContainer>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBRow className="pt-5 justify-content-center">
                                {
                                    formOrientation ?
                                        <MDBStepper icon className="iconResize">
                                            <MDBStep far icon="user-circle" stepName="মৌলিক তথ্যাবলী" onClick={this.swapFormActive(3)(1)}></MDBStep>
                                            <MDBStep fas icon="plane-departure" stepName="ইমিগ্রেশন তথ্যাবলী" onClick={this.swapFormActive(3)(2)}></MDBStep>
                                            <MDBStep fas icon="map-marked" stepName="যোগাযোগের তথ্যাবলী" onClick={this.swapFormActive(3)(3)}></MDBStep>
                                            <MDBStep icon="folder-open" stepName="ডকুমেন্ট" onClick={this.swapFormActive(3)(4)}></MDBStep>
                                            {
                                                //<MDBStep fas icon="tasks" stepName="শর্তাবলী" onClick={this.swapFormActive(3)(5)}></MDBStep>
                                            }
                                        </MDBStepper>
                                        :
                                        <MDBCol md="2" className="pl-5 pl-md-0 pb-5">
                                            <MDBStepper icon vertical>
                                                <MDBStep far icon="user-circle" stepName="মৌলিক তথ্যাবলী" onClick={this.swapFormActive(3)(1)} vertical />
                                                <MDBStep fas icon="plane-departure" stepName="ইমিগ্রেশন তথ্যাবলী" onClick={this.swapFormActive(3)(2)} vertical />
                                                <MDBStep fas icon="map-marked" stepName="যোগাযোগের তথ্যাবলী" onClick={this.swapFormActive(3)(3)} vertical />
                                                <MDBStep icon="folder-open" stepName="ডকুমেন্ট" onClick={this.swapFormActive(3)(4)} vertical />
                                                {
                                                    //<MDBStep fas icon="tasks" stepName="শর্তাবলী" onClick={this.swapFormActive(3)(5)} vertical />
                                                }
                                            </MDBStepper>
                                        </MDBCol>                                
                                }

                                <MDBCol md="7">
                                    {this.state.formActivePanel3 === 1 && (
                                        <MDBCol md="12">
                                            <h3 className="font-weight-bold pl-0 my-4">
                                                <strong>মৌলিক তথ্য</strong>
                                            </h3>
                                            {
                                                <Form.Group>
                                                    <Form.Label className={reglabelClass}>থানা(*)</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="thanaId"
                                                        id="thanaId"
                                                        value={thanaId}                                                        
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            this.props.setting.thanas &&
                                                            this.props.setting.thanas.map((c, index) => {
                                                                return (<option key={index} value={c.thanaId}>{c.thana}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>

                                                //<MDBSelect label="থানা(*)"
                                                //    name="thanaId"
                                                //    getValue={this.regThanaChangeHandler}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                //    autoFocus={this.calculateAutofocus(1)}
                                                //>
                                                //    <MDBSelectInput selected="থানা নির্বাচন করুন" />
                                                //    <MDBSelectOptions>
                                                //        <MDBSelectOption disabled>থানা নির্বাচন করুন</MDBSelectOption>
                                                //        {
                                                //            this.props.setting.thanas &&
                                                //            this.props.setting.thanas.map(cnt => {
                                                //                return <MDBSelectOption key={cnt.thanaId} value={cnt.thanaId}>{cnt.thana}</MDBSelectOption>
                                                //            })
                                                //        }
                                                //    </MDBSelectOptions>
                                                //</MDBSelect>

                                                //<MDBSelect
                                                //    options={thanas}
                                                //    selected="থানা নির্বাচন করুন"
                                                //    label="থানা(*)"
                                                //    name="thanaId"
                                                //    getValue={this.regThanaChangeHandler}
                                                //    autoFocus={this.calculateAutofocus(1)}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                //    require
                                                ///>
                                            }
                                            <MDBInput label="নাম ( বাংলায় )*"
                                                name="memberNameBd"
                                                className="mt-4"
                                                value={memberNameBd}
                                                onChange={this.changeHandler}
                                                labelClass={reglabelClass}                                                
                                            />
                                            <MDBInput label="Name ( In English )*"
                                                name="memberNameEn"
                                                className="mt-4"
                                                value={memberNameEn}
                                                onChange={this.changeHandler}
                                                labelClass={reglabelClass}                                                
                                            />
                                            <MDBInput label="পিতার নাম(*)"
                                                name="fatherName"
                                                className="mt-4"
                                                value={fatherName}
                                                onChange={this.changeHandler}
                                                labelClass={reglabelClass}                                                
                                            />
                                            <MDBInput label="মাতার নাম(*)"
                                                name="motherName"
                                                className="mt-4"
                                                value={motherName}
                                                onChange={this.changeHandler}
                                                labelClass={reglabelClass}                                                
                                            />

                                            <div className="client_dob">
                                                <label className={reglabelClass}>জন্ম তারিখ(*)</label>
                                                <MDBDatePicker
                                                    name="dob"
                                                    className="mt-4"
                                                    getValue={this.getPickerValue}
                                                    format='MM/DD/YYYY'
                                                />
                                            </div>
                                            
                                            <MDBInput label="জন্ম স্থান"
                                                name="birthPlace"
                                                className="mt-4"
                                                value={birthPlace}
                                                onChange={this.changeHandler}
                                            />                                          
                                            <MDBInput label="শিক্ষাগত যোগ্যতা"
                                                name="education"
                                                className="mt-4"
                                                value={education}
                                                onChange={this.changeHandler}
                                            />

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>লিঙ্গ(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="genderId"
                                                    id="genderId"
                                                    value={genderId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        gender &&
                                                        gender.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>ধর্ম(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="religiousId"
                                                    id="religiousId"
                                                    value={religiousId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        religious &&
                                                        religious.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>পেশা(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="occupationId"
                                                    id="occupationId"
                                                    value={occupationId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.occupations &&
                                                        this.props.setting.occupations.map((c, index) => {
                                                            return (<option key={index} value={c.occupationId}>{c.occupation}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>রক্তের গ্রুপ(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="bloodGroup"
                                                    id="bloodGroup"
                                                    value={bloodGroup}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        bloodGroups &&
                                                        bloodGroups.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>বৈবাহিক অবস্থা(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="maritalStatusId"
                                                    id="maritalStatusId"
                                                    value={maritalStatusId}
                                                    onChange={this.maritalStatusChangeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        maritalStatus &&
                                                        maritalStatus.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            {
                                                //<MDBSelect
                                                //    options={gender}
                                                //    selected="লিঙ্গ নির্বাচন করুন"
                                                //    label="লিঙ্গ(*)"
                                                //    name="gender"
                                                //    className="mt-4"
                                                //    getValue={this.genderChangeHandler}
                                                //    labelClass={reglabelClass}
                                                ///>

                                                //<MDBSelect
                                                //    options={religious}
                                                //    selected="ধর্ম নির্বাচন করুন"
                                                //    label="ধর্ম(*)"
                                                //    name="religiousId"
                                                //    className="mt-4"
                                                //    getValue={this.religiousChangeHandler}
                                                //    labelClass={reglabelClass}
                                                ///>
                                                //<MDBSelect label="পেশা(*)"
                                                //    name="occupationId"
                                                //    getValue={this.occupationChangeHandler}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                //>
                                                //    <MDBSelectInput selected="পেশা নির্বাচন করুন" />
                                                //    <MDBSelectOptions>
                                                //        <MDBSelectOption disabled>পেশা নির্বাচন করুন</MDBSelectOption>
                                                //        {
                                                //            this.props.setting.occupations &&
                                                //            this.props.setting.occupations.map(cnt => {
                                                //                return <MDBSelectOption key={cnt.occupationId} value={cnt.occupationId}>{cnt.occupation}</MDBSelectOption>
                                                //            })
                                                //        }
                                                //    </MDBSelectOptions>
                                                //</MDBSelect>

                                                //<MDBSelect
                                                //    options={occupations}
                                                //    selected="পেশা নির্বাচন করুন"
                                                //    label="পেশা(*)"
                                                //    name="occupationId"
                                                //    className="mt-4"
                                                //    getValue={this.occupationChangeHandler}
                                                //    labelClass={reglabelClass}
                                                //    require
                                                ///>
                                            
                                            //<MDBSelect
                                            //    options={bloodGroups}
                                            //    selected="রক্তের গ্রুপ নির্বাচন করুন"
                                            //    label="রক্তের গ্রুপ(*)"
                                            //    name="bloodGroup"
                                            //    className="mt-4"
                                            //    getValue={this.bloodGroupChangeHandler}
                                            //    labelClass={reglabelClass}                                                
                                            ///>
                                            //<MDBSelect
                                            //    options={maritalStatus}
                                            //    selected="বৈবাহিক অবস্থা নির্বাচন করুন"
                                            //    label="বৈবাহিক অবস্থা(*)"
                                            //    name="maritalStatusId"
                                            //    className="mt-4"
                                            //    getValue={this.maritalStatusChangeHandler}
                                            //    labelClass={reglabelClass}
                                            //    />
                                            }
                                            <div className={hasSpouse}>
                                                <MDBInput label="স্ত্রীর নাম"
                                                    name="spouse"
                                                    className="mt-4"
                                                    value={spouse}
                                                    onChange={this.changeHandler}
                                                />
                                            </div>
                                            <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(2)}>
                                                next
                                            </MDBBtn>
                                        </MDBCol>
                                    )}
                                    {this.state.formActivePanel3 === 2 && (
                                        <MDBCol md="12">
                                            <h3 className="font-weight-bold pl-0 my-4">
                                                <strong>ইমিগ্রেশন তথ্যাবলী</strong>
                                            </h3>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>বর্তমান অবস্থান(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="immigrationStatusId"
                                                    id="immigrationStatusId"
                                                    value={immigrationStatusId}
                                                    onChange={this.immigrationStatusHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        immigrationStatus &&
                                                        immigrationStatus.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>অবস্থানকৃত দেশের নাম(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="immigrationCountryId"
                                                    id="immigrationCountryId"
                                                    value={immigrationCountryId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.countries &&
                                                        this.props.setting.countries.map((c, index) => {
                                                            return (<option key={index} value={c.countryId}>{c.countryName}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                            {
                                            //<MDBSelect
                                            //    options={immigrationStatus}
                                            //    selected="বর্তমান অবস্থান নির্বাচন করুন"
                                            //    label="বর্তমান অবস্থান(*)"
                                            //    name="immigrationStatusId"
                                            //    getValue={this.immigrationStatusHandler}
                                            //    className="mt-4"
                                            //    labelClass={reglabelClass}                                                
                                            ///>
                                           
                                            //    <MDBSelect label="অবস্থানকৃত দেশের নাম(*)"
                                            //        name="immigrationCountryId"
                                            //        getValue={this.immigrationCountryHandler}
                                            //        className="mt-4"
                                            //        labelClass={reglabelClass}
                                            //    >
                                            //        <MDBSelectInput selected="দেশের নাম নির্বাচন করুন" />
                                            //        <MDBSelectOptions>
                                            //            <MDBSelectOption disabled>দেশের নাম নির্বাচন করুন</MDBSelectOption>
                                            //            {
                                            //                this.props.setting.countries &&
                                            //                this.props.setting.countries.map(cnt => {
                                            //                    return <MDBSelectOption key={cnt.countryId} value={cnt.countryId}>{cnt.countryName}</MDBSelectOption>
                                            //                })
                                            //            }
                                            //        </MDBSelectOptions>
                                            //    </MDBSelect>
                                                //<MDBSelect
                                                //    options={countries}
                                                //    selected="অবস্থানকৃত দেশের নাম নির্বাচন করুন"
                                                //    label="অবস্থানকৃত দেশের নাম(*)"
                                                //    name="immigrationCountryId"
                                                //    getValue={this.immigrationCountryHandler}
                                                //    className="mt-4"
                                                //    labelClass={this.state.reglabelClass}
                                                //    require
                                                ///> 
                                            }
                                        
                                            <div className="clear-fixed">
                                                <strong>প্রবাসে অবস্থানের সময়কাল</strong>
                                            </div>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>শুরু(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="immigrationStart"
                                                    id="immigrationStart"
                                                    value={immigrationStart}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        since &&
                                                        since.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <div className={isImmigrate}>
                                                <Form.Group>
                                                    <Form.Label className={reglabelClass}>শেষ(*)</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="immigrationEnd"
                                                        id="immigrationEnd"
                                                        value={immigrationEnd}
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            since &&
                                                            since.map((c, index) => {
                                                                return (<option key={index} value={c.value}>{c.text}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            {
                                                //<MDBSelect
                                                //    options={since}
                                                //    selected="শুরু"
                                                //    label="শুরু(*)"
                                                //    name="immigrationStart"
                                                //    getValue={this.immigrationStartHandler}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                ///>
                                                //<div className={isImmigrate}>
                                                //    <MDBSelect
                                                //        options={since}
                                                //        selected="শেষ"
                                                //        label="শেষ"
                                                //        name="immigrationEnd"
                                                //        getValue={this.immigrationEndHandler}
                                                //        className="mt-4"
                                                //    />
                                                //</div>
                                                }
                                            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(1)}>
                                                previous
                                            </MDBBtn>
                                            <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(3)}>
                                                next
                                            </MDBBtn>
                                        </MDBCol>
                                    )}
                                    {this.state.formActivePanel3 === 3 && (
                                        <MDBCol md="12">
                                            <h3 className="font-weight-bold pl-0 my-4">
                                                <strong>যোগাযোগের তথ্যাবলী</strong>
                                            </h3>
                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0 reg_contact"
                                                inputs={
                                                    <MDBInput
                                                        label="মোবাইল নাম্বার(*)"
                                                        name="mobileNo"
                                                        onChange={this.changeHandler}
                                                        autoFocus={this.calculateAutofocus(3)}
                                                        value={mobileNo}
                                                        labelClass={reglabelClass}                                                        
                                                    />
                                                }
                                                append={
                                                    <MDBBtn
                                                        color="secondary"
                                                        className="m-0 px-3 py-2 z-depth-0"
                                                        onClick={this.multipleContactHandler}
                                                    > +
                                                    </MDBBtn>
                                                }
                                            />
                                            <div className={hasMultipleContact}>

                                                <MDBInput label="মোবাইল নাম্বার (অপঃ০১)"
                                                    name="mobileNoOp1"
                                                    className="mt-4"
                                                    value={mobileNoOp1}
                                                    onChange={this.changeHandler}
                                                />

                                                <MDBInput label="মোবাইল নাম্বার (অপঃ০২)"
                                                    name="mobileNoOp2"
                                                    className="mt-4"
                                                    value={mobileNoOp2}
                                                    onChange={this.changeHandler}
                                                />

                                            </div>
                                            <MDBInput label="ই-মেইল"
                                                name="email"
                                                value={email}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                            />
                                            <MDBInput label="ফেইসবুক প্রফাইল লিংক"
                                                name="fbLink"
                                                value={fbLink}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                            />

                                            <MDBInputGroup
                                                material
                                                containerClassName="mb-3 mt-0 reg_contact"
                                                inputs={
                                                    <MDBInput
                                                        name="relContactNo"
                                                        label="নিকট স্বজনের মোবাইল নাম্বার"
                                                        value={relContactNo}
                                                        onChange={this.changeHandler}
                                                    />
                                                }
                                                append={
                                                    <MDBBtn
                                                        color="secondary"
                                                        className="m-0 px-3 py-2 z-depth-0"
                                                        onClick={this.relmultiContactHandler}
                                                    > +
                                                    </MDBBtn>
                                                }
                                            />
                                            <div className={hasrelMultiContact}>

                                                <MDBInput label="মোবাইল নাম্বার (অপঃ০১)"
                                                    name="relContactNoOp1"
                                                    className="mt-4"
                                                    value={relContactNoOp1}
                                                    onChange={this.changeHandler}
                                                />

                                                <MDBInput label="মোবাইল নাম্বার (অপঃ০২)"
                                                    name="relContactNoOp2"
                                                    className="mt-4"
                                                    value={relContactNoOp2}
                                                    onChange={this.changeHandler}
                                                />

                                            </div>

                                            <strong>স্থায়ী ঠিকানা</strong>
                                            <MDBInput label="রাস্তা/গ্রাম/মহল্লা(*)"
                                                name="roadNo"
                                                value={roadNo}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                                labelClass={reglabelClass}                                                
                                            />

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>নারায়ণগঞ্জ সিটি কর্পোরেশন/ইউনিয়ন/পৌরসভা(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="porosobaId"
                                                    id="porosobaId"
                                                    value={porosobaId}
                                                    onChange={this.cityUnionsChangeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        cityUnions &&
                                                        cityUnions.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label className={reglabelClass}>উপজেলা(*)</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="upazilaId"
                                                    id="upazilaId"
                                                    value={upazilaId}
                                                    onChange={this.upazilaChangeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.upazilas &&
                                                        this.props.setting.upazilas.map((c, index) => {
                                                            return (<option key={index} value={c.upazilaId}>{c.upazilaName}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>

                                            <div className={isMunicipalityHide}>
                                                <Form.Group>
                                                    <Form.Label className={reglabelClass}>পৌরসভা(*)</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="municipalityId"
                                                        id="municipalityId"
                                                        value={municipalityId}
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            this.props.setting.municipalities &&
                                                            this.props.setting.municipalities.map((c, index) => {
                                                                return (<option key={index} value={c.municipalityId}>{c.municipalityName}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>

                                            <div className={isUnionHide}>
                                                <Form.Group>
                                                    <Form.Label className={reglabelClass}>ইউনিয়ন(*)</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="unionId"
                                                        id="unionId"
                                                        value={unionId}
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            this.props.setting.unions &&
                                                            this.props.setting.unions.map((c, index) => {
                                                                return (<option key={index} value={c.unionId}>{c.unionName}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>

                                            {
                                                //<MDBSelect
                                                //    options={cityUnions}
                                                //    selected="নারায়ণগঞ্জ সিটি কর্পোরেশন/ইউনিয়ন/পৌরসভা নির্বাচন করুন"
                                                //    label="নারায়ণগঞ্জ সিটি কর্পোরেশন/ইউনিয়ন/পৌরসভা(*)"
                                                //    name="porosobaId"
                                                //    getValue={this.cityUnionsChangeHandler}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                ///>

                                                //<MDBSelect label="উপজেলা(*)"
                                                //    name="upazilaId"
                                                //    getValue={this.upazilaChangeHandler}
                                                //    className="mt-4"
                                                //    labelClass={reglabelClass}
                                                //>
                                                //    <MDBSelectInput selected="উপজেলা নির্বাচন করুন" />
                                                //    <MDBSelectOptions>
                                                //        <MDBSelectOption disabled>উপজেলা নির্বাচন করুন</MDBSelectOption>
                                                //        {
                                                //            this.props.setting.upazilas &&
                                                //            this.props.setting.upazilas.map(c => {
                                                //                return <MDBSelectOption key={c.upazilaId} value={c.upazilaId}>{c.upazilaName}</MDBSelectOption>
                                                //            })
                                                //        }
                                                //    </MDBSelectOptions>
                                                //</MDBSelect>

                                                //<div className={isMunicipalityHide}>
                                                //    <MDBSelect label="পৌরসভা(*)"
                                                //        name="municipalityId"
                                                //        getValue={this.municipalityHandler}
                                                //        className="mt-4"
                                                //        labelClass={reglabelClass}
                                                //    >
                                                //        <MDBSelectInput selected="পৌরসভা নির্বাচন করুন" />
                                                //        <MDBSelectOptions>
                                                //            <MDBSelectOption disabled>পৌরসভা নির্বাচন করুন</MDBSelectOption>
                                                //            {
                                                //                this.props.setting.municipalities &&
                                                //                this.props.setting.municipalities.map(c => {
                                                //                    return <MDBSelectOption key={c.municipalityId} value={c.municipalityId}>{c.municipalityName}</MDBSelectOption>
                                                //                })
                                                //            }
                                                //        </MDBSelectOptions>
                                                //    </MDBSelect>
                                                //</div>
                                                //<div className={isUnionHide}>
                                                //    {
                                                //        <MDBSelect label="ইউনিয়ন(*)"
                                                //            name="municipalityId"
                                                //            getValue={this.unionsChangeHandler}
                                                //            className="mt-4"
                                                //            labelClass={reglabelClass}
                                                //        >
                                                //            <MDBSelectInput selected="ইউনিয়ন নির্বাচন করুন" />
                                                //            <MDBSelectOptions>
                                                //                <MDBSelectOption disabled>ইউনিয়ন নির্বাচন করুন</MDBSelectOption>
                                                //                {
                                                //                    this.props.setting.unions &&
                                                //                    this.props.setting.unions.map(c => {
                                                //                        return <MDBSelectOption key={c.unionId} value={c.unionId}>{c.unionName}</MDBSelectOption>
                                                //                    })
                                                //                }
                                                //            </MDBSelectOptions>
                                                //        </MDBSelect>
                                                //    }
                                                //</div>
                                                }
                                            <div className={isWordNoHide}>
                                                <MDBInput label="ওয়ার্ড নং(*)"
                                                    name="wordNo"
                                                    value={wordNo}
                                                    onChange={this.changeHandler}
                                                    className="mt-4"
                                                    labelClass={reglabelClass}                                                    
                                                />
                                            </div>
                                            <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(2)}>
                                                previous
                                            </MDBBtn>
                                            <MDBBtn color="mdb-color" rounded className="float-right" onClick={this.handleNextPrevClick(3)(4)}>
                                                next
                                            </MDBBtn>
                                        </MDBCol>
                                    )}
                                    {this.state.formActivePanel3 === 4 && (
                                        <MDBCol md="12">
                                            <h3 className="font-weight-bold pl-0 my-4">
                                                <strong>ডকুমেন্ট তথ্যাবলী</strong>
                                            </h3>
                                            <MDBInput label="জন্ম নিবন্ধন/জাতীয় পরিচয়পত্র নাম্বার"
                                                name="nationalId"
                                                className="mt-4"
                                                value={nationalId}
                                                onChange={this.changeHandler}
                                                autoFocus={this.calculateAutofocus(4)}
                                            /> 
                                            <MDBInput label="পাসপোর্ট নাম্বার"
                                                name="passportNo"
                                                className="mt-4"
                                                value={passportNo}
                                                onChange={this.changeHandler}
                                            />
                                            {
                                                <div>
                                                    <div className="dropzoneImagePreview" style={isSrcUrl}>
                                                        <div className="thumbsContainer">
                                                            <div className="thumb">
                                                                <div className="thumbInner">
                                                                    <img
                                                                        src={srcUrl}
                                                                        className="img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Dropzone onDrop={this.imagePreviewHandler} multiple={false} accept="image/*">
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzoneContainer">
                                                                <div
                                                                    {...getRootProps({
                                                                        className: 'dropzone',
                                                                        //onDrop: event => event.stopPropagation()
                                                                    })}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <p>ছবি আপলোড করুন(*)</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            }
                                            {
                                                //<MDBFileInput
                                                //    label="ছবি আপলোড করুন(*)"
                                                //    name="imgFile"
                                                //    className="mt-4"
                                                //    getValue={this.profileUploadHandler}
                                                //    labelClass={reglabelClass}
                                                ///>
                                            }
                                            {
                                            //<MDBFileInput 
                                            //    label="ডকুমেন্ট আপলোড করুন(*)"
                                            //    name="docFile"
                                            //    className="mt-4"
                                            //    getValue={this.docUploadHandler}
                                            //    labelClass={reglabelClass}
                                            ///>
                                            
                                                
                                                //<MDBBtn color="success" rounded className="float-right" onClick={this.uploadHandler}>
                                                //    submit
                                                //</MDBBtn>
                                                
                                            }
                                            {
                                                <MDBCol md="12" style={{marginTop:20,marginBottom:50}}>
                                                    <h3 className="font-weight-bold pl-0 my-4">
                                                        <strong>শর্তাবলী</strong>
                                                    </h3>
                                                    <MDBInput label="ফর্মে সকল তথ্য সঠিক প্রদান করা হয়েছে" type="checkbox" id="checkbox3" autoFocus={this.calculateAutofocus(5)} />
                                                    <MDBInput label="নারায়ণগঞ্জ জেলা প্রবাসী ও প্রবাস ফেরত কল্যাণ পরিষদ সকল শর্ত ও নিয়ম কানুন মানিয়া চলিব" type="checkbox" id="checkbox4" />
                                                </MDBCol>
                                            }
                                            <div className={preLoading}>
                                                <ReactLoading type="bubbles" color="green" height={'20%'} width={'20%'} className="regLoader" />
                                            </div>
                                            <div className={postLoading}>
                                                <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(3)}>
                                                    previous
                                                </MDBBtn>
                                                <MDBBtn color="success" rounded className="float-right" onClick={this.handleSubmission}>
                                                    submit
                                                </MDBBtn>
                                            </div>

                                        </MDBCol>
                                    )}
                                    {//this.state.formActivePanel3 === 5 && (
                                        //<MDBCol md="12">
                                        //    <h3 className="font-weight-bold pl-0 my-4">
                                        //        <strong>শর্তাবলী</strong>
                                        //    </h3>
                                        //    <MDBInput label="ফর্মে সকল তথ্য সঠিক প্রদান করা হয়েছে" type="checkbox" id="checkbox3" autoFocus={this.calculateAutofocus(5)} />
                                        //    <MDBInput label="নারায়ণগঞ্জ জেলা প্রবাসী ও প্রবাস ফেরত কল্যাণ পরিষদ সকল শর্ত ও নিয়ম কানুন মানিয়া চলিব" type="checkbox" id="checkbox4" />
                                        //    <div className={preLoading}>
                                        //        <ReactLoading type="bubbles" color="green" height={'20%'} width={'20%'} className="regLoader" />
                                        //    </div>
                                        //    <div className={postLoading}>
                                        //        <MDBBtn color="mdb-color" rounded className="float-left" onClick={this.handleNextPrevClick(3)(4)}>
                                        //            previous
                                        //        </MDBBtn>                                            
                                        //        <MDBBtn color="success" rounded className="float-right" onClick={this.handleSubmission}>
                                        //            submit
                                        //        </MDBBtn>
                                        //    </div>
                                        //</MDBCol>
                                        //)
                                    }
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={1000}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    reg: state.register,
    setting: state.setting
})
export default connect(mapStateToProps, {
    registerMember
    , uploadProfile
    , loadCountries
    , loadThanas
    , loadOccupations
    , loadUpazilas
    , loadMunicipalities
    , loadUnionsByUpazila
})(Registration);