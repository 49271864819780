import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPendingMember, updatePendingMember } from '../../../store/actions/memberAction';
import {
    MDBTabPane, MDBTabContent, MDBNav, MDBNavItem,
    MDBNavLink, MDBIcon, MDBRow, MDBCol, MDBInput, MDBDatePicker,
    MDBBtn, MDBFileInput, toast, ToastContainer,
} from "../../../mdbreact";
import { Form } from 'react-bootstrap';
import {
    loadThanas
    , loadCommittees
    , loadOccupations
    , loadCountries
    , loadUpazilas, loadMunicipalities
    , loadUnionsByUpazila
    , loadDesignations
} from '../../../store/actions/settingAction';
const sinc = [];
const init = {
    memberId : '',
    thanaId: "",
    memberNameBd: "",
    memberNameEn: "",
    fatherName: "",
    motherName: "",
    birthPlace: "নারায়ণগঞ্জ",
    education: "",
    dob: "",
    genderId: "",
    religiousId: "",
    spouse: "",
    hasSpouse: 'd-none',
    occupationId: "",
    bloodGroup: "",
    maritalStatusId: "",
    immigrationStatusId: "",
    immigrationCountryId: "",
    isImmigrate: 'd-none',
    immigrationStart: "",
    immigrationEnd: "",
    mobileNo: "",
    mobileNoOp1: "",
    mobileNoOp2: "",
    hasMultipleContact: 'd-none',
    email: "",
    fbLink: "",
    relContactNo: "",
    relContactNoOp1: "",
    relContactNoOp2: "",
    hasrelMultiContact: 'd-none',
    roadNo: "",
    //upazila: [],
    upazilaId: "",
    unionId: "",
    isUnionHide: 'd-none',
    municipalityId: "",
    isMunicipalityHide: 'd-none',
    wordNo: "",
    nationalID: "",
    passportNo: "",
    isWordNoHide: 'd-none',
    activeItemClassicTabs3: "1",    
    gender: [
        {
            text: "পুরুষ",
            value: "1"
        },
        {
            text: "মহিলা",
            value: "2"
        },
        {
            text: "অন্যান্য",
            value: "3"
        }
    ],
    bloodGroups: [
        {
            text: "A+",
            value: "A+"
        },
        {
            text: "A-",
            value: "A-"
        },
        {
            text: "B+",
            value: "B+"
        },
        {
            text: "B-",
            value: "B-"
        },
        {
            text: "AB+",
            value: "AB+"
        },
        {
            text: "AB-",
            value: "AB-"
        },
        {
            text: "O+",
            value: "O+"
        },
        {
            text: "O-",
            value: "O-"
        }
    ],
    religious: [
        {
            text: "ইসলাম",
            value: "1"
        },
        {
            text: "হিন্দু",
            value: "2"
        },
        {
            text: "বৌদ্ধ",
            value: "3"
        },
        {
            text: "খ্রিস্টান",
            value: "4"
        },
        {
            text: "অন্যান্য",
            value: "5"
        }
    ],
    maritalStatus: [
        {
            text: "বিবাহিত",
            value: "1"
        },
        {
            text: "অবিবাহিত",
            value: "2"
        },
    ],
    immigrationStatus: [
        {
            text: "প্রবাসী",
            value: "1"
        },
        {
            text: "প্রবাস ফেরত",
            value: "2"
        },
    ],
    donarStatus: [
        {
            text: "Yes",
            value: "1"
        },
        {
            text: "No",
            value: "0"
        }
    ],
    memberStatus: [
        //{
        //    text: "Pending",
        //    value: "Pending"
        //},
        {
            text: "Approved",
            value: "Approved"
        },
        {
            text: "Cancel",
            value: "Cancel"
        }
    ],
    approvedStatus : '',
    since: [],
    cityUnions: [
        {
            text: "সিটি কর্পোরেশন",
            value: "1"
        },
        {
            text: "ইউনিয়ন",
            value: "2"
        },
        {
            text: "পৌরসভা",
            value: "3"
        }
    ],
    message: "",
    isSuccess: false,
    dobConvert: '',
    designations: [],
    isInit: true,
    userProfileName: null,
    userFileName: null,
    srcUrl: '',
    imageFile: null
}

class UpdatePendingMember extends Component {

    constructor(props) {
        super(props);
        this.state = init;
        let memberId = props.match.params.id;
        let userId = props.auth.user.userId;
        props.getPendingMember(memberId, userId);
    }

    toggleClassicTabs3 = tab => () => {
        if (this.state.activeItemClassicTabs3 !== tab) {
            this.setState({
                activeItemClassicTabs3: tab
            });
        }
    }

    componentDidMount() {

        this.incSinc();
        this.props.loadThanas();
        this.props.loadOccupations();
        this.props.loadCountries();
        this.props.loadUpazilas();
        this.props.loadMunicipalities();
        this.props.loadDesignations();
        
        this.setState({
            ...this.state,
            since: sinc
        })

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log(nextProps);
        if (this.state.isInit) {
            if (nextProps.member) {
                if (nextProps.member.members) {
                    let memberObj = nextProps.member.members[0];
                    if (memberObj.statusCode === 404) {
                        toast.error('Member is not found.', {
                            closeButton: true,
                            onClose: () => nextProps.history.push('/admin')
                        });
                    }
                    else {
                        let hasSpouse = 'd-none';
                        if (memberObj.maritalStatusId) {
                            hasSpouse = 'd-block';
                        }
                        let isImmigrate = 'd-none';
                        if (memberObj.immigrationStatusId == 2) {
                            isImmigrate = 'd-block';
                        }

                        let cityUnionPoroId = '';
                        let municipalityHide = 'd-none';
                        let unionHide = 'd-none';

                        //if (memberObj.unionId) {
                        //    municipalityHide = 'd-none';
                        //    unionHide = 'd-block';
                        //    cityUnionPoroId = '2';
                        //}
                        //else if (memberObj.municipalityId) {
                        //    municipalityHide = 'd-block';
                        //    unionHide = 'd-none';
                        //    cityUnionPoroId = '3';
                        //}
                        //else
                        //    cityUnionPoroId = '1';
                        //console.log(memberObj);  

                        if (memberObj.unionId != 0) {
                            municipalityHide = 'd-none';
                            unionHide = 'd-block';
                            cityUnionPoroId = '2';
                            nextProps.loadUnionsByUpazila(memberObj.upazilaId);
                        }
                        else if (memberObj.municipalityId != 0) {
                            municipalityHide = 'd-block';
                            unionHide = 'd-none';
                            cityUnionPoroId = '3';
                        }
                        else
                            cityUnionPoroId = '1';

                        this.setState({
                            memberId: memberObj.memberId,
                            memberCode: memberObj.memberCode,
                            memberNameBd: memberObj.memberNameBd,
                            memberNameEn: memberObj.memberNameEn,
                            fatherName: memberObj.fatherName,
                            motherName: memberObj.motherName,
                            dob: memberObj.dobConvert,
                            bloodGroup: memberObj.bloodGroup,
                            birthPlace: memberObj.birthPlace,
                            education: memberObj.education,
                            genderId: memberObj.genderId,
                            occupationId: memberObj.occupationId,
                            religiousId: memberObj.religiousId,
                            maritalStatusId: memberObj.maritalStatusId,
                            spouse: memberObj.spouse,
                            immigrationStatusId: memberObj.immigrationStatusId,
                            immigrationCountryId: memberObj.immigrationCountryId,
                            immigrationStart: memberObj.immigrationStart,
                            immigrationEnd: memberObj.immigrationEnd,
                            mobileNo: memberObj.mobileNo,
                            mobileNoOp1: memberObj.mobileNoOp1,
                            mobileNoOp2: memberObj.mobileNoOp2,
                            fbLink: memberObj.fbLink,
                            email: memberObj.email,
                            relContactNo: memberObj.relContactNo,
                            relContactNoOp1: memberObj.relContactNoOp1,
                            relContactNoOp2: memberObj.relContactNoOp2,
                            roadNo: memberObj.roadNo,
                            upazilaId: memberObj.upazilaId,
                            unionId: memberObj.unionId,
                            municipalityId: memberObj.municipalityId,
                            thanaId: memberObj.thanaId,
                            wordNo: memberObj.wordNo,
                            passportNo: memberObj.passportNo,
                            nationalID: memberObj.nationalId,
                            approvedStatus: memberObj.approvedStatus,
                            isMunicipalityHide: municipalityHide,
                            isUnionHide: unionHide,
                            cityUnionPoroId: cityUnionPoroId,
                            isWordNoHide: 'd-block',
                            isImmigrate: isImmigrate,
                            hasSpouse: hasSpouse,
                            isInit: false,
                            userProfileName: memberObj.userProfileName,
                            userFileName: memberObj.userFileName
                        });
                    }
                }
            }
        }
        else if (nextProps.member.isSuccess) {
            nextProps.member.isSuccess = null;
            toast.success('Member profile updated successfully.', {
                closeButton: true,
                onClose: () => {
                    let committee = this.props.match.params.cid;
                    window.location.href = '/admin/pendingmember/' + committee;
                }
            });
        }
        else if (nextProps.member.isSuccess == false) {
            nextProps.member.isSuccess = null;
            toast.error('Member profile updated failed.', {
                closeButton: true
            });
        }

    }
    /*==================================
     * INIT LOADER
     *=================================*/
    incSinc() {

        let curYear = new Date().getFullYear();
        for (var i = curYear; i >= 1960; i--) {
            sinc.push({ text: i, value: i });
        }
    }
    
    /*==================================
     * Change Handler
     *=================================*/

    changeHandler = event => {
        //const value = event.target.innerHTML;
        //console.log(event);
        //console.log(event.target.name + '//' + event.target.value);
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    formatDate = date => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    getPickerValue = (value) => {
        var date = this.formatDate(value);
        this.setState({
            ...this.state,
            dob: date
        });
    }
    
    maritalStatusChangeHandler = event => {        
        let hasSpouse = 'd-none';
        if (event.target.value === "1") {
            hasSpouse = 'd-block';
        }
        this.setState({
            ...this.state,
            hasSpouse: hasSpouse,
            [event.target.name]: event.target.value
        });
    }

    //multipleContactHandler = () => {
    //    let hide = '';
    //    if (this.state.hasMultipleContact == 'd-none')
    //        hide = 'd-block';
    //    else
    //        hide = 'd-none';

    //    this.setState({
    //        ...this.state,
    //        hasMultipleContact: hide
    //    });
    //}

    //relmultiContactHandler = () => {
    //    let hide = '';
    //    if (this.state.hasrelMultiContact == 'd-none')
    //        hide = 'd-block';
    //    else
    //        hide = 'd-none';

    //    this.setState({
    //        ...this.state,
    //        hasrelMultiContact: hide
    //    });
    //}

    immigrationStatusHandler = (event) => {
        let hide = 'd-none';
        if (event.target.value === "2") {
            hide = 'd-block';
        }

        this.setState({
            ...this.state,
            [event.target.name]: parseInt(event.target.value),
            isImmigrate: hide
        });
    }

    //immigrationCountryHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationCountryId: parseInt(event)
    //    });
    //}

    //immigrationStartHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationStart: parseInt(event)
    //    });
    //}

    //immigrationEndHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        immigrationEnd: parseInt(event)
    //    });
    //}

    upazilaChangeHandler = (event) => {

        let upazilaId = event.target.value;
        if (this.state.isUnionHide == 'd-block')
            this.props.loadUnionsByUpazila(upazilaId)
        this.setState({
            ...this.state,
            upazilaId: upazilaId
        });
    }

    cityUnionsChangeHandler = (event) => {
        let municipalityHide = 'd-none';
        let unionHide = 'd-none';
        let wordNoHide = 'd-none';
        let eventId = event.target.value;
        //let unionId = 0;
        //let municipalityId = 0;
        //console.log(eventId);
        if (eventId === '1') {
            wordNoHide = 'd-block';
        }
        else if (eventId === '2') {
            unionHide = 'd-block';
            wordNoHide = 'd-block';
            if (this.state.upazilaId)
                this.props.loadUnionsByUpazila(this.state.upazilaId);
        }
        else if (eventId === '3') {
            municipalityHide = 'd-block';
            wordNoHide = 'd-block';
        }
        this.setState({
            ...this.state,
            [event.target.name]: parseInt(event.target.value),
            isMunicipalityHide: municipalityHide,
            isUnionHide: unionHide,
            isWordNoHide: wordNoHide,
            unionId: 0,
            municipalityId: 0
        });
    }

    //municipalityHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        municipalityId: parseInt(event)
    //    });
    //}

    //unionsChangeHandler = (event) => {

    //    this.setState({
    //        ...this.state,
    //        unionId: parseInt(event)
    //    });
    //}

    //static getDerivedStateFromProps(nextprops, prevstate) {
    //    if (prevstate.isInit) {
    //        if (nextprops.member) {
    //            if (nextprops.member.members) {
    //                let memberObj = nextprops.member.members[0];
    //                if (memberObj.statusCode === 404) {
    //                    toast.error('Member is not found.', {
    //                        closeButton: true,
    //                        onClose: () => nextprops.history.push('/admin')
    //                    });
    //                }
    //                else {
    //                    let hasSpouse = 'd-none';
    //                    if (memberObj.maritalStatusId) {
    //                        hasSpouse = 'd-block';
    //                    }
    //                    let isImmigrate = 'd-none';
    //                    if (memberObj.immigrationStatusId == 2) {
    //                        isImmigrate = 'd-block';
    //                    }

    //                    let cityUnionPoroId = '';
    //                    let municipalityHide = 'd-none';
    //                    let unionHide = 'd-none';

    //                    if (memberObj.unionId) {
    //                        municipalityHide = 'd-none';
    //                        unionHide = 'd-block';
    //                        cityUnionPoroId = '2';
    //                    }
    //                    else if (memberObj.municipalityId) {
    //                        municipalityHide = 'd-block';
    //                        unionHide = 'd-none';
    //                        cityUnionPoroId = '3';
    //                    }
    //                    else
    //                        cityUnionPoroId = '1';
    //                    //console.log(memberObj);                
    //                    return {
    //                        memberId: memberObj.memberId,
    //                        memberCode: memberObj.memberCode,
    //                        memberNameBd: memberObj.memberNameBd,
    //                        memberNameEn: memberObj.memberNameEn,
    //                        fatherName: memberObj.fatherName,
    //                        motherName: memberObj.motherName,
    //                        dob: memberObj.dobConvert,
    //                        bloodGroup: memberObj.bloodGroup,
    //                        birthPlace: memberObj.birthPlace,
    //                        education: memberObj.education,
    //                        genderId: memberObj.genderId,
    //                        occupationId: memberObj.occupationId,
    //                        religiousId: memberObj.religiousId,
    //                        maritalStatusId: memberObj.maritalStatusId,
    //                        spouse: memberObj.spouse,
    //                        immigrationStatusId: memberObj.immigrationStatusId,
    //                        immigrationCountryId: memberObj.immigrationCountryId,
    //                        immigrationStart: memberObj.immigrationStart,
    //                        immigrationEnd: memberObj.immigrationEnd,
    //                        mobileNo: memberObj.mobileNo,
    //                        mobileNoOp1: memberObj.mobileNoOp1,
    //                        mobileNoOp2: memberObj.mobileNoOp2,
    //                        fbLink: memberObj.fbLink,
    //                        email: memberObj.email,
    //                        relContactNo: memberObj.relContactNo,
    //                        relContactNoOp1: memberObj.relContactNoOp1,
    //                        relContactNoOp2: memberObj.relContactNoOp2,
    //                        roadNo: memberObj.roadNo,
    //                        upazilaId: memberObj.upazilaId,
    //                        unionId: memberObj.unionId,
    //                        municipalityId: memberObj.municipalityId,
    //                        thanaId: memberObj.thanaId,
    //                        wordNo: memberObj.wordNo,
    //                        passportNo: memberObj.passportNo,
    //                        nationalID: memberObj.nationalId,
    //                        approvedStatus: memberObj.approvedStatus,
    //                        isMunicipalityHide: municipalityHide,
    //                        isUnionHide: unionHide,
    //                        cityUnionPoroId: cityUnionPoroId,
    //                        isWordNoHide: 'd-block',
    //                        isImmigrate: isImmigrate,
    //                        hasSpouse: hasSpouse,
    //                        isInit: false,
    //                        userProfileName: memberObj.userProfileName,
    //                        userFileName: memberObj.userFileName
    //                    };
    //                }
    //            }
    //        }
    //    }
    //    else if (nextprops.member.isSuccess) {
    //        nextprops.member.isSuccess = null;
    //        toast.success('Member profile updated successfully.', {
    //            closeButton: true
    //        });
    //    }
    //    else if (nextprops.member.isSuccess == false) {
    //        nextprops.member.isSuccess = null;
    //        toast.error('Member profile updated failed.', {
    //            closeButton: true
    //        });
    //    }
    //    return null;
    //}

    changeDesignationHandler = name => event => {
        //console.log(name + '//' + event.target.value);
        this.setState({
            [name]: event.target.value,
        });
    };

    generateDesignations = () => {
        //console.log(this.props.setting.designations);
        let designation = this.props.member.members.map(mem => {
            return (
                <MDBRow key={mem.committeeId}>
                    <MDBCol size="12" sm="12" lg="12">
                        <Form.Group>
                            <Form.Label>{mem.committeeName } পদবি</Form.Label>
                            <Form.Control as="select" custom
                                onChange={this.changeDesignationHandler(mem.slug)}>
                                <option value='select'>নির্বাচন করুন</option>
                                {
                                    this.props.setting.designations &&
                                    this.props.setting.designations.map((c, index) => {
                                        let totalItems = this.props.setting.designations.length;
                                        let selected = index == (totalItems - 1);
                                        return (<option key={index} value={c.designationId} selected={selected} >{c.designation}</option>)
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </MDBCol>
                </MDBRow>
            )
        })
        return designation;
    }

    getDesignations = () => {
        let designations = [];
        let desigId = this.props.setting.designations[this.props.setting.designations.length - 1].designationId;
        let memberId = this.state.memberId;
        this.props.member.members.map(d => {
            let id = d.committeeId;
            let desig = parseInt(this.state[d.slug] ? this.state[d.slug] : desigId);
            designations.push({ memberId: memberId,committeeId: id, designationId: desig });
        })
        return designations;
    }

    handleSubmission = () => {
        //Load Designation
        let designations = this.getDesignations();
        //console.log(designations);
        //Get Update Profile Value 
        let {
            thanaId, genderId, religiousId, occupationId, bloodGroup, maritalStatusId,
            dob, upazilaId, municipalityId, immigrationStatusId,
            immigrationStart, immigrationEnd, memberId, donarStatusId, memberNameBd,
            memberNameEn, fatherName, motherName, birthPlace, education, immigrationCountryId,
            spouse, mobileNo, mobileNoOp1, mobileNoOp2, fbLink, relContactNo, relContactNoOp1, relContactNoOp2,
            roadNo, wordNo, nationalID, passportNo, email, unionId, approvedStatus, userProfileName,
        } = this.state;
        let member = {
            memberId: memberId,
            thanaId: parseInt(thanaId),
            genderId: parseInt(genderId),
            religiousId: parseInt(religiousId),
            occupationId: parseInt(occupationId),
            bloodGroup: bloodGroup,
            maritalStatusId: parseInt(maritalStatusId),
            dobConvert: dob,
            upazilaId: parseInt(upazilaId),
            unionId: parseInt(unionId),
            municipalityId: parseInt(municipalityId),
            immigrationStatusId: parseInt(immigrationStatusId),
            immigrationStart: parseInt(immigrationStart),
            immigrationEnd: parseInt(immigrationEnd),
            memberNameBd: memberNameBd,
            memberNameEn: memberNameEn,
            fatherName: fatherName,
            motherName: motherName,
            birthPlace: birthPlace,
            education: education,
            immigrationCountryId: parseInt(immigrationCountryId),
            spouse: spouse,
            mobileNo: mobileNo,
            mobileNoOp1: mobileNoOp1,
            mobileNoOp2: mobileNoOp2,
            fbLink: fbLink,
            relContactNo: relContactNo,
            relContactNoOp1: relContactNoOp1,
            relContactNoOp2: relContactNoOp2,
            roadNo: roadNo,
            wordNo: wordNo,
            nationalID: nationalID,
            passportNo: passportNo,
            email: email,
            donarStatus: donarStatusId == "1" ? true : null,
            approvedStatus: approvedStatus,
            approvedBy: this.props.auth.user.userId,
            assignDesignations: designations,
            userProfileName: userProfileName
        }

        let formData = new FormData();
        formData.append('member', JSON.stringify(member));
        formData.append('imageFile', this.state.imageFile);
        this.props.updatePendingMember(formData);

        //console.log(member);
        //this.props.updatePendingMember(member);
    }

    imageUploadHandler = (event) => {
        //console.log(event[0]);
        let currentFile = event[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            //console.log(reader.result);
            this.setState({
                ...this.state,
                imageFile: currentFile,
                srcUrl: reader.result
            })
        }, false);
        reader.readAsDataURL(currentFile);
    }

    render() {
        //console.log(this.state.thanaId);
        let {
            //thana,
            thanaId, memberCode, gender, genderId, religious, religiousId,
            //occupations,
            occupationId, bloodGroups, bloodGroup, maritalStatus, maritalStatusId, dob,
            immigrationStatus, immigrationStatusId, countries, since, upazila, upazilaId, municipality, municipalityId, unions,
            cityUnions, hasSpouse, hasMultipleContact, hasrelMultiContact, isImmigrate, immigrationStart, immigrationEnd,
            isMunicipalityHide, isUnionHide, isWordNoHide, memberId, donarStatus, donarStatusId, memberStatus,
            memberNameBd, memberNameEn, fatherName, motherName, birthPlace, education, immigrationCountryId,
            spouse, mobileNo, mobileNoOp1, mobileNoOp2, fbLink, relContactNo, relContactNoOp1, relContactNoOp2,
            roadNo, wordNo, nationalID, passportNo, email,
            cityUnionPoroId, unionId, approvedStatus, userProfileName, srcUrl
        } = this.state;

        return (
            <div id="adminMemberReg">
                <div className="classic-tabs">
                    <MDBNav classicTabs color="green" className="mt-5">
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "1"} onClick={this.toggleClassicTabs3("1")}>
                                <MDBIcon icon="chalkboard-teacher" size="2x" />
                                <br />
                                Basic Info
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "2"} onClick={this.toggleClassicTabs3("2")}>
                                <MDBIcon icon="user" size="2x" />
                                <br />
                                Family Info
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "3"} onClick={this.toggleClassicTabs3("3")}>
                                <MDBIcon fas icon="plane-departure" size="2x" />
                                <br />
                                Immigration Info
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItemClassicTabs3 === "4"} onClick={this.toggleClassicTabs3("4")}>
                                <MDBIcon icon="envelope" size="2x" />
                                <br />
                                Contact Info
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent className="card mb-5" activeItem={this.state.activeItemClassicTabs3}>
                        <MDBTabPane tabId="1">
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            { userProfileName && 
                                                <div className="circular">
                                                <img src={srcUrl != '' ? srcUrl : window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + userProfileName} />
                                                </div>                                           
                                            }
                                            <MDBFileInput
                                                label="ছবি আপলোড করুন(*)"
                                                name="imgFile"
                                                className="mt-4"
                                                getValue={this.imageUploadHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="Member ID"
                                                name="memberCode"
                                                className="mt-4"
                                                value={memberCode}
                                                disabled
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="নাম ( বাংলায় )"
                                                name="memberNameBd"
                                                className="mt-4"
                                                value={memberNameBd}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="Name ( In English )"
                                                name="memberNameEn"
                                                className="mt-4"
                                                value={memberNameEn}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>                                        
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>থানা</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="thanaId"
                                                    id="thanaId"
                                                    value={thanaId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.thanas
                                                        && this.props.setting.thanas.map((c, index) => {
                                                            return (<option key={index} value={c.thanaId}>{c.thana}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="জন্ম স্থান"
                                                name="birthPlace"
                                                className="mt-4"
                                                value={birthPlace}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>                                    
                                </MDBCol>
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <div className="dobWrapper">
                                                <label>জন্ম তারিখ</label>
                                                <MDBDatePicker
                                                    name="dob"
                                                    className="mt-4"
                                                    getValue={this.getPickerValue}
                                                    format='MM/DD/YYYY'
                                                    value={dob}
                                                />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="শিক্ষাগত যোগ্যতা"
                                                name="education"
                                                className="mt-4"
                                                value={education}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>লিঙ্গ</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="genderId"
                                                    id="genderId"
                                                    value={genderId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>লিঙ্গ নির্বাচন করুন</option>
                                                    {
                                                        gender && gender.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>ধর্ম</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="religiousId"
                                                    id="religiousId"
                                                    value={religiousId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        religious && religious.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>পেশা</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="occupationId"
                                                    id="occupationId"
                                                    value={occupationId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.occupations
                                                        && this.props.setting.occupations.map((c, index) => {
                                                            return (<option key={index} value={c.occupationId}>{c.occupation}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>রক্তের গ্রুপ</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="bloodGroup"
                                                    id="bloodGroup"
                                                    value={bloodGroup}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        bloodGroups && bloodGroups.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="জন্ম নিবন্ধন/জাতীয় পরিচয়পত্র নাম্বার"
                                                name="nationalID"
                                                className="mt-4"
                                                value={nationalID}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="পাসপোর্ট নাম্বার"
                                                name="passportNo"
                                                className="mt-4"
                                                value={passportNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                {/* Frequently update into */}
                                <MDBCol size="12" sm="12" lg="4">

                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>ডোনার স্টেটাস</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="donarStatusId"
                                                    id="donarStatusId"
                                                    value={donarStatusId}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        donarStatus && donarStatus.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>মেম্বার স্টেটাস</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="approvedStatus"
                                                    id="approvedStatus"
                                                    value={approvedStatus}
                                                    onChange={this.changeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        memberStatus && memberStatus.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>

                                    {this.props.member.members && this.generateDesignations()}
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                                Update Profile
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                            
                        </MDBTabPane>
                        <MDBTabPane tabId="2">
                            { /* Family Info */}
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBInput label="পিতার নাম"
                                        name="fatherName"
                                        className="mt-4"
                                        value={fatherName}
                                        onChange={this.changeHandler}
                                    />                                    
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBInput label="মাতার নাম"
                                        name="motherName"
                                        className="mt-4"
                                        value={motherName}
                                        onChange={this.changeHandler}
                                    />                                   
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <Form.Group>
                                        <Form.Label>বৈবাহিক অবস্থা</Form.Label>
                                        <Form.Control as="select" custom
                                            name="maritalStatusId"
                                            id="maritalStatusId"
                                            value={maritalStatusId}
                                            onChange={this.maritalStatusChangeHandler}>
                                            <option value='select'>নির্বাচন করুন</option>
                                            {
                                                maritalStatus && maritalStatus.map((c, index) => {
                                                    return (<option key={index} value={c.value}>{c.text}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>
                            <div className={hasSpouse}>
                                <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <div className={hasSpouse}>
                                        <MDBInput label="স্ত্রীর নাম"
                                            name="spouse"
                                            className="mt-4"
                                            value={spouse}
                                            onChange={this.changeHandler}
                                        />
                                    </div>                                  
                                </MDBCol>
                                </MDBRow> 
                            </div>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="12">
                                    <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                        Update Profile
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBTabPane>
                        <MDBTabPane tabId="3">
                            { /* immigration */}
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <Form.Group>
                                        <Form.Label>বর্তমান অবস্থান</Form.Label>
                                        <Form.Control as="select" custom
                                            name="immigrationStatusId"
                                            id="immigrationStatusId"
                                            value={immigrationStatusId}
                                            onChange={this.immigrationStatusHandler}>
                                            <option value='select'>বর্তমান অবস্থান নির্বাচন করুন</option>
                                            {
                                                immigrationStatus && immigrationStatus.map((c, index) => {
                                                    return (<option key={index} value={c.value}>{c.text}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <Form.Group>
                                        <Form.Label>অবস্থানকৃত দেশের নাম</Form.Label>
                                        <Form.Control as="select" custom
                                            name="immigrationCountryId"
                                            id="immigrationCountryId"
                                            value={immigrationCountryId}
                                            onChange={this.changeHandler}>
                                            <option value='select'>অবস্থানকৃত দেশের নাম নির্বাচন করুন</option>
                                            {
                                                this.props.setting.countries &&
                                                this.props.setting.countries.map((c, index) => {
                                                    return (<option key={index} value={c.countryId}>{c.countryName}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <div className="clear-fixed">
                                        <strong>প্রবাসে অবস্থানের সময়কাল</strong>
                                    </div>
                                    <Form.Group>
                                        <Form.Label>শুরু</Form.Label>
                                        <Form.Control as="select" custom
                                            name="immigrationStart"
                                            id="immigrationStart"
                                            value={immigrationStart}
                                            onChange={this.changeHandler}>
                                            <option value='select'>শুরু</option>
                                            {
                                                since && since.map((c, index) => {
                                                    return (<option key={index} value={c.value}>{c.text}</option>)
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <div className={isImmigrate}>
                                        <Form.Group>
                                            <Form.Label>শেষ</Form.Label>
                                            <Form.Control as="select" custom
                                                name="immigrationEnd"
                                                id="immigrationEnd"
                                                value={immigrationEnd}
                                                onChange={this.changeHandler}>
                                                <option value='select'>শেষ</option>
                                                {
                                                    since && since.map((c, index) => {
                                                        return (<option key={index} value={c.value}>{c.text}</option>)
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="12">
                                    <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                        Update Profile
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBTabPane>
                        <MDBTabPane tabId="4">

                            <MDBRow>
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput
                                                label="মোবাইল নাম্বার"
                                                name="mobileNo"
                                                onChange={this.changeHandler}
                                                value={mobileNo}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="মোবাইল নাম্বার (অপঃ০১)"
                                                name="mobileNoOp1"
                                                className="mt-4"
                                                value={mobileNoOp1}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="মোবাইল নাম্বার (অপঃ০২)"
                                                name="mobileNoOp2"
                                                className="mt-4"
                                                value={mobileNoOp2}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="ই-মেইল"
                                                name="email"
                                                value={email}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="ফেইসবুক প্রফাইল লিংক"
                                                name="fbLink"
                                                value={fbLink}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput
                                                name="relContactNo"
                                                label="নিকট স্বজনের মোবাইল নাম্বার"
                                                value={relContactNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="মোবাইল নাম্বার (অপঃ০১)"
                                                name="relContactNoOp1"
                                                className="mt-4"
                                                value={relContactNoOp1}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="মোবাইল নাম্বার (অপঃ০২)"
                                                name="relContactNoOp2"
                                                className="mt-4"
                                                value={relContactNoOp2}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                                { /*Address*/ }
                                <MDBCol size="12" sm="12" lg="4">
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBInput label="রাস্তা/গ্রাম/মহল্লা"
                                                name="roadNo"
                                                value={roadNo}
                                                onChange={this.changeHandler}
                                                className="mt-4"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>নারায়ণগঞ্জ সিটি কর্পোরেশন/ইউনিয়ন/পৌরসভা</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="cityUnionPoroId"
                                                    id="cityUnionPoroId"
                                                    value={cityUnionPoroId}
                                                    onChange={this.cityUnionsChangeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        cityUnions && cityUnions.map((c, index) => {
                                                            return (<option key={index} value={c.value}>{c.text}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <Form.Group>
                                                <Form.Label>উপজেলা</Form.Label>
                                                <Form.Control as="select" custom
                                                    name="upazilaId"
                                                    id="upazilaId"
                                                    value={upazilaId}
                                                    onChange={this.upazilaChangeHandler}>
                                                    <option value='select'>নির্বাচন করুন</option>
                                                    {
                                                        this.props.setting.upazilas
                                                        && this.props.setting.upazilas.map((c, index) => {
                                                            return (<option key={index} value={c.upazilaId}>{c.upazilaName}</option>)
                                                        })
                                                    }
                                                </Form.Control>
                                            </Form.Group>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <div className={isMunicipalityHide}>
                                                <Form.Group>
                                                    <Form.Label>পৌরসভা</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="municipalityId"
                                                        id="municipalityId"
                                                        value={municipalityId}
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            this.props.setting.municipalities &&
                                                            this.props.setting.municipalities.map((c, index) => {
                                                                return (<option key={index} value={c.municipalityId}>{c.municipalityName}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <div className={isUnionHide}>
                                                <Form.Group>
                                                    <Form.Label>ইউনিয়ন</Form.Label>
                                                    <Form.Control as="select" custom
                                                        name="unionId"
                                                        id="unionId"
                                                        value={unionId}
                                                        onChange={this.changeHandler}>
                                                        <option value='select'>নির্বাচন করুন</option>
                                                        {
                                                            this.props.setting.unions &&
                                                            this.props.setting.unions.map((c, index) => {
                                                                return (<option key={index} value={c.unionId}>{c.unionName}</option>)
                                                            })
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <div className={isWordNoHide}>
                                                <MDBInput label="ওয়ার্ড নং"
                                                    name="wordNo"
                                                    value={wordNo}
                                                    onChange={this.changeHandler}
                                                    className="mt-4"
                                                />
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol size="12" sm="12" lg="12">
                                            <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                                Update Profile
                                            </MDBBtn>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCol>
                            </MDBRow>
                        </MDBTabPane>
                    </MDBTabContent>
                </div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer,
    setting: state.setting
})
export default connect(mapStateToProps, {
    getPendingMember
    , loadCommittees
    , updatePendingMember
    , loadCountries
    , loadThanas
    , loadOccupations
    , loadCountries
    , loadUpazilas
    , loadMunicipalities
    , loadUnionsByUpazila
    , loadDesignations
})(UpdatePendingMember);