import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPendingMemberList } from '../../../store/actions/memberAction';
import PendingList from './PendingList';

class MemberPendingList extends Component {

    state = {
        committee: '',
        userId:0,
        data:[],
        searchTerm:'',
        isSearch: false,
        isLoading:true
    }

    constructor(props) {
        super(props);
        this.fbHandler = this.fbHandler.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.deleteHandler = this.deleteHandler.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            committee: this.props.match.params.cid,
            userId: this.props.auth.user.userId
        }, () => {
            this.props.getPendingMemberList(this.state.committee, this.state.userId)
        });
        //console.log(this.state);
        //this.setState({
        //    ...this.state,
        //    committee: this.props.match.params.cid,
        //    userId: this.props.auth.user.userId
        //}, () => {
        //    this.props.getPendingMemberList(committee, userId);
        //});        
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(this.props.member.members) !== JSON.stringify(prevProps.member.members) && this.state.isSearch === false) {
            this.setState({
                ...this.state,
                isLoading: false,
                data:this.props.member.members
            })
        }
    }   

    // changeHandler = (event) => {
    //     const data = this.props.member.members;
    //     let term = event.target.value.toLowerCase();
    //     let filterResult = data.filter(c => (c.immigrationCountry && c.immigrationCountry.toLowerCase().includes(term))
    //         || (c.occupation && (c.occupation.toLowerCase().includes(term)))
    //         || ( c.memberCode && c.memberCode.toLowerCase().includes(term))
    //         || ( c.memberNameBd && c.memberNameBd.toLowerCase().includes(term))
    //         || ( c.memberNameEn && c.memberNameEn.toLowerCase().includes(term))
    //         || ( c.mobileNo && c.mobileNo.toLowerCase().includes(term))
    //         || ( c.thana && c.thana.toLowerCase().includes(term))
    //         );
    //     //console.log(filterResult);
    //     this.setState({
    //         ...this.state,
    //         [event.target.name]: event.target.value,
    //         data: filterResult,
    //         isSearch: true
    //     });
    // }

    searchHandler = (term) => {
        const data = this.props.member.members;
        let result = data.filter(c => (c.immigrationCountry && c.immigrationCountry.toLowerCase().includes(term))
            || (c.occupation && (c.occupation.toLowerCase().includes(term)))
            || (c.memberCode && c.memberCode.toLowerCase().includes(term))
            || (c.memberNameBd && c.memberNameBd.toLowerCase().includes(term))
            || (c.memberNameEn && c.memberNameEn.toLowerCase().includes(term))
            || (c.mobileNo && c.mobileNo.toLowerCase().includes(term))
            || (c.thana && c.thana.toLowerCase().includes(term))
        );
        return result;
    }

    changeHandler = (event) => {      
        let term = event.target.value.toLowerCase();
        let result = this.searchHandler(term);
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            data: result,
            isSearch: true
        });
    }    

    clickHandler = (term) => {
        let result = this.searchHandler(term);
        // console.log(result);
        this.setState({
            ...this.state,            
            data: result,
            isSearch: true
        });        
    }

    fbHandler = (url) => {
        url = 'https://www.facebook.com/' + url;
        window.open(url, '_blank');
    }

    editHandler = (id) => {
        window.location.href = '/admin/updatependingmember/' + id + '/' + this.state.committee;
        //this.props.history.push('/admin/updatependingmember/' + id);
    }

    deleteHandler = (rowData) => {
        console.log(rowData);
    }

    render() {
        const {data,searchTerm} = this.state;
        return (
            <PendingList
                isLoading={this.isLoading}
                members={data}
                searchTerm={searchTerm}
                changeHandler={this.changeHandler}
                clickHandler={this.clickHandler}
                fbHandler={this.fbHandler}
                editHandler={this.editHandler}
                deleteHandler={this.deleteHandler}
            />
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer
})

export default connect(mapStateToProps, { getPendingMemberList })(MemberPendingList);