import * as Types from './types';
import Axios from 'axios';

export const getPendingMemberList = (committee, userId) => dispatch => {
    Axios.get('api/Member/GetPendingMembersSummary/' + committee + '/' + userId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_PENDING_MEMBERS,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const getPendingMember = (memberId, userId) => dispatch => {
    Axios.get('api/Member/GetPendingMemberByMemberId/' + memberId +'/'+ userId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_PENDING_MEMBER,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const updatePendingMember = (member) => dispatch => {
    //console.log(member);
    //return;
    Axios.post('api/Member/UpdatePendingMember', member)
        .then(response => {
            dispatch({
                type: Types.UPDATE_PENDING_MEMBER,
                payload: {
                    statusCode: response.data.statusCode,
                    isSuccess: response.data.isSuccess
                }
            });
        })
        .catch(error => {
            console.log(error);
        })
}

export const getApprovedMemberList = (committee, userId) => dispatch => {
    Axios.get('api/Member/GetApprovedMembersSummary/' + committee + '/' + userId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_APPROVED_MEMBERS,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const getApprovedMember = (memberId, userId) => dispatch => {
    Axios.get('api/Member/GetApprovedMemberByMemberId/' + memberId + '/' + userId)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_APPROVED_MEMBER,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const updateApprovedMember = (member) => dispatch => {
    //console.log(member);
    //return;
    Axios.post('api/Member/UpdateApprovedMember', member)
        .then(response => {
            dispatch({
                type: Types.UPDATE_APPROVED_MEMBER,
                payload: {
                    statusCode: response.data.statusCode,
                    isSuccess: response.data.isSuccess,
                }
            });
        })
        .catch(error => {
            console.log(error);
        })
}

export const getDonarMembers = () => dispatch => {
    Axios.get('api/Member/GetDonarMembers')
        .then(response => {
            dispatch({
                type: Types.LOAD_DONAR_MEMBERS,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const getMembersBasicInfo = () => dispatch => {
    Axios.get('api/Member/GetMembersBasicInfo')
        .then(response => {
            dispatch({
                type: Types.LOAD_MEMBERS_BASIC_INFO,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const getMemberDesignations = (memberId) => dispatch => {
    Axios.get('api/Member/GetMemberDesignations/' + memberId)
        .then(response => {
            dispatch({
                type: Types.LOAD_MEMBER_DESIGNATIONS,
                payload: {
                    designations: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const getApprovedMembersFrontend = (committee) => dispatch => {
    Axios.get('api/Member/GetApprovedMembersFrontend/' + committee)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.LOAD_APPROVED_MEMBERS,
                payload: {
                    members: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const storeMemberforGreetingCard = (member) => dispatch => {
    dispatch({
        type: Types.STORE_MEMBER_GREETING_CARD,
        payload: {
            member: member
        }
    });
}
