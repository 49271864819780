import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDonarMembers } from '../../../store/actions/memberAction';
import { loadPaymentMode, loadServiceHolder, insertDonation } from '../../../store/actions/transactionAction';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon,
    MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput,
    MDBRow, MDBCol, MDBDatePicker, MDBSelect, MDBSelectOption, MDBSelectOptions,
    MDBSelectInput, toast, ToastContainer
} from "../../../mdbreact";

const init = {
    first: 0,
    rows: 10,
    totalRecords: 0,
    committee: '',
    iconSizeClass: {
        fontSize: '1.2rem'
    },
    modal: false,
    donationDateConvert: null,
    memberId: 0,
    paymentModeId: 0,
    serviceHolderId: 0,
    accountNo: '',
    isAccount:false,
    mobileNo: '',
    isMobile:false,
    chequeNo: '',
    isCheque:false,
    cardNo: '',
    isCard:false,
    transactionNo: '',
    amount: '',
    payTo: '',
    remarks: '',
    isStateChange: false,
    submitText: 'Submit'
}

class DonarMemberList extends Component {

    constructor(props) {
        super(props);
        this.state = init;       
    }

    componentDidMount() {
        this.props.getDonarMembers();
        this.props.loadPaymentMode();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.tran.statusCode == 200 && this.state.isStateChange == true) {
            toast.success(nextProps.tran.message, {
                closeButton: true
            });
            this.setState({
                ...init
            });
        }
    }

    toggle = nr => () => {
        //let modalNumber = 'modal' + nr
        this.setState({
            modal: false
        });
    }

    //UNSAFE_componentWillUpdate(nextProps, nextState) {

    //    let committee = nextProps.match.params.cid;
    //    if (this.state.committee !== committee) {
    //        this.state.committee = committee;
    //        //let userId = nextProps.auth.user.userId;
    //        this.props.getDonarMembers();
    //    }
    //}
    /*=======================
     * PROFILE PIC TEMPLATE
     * =====================*/
    profileTemplate(rowData) {
        let imgData = 'np_default_avatar.jpg';
        if (rowData.userProfileName)
            imgData = rowData.userProfileName;
        const src = window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + imgData;
        return (
            <>
                <img alt={"test purpose"} src={src} width="32" style={{ verticalAlign: 'middle', borderRadius: '50%', width: '50px', height: '50px', border: '3px solid rgba(233,230,230,1)' }} />
                <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.memberNameBd}</span>
            </>
        );
    }

    /*=======================
     * ACTION TEMPLATE
     * =====================*/
    actionTemplate = (rowData, column) => {
        return <div id='admin_pending_btn'>
            <MDBBtn color="dark-green" size="sm" onClick={() => this.modalHandler(rowData.memberId)}>
                <MDBIcon far icon="money-bill-alt" style={this.state.iconSizeClass} />
            </MDBBtn>            

            {
                //<MDBBtn tag="a" size="sm" floating
                //    gradient="blue"
                //    onClick={() => this.fbHandler(rowData.fbLink)}>
                //    <MDBIcon fab icon="facebook" />
                //</MDBBtn>
                //<MDBBtn tag="a" size="sm" floating
                //    gradient="aqua"
                //    onClick={() => this.editHandler(rowData.memberId)}>
                //    <MDBIcon icon="edit" />
                //</MDBBtn>
                //<MDBBtn tag="a" size="sm" floating
                //    className="ripe-malinka-gradient"
                //    onClick={() => this.downloadProfileHandler(rowData.memberId)}>
                //    <MDBIcon far icon="file-pdf" />
                //</MDBBtn>
                //<MDBBtn tag="a" size="sm" floating
                //    gradient="peach"
                //    onClick={() => this.viewProfileHandler(rowData.memberId)}>
                //    <MDBIcon icon="user-tie" />
                //</MDBBtn>
            }
        </div>;
    }

    changeHandler = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    modalHandler = (memberid) => {
        this.setState({
            modal: true,
            memberId: memberid
        });
    }

    formatDate = date => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    getPickerValue = (value) => {
        var date = this.formatDate(value);
        this.setState({
            ...this.state,
            donationDateConvert: date
        });
    }

    paymentModeChangeHandler = (value) => {
        const paymentModeId = parseInt(value);
        this.props.loadServiceHolder(paymentModeId);
        if (paymentModeId === 2) {
            this.setState({
                ...this.state,
                isAccount: true,
                isCheque: true,
                isCard: false,
                isMobile: false,
                serviceHolderId:0,
                paymentModeId: paymentModeId
            });
        }
        else if (paymentModeId === 5) {
            this.setState({
                ...this.state,
                isAccount: false,
                isCheque: false,
                isCard: false,
                isMobile: true,
                serviceHolderId: 0,
                paymentModeId: paymentModeId
            });
        }
        else if (paymentModeId === 7) {
            this.setState({
                ...this.state,
                isAccount: false,
                isCheque: false,
                isCard: true,
                isMobile: false,
                serviceHolderId: 0,
                paymentModeId: paymentModeId
            });
        }
        else {
            this.setState({
                ...this.state,
                isAccount: false,
                isCheque: false,
                isCard: false,
                isMobile: false,
                serviceHolderId: 0,
                paymentModeId: paymentModeId
            });
        }
    }

    serviceHolderChangeHandler = (value) => {
        this.setState({
            ...this.state,
            serviceHolderId: parseInt(value)
        });
    }

    /*===================
     * VALIDATION HANDLER
     * =================*/
    validationHandler = () => {
        let { donationDateConvert, paymentModeId,serviceHolderId,accountNo, mobileNo, amount
        } = this.state;
        
        if (!donationDateConvert) {
            toast.error('Donation date is required!', {
                closeButton: true
            });
            return true;
        }
        if (paymentModeId == 0) {
            toast.error('Payment mode is required!', {
                closeButton: true
            });
            return true;
        }
        
        if (paymentModeId == 2) {
            if (serviceHolderId == 0) {
                toast.error('Service holder is required!', {
                    closeButton: true
                });
                return true;
            }
            if (accountNo == '') {
                toast.error('Account no. is required!', {
                    closeButton: true
                });
                return true;
            }
        }
        
        if (paymentModeId == 5) {
            if (serviceHolderId == 0) {
                toast.error('Service holder is required!', {
                    closeButton: true
                });
                return true;
            }
            if (mobileNo == '') {
                toast.error('Mobile no. is required!', {
                    closeButton: true
                });
                return true;
            }
        }

        if (!amount) {
            toast.error('Amount is required!', {
                closeButton: true
            });
            return true;
        }
        return false;
    }

    handleSubmission = () => {

        if (this.validationHandler())
            return;
        this.state.amount = parseFloat(this.state.amount);
        let { donationDateConvert, paymentModeId, serviceHolderId, accountNo
            , mobileNo, amount, chequeNo, cardNo, transactionNo, remarks, memberId
        } = this.state;

        let entryBy = this.props.auth.user.userId;

        this.state.isStateChange = true;
        this.state.submitText = 'Sending...';

        this.props.insertDonation({
            donationDateConvert, paymentModeId, serviceHolderId, accountNo, mobileNo,
            amount, chequeNo, cardNo, transactionNo, remarks, entryBy, memberId
        });
    }

    //fbHandler(url) {
    //    url = 'https://www.facebook.com/' + url;
    //    window.open(url, '_blank');
    //}

    //viewProfileHandler(memberId) {
    //    let url = '/api/Report/MemberCV/2/' + memberId + '?format=png';
    //    window.open(url, '_blank');
    //}

    //downloadProfileHandler(memberId) {
    //    let url = '/api/Report/MemberCV/2/' + memberId + '?format=pdf';
    //    window.open(url, '_blank');
    //}

    //editHandler(id) {
    //    this.props.history.push('/admin/updateDonarmember/' + id);
    //}

    //deleteHandler(rowData) {
    //    console.log(rowData);
    //}

    render() {
        let { accountNo, isAccount, mobileNo, isMobile, chequeNo, isCheque, cardNo, isCard,
            transactionNo, amount, payTo, remarks, submitText
        } = this.state;
        return (
            <div id="donation">
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Donar Member List</MDBCardHeader>
                    <MDBCardBody>
                        <DataTable value={this.props.member.members}
                            selectionMode="single"
                            paginator={true}
                            rows={10}
                            first={this.state.first}
                            onPage={(e) => this.setState({ first: e.first })}
                            responsive={true}
                        >
                            <Column field="memberNameBd" header="" body={this.profileTemplate} headerStyle={{ width: '17em' }} />
                            <Column field="memberCode" header="ID" />
                            <Column field="thana" header="Thana" />
                            <Column field="country" header="Country" />
                            <Column field="mobileNo" header="Mobile No" />
                            <Column field="occupation" header="Occupation" />
                            <Column field="thana" header="" body={this.actionTemplate} headerStyle={{ width: '22em' }} />
                        </DataTable>
                    </MDBCardBody>
                </MDBCard>

                { /*=======MODAL=======*/}
                <MDBModal isOpen={this.state.modal} toggle={this.toggle()} size="lg">
                    <MDBModalHeader toggle={this.toggle()} className="primary-color-dark">Donation Collection</MDBModalHeader>
                    <MDBModalBody className="memberFieldBtn">
                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="6">
                                <MDBRow>
                                    <MDBCol>
                                        <div className="dtpDivFullWidth">
                                            <label>Donation Date(*)</label>
                                            <MDBDatePicker
                                                name="donationDateConvert"
                                                className="mt-4"
                                                getValue={this.getPickerValue}
                                                format='MM/DD/YYYY'
                                            />
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBSelect label="Payment Mode(*)"
                                            name="paymentModeId"
                                            getValue={this.paymentModeChangeHandler}
                                            className="mt-4"
                                        >
                                            <MDBSelectInput selected="Select Payment Mode" />
                                            <MDBSelectOptions>
                                                <MDBSelectOption disabled>Select Payment Mode</MDBSelectOption>
                                                {
                                                    this.props.tran.paymentModes &&
                                                    this.props.tran.paymentModes.map(cnt => {
                                                        return <MDBSelectOption key={cnt.paymentModeId} value={cnt.paymentModeId}>{cnt.paymentModeName}</MDBSelectOption>
                                                    })
                                                }
                                            </MDBSelectOptions>
                                        </MDBSelect>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>

                                        <MDBSelect label="Service Holder"
                                            name="serviceHolderId"
                                            getValue={this.serviceHolderChangeHandler}
                                            className="mt-4"
                                        >
                                            <MDBSelectInput selected="Select Service Holder" />
                                            <MDBSelectOptions>
                                                <MDBSelectOption disabled>Select Service Holder</MDBSelectOption>
                                                {
                                                    this.props.tran.serviceHolders &&
                                                    this.props.tran.serviceHolders.map(cnt => {
                                                        return <MDBSelectOption key={cnt.serviceHolderId} value={cnt.serviceHolderId}>{cnt.serviceHolderName}</MDBSelectOption>
                                                    })
                                                }
                                            </MDBSelectOptions>
                                        </MDBSelect>

                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label="Pay To"
                                            name="payTo"
                                            className="mt-4"
                                            value={payTo}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>

                            <MDBCol size="12" sm="12" lg="6">                                
                                {
                                    isAccount && 
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput label="Account No(*)"
                                                name="accountNo"
                                                className="mt-4"
                                                value={accountNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }
                                
                                {
                                    isMobile && 
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput label="Mobile No(*)"
                                                name="mobileNo"
                                                className="mt-4"
                                                value={mobileNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }

                                {
                                    isCheque && 
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput label="Cheque No"
                                                name="chequeNo"
                                                className="mt-4"
                                                value={chequeNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }
                                {
                                    isCard && 
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput label="Card No"
                                                name="cardNo"
                                                className="mt-4"
                                                value={cardNo}
                                                onChange={this.changeHandler}
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                }
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label="Transaction/Voucher/Memo/Money Receipt No"
                                            name="transactionNo"
                                            className="mt-4"
                                            value={transactionNo}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label="Amount(*)"
                                            name="amount"
                                            className="mt-4"
                                            value={amount}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput label="Remarks"
                                            type="textarea"
                                            rows="3"
                                            name="remarks"
                                            className="mt-4"
                                            value={remarks}
                                            onChange={this.changeHandler}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                            {submitText}
                                        </MDBBtn>
                                        {
                                            //    <MDBBtn color="danger" rounded onClick={this.handleClear}>
                                            //        Clear
                                            //  </MDBBtn>
                                        }
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModal>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer,
    tran: state.transaction
})

export default connect(mapStateToProps, {
    getDonarMembers,
    loadPaymentMode,
    loadServiceHolder,
    insertDonation
})(DonarMemberList);