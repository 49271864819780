import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCol, MDBRow, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,
    MDBIcon, MDBBtn, toast, ToastContainer
} from "../../../mdbreact";
import { loadCards } from '../../../store/actions/greetingCardAction';
import { getMemberDesignations } from '../../../store/actions/memberAction';

let init = {
    generateMemberId: null,
    generateCardId: null,
    generateDesignationId: null,
    generateIsSrcUrl: null,
    generateSrcCss: {
        backgroundImage: null,        
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        border: '3px solid hsl(14, 100%, 53%)',
        height: '300px',
        width: '200px'
    },
    designations: null
}
class Generate extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidMount() {
        if (this.props.memberProfile) {
            this.props.getMemberDesignations(this.props.memberProfile.memberId);
        }
        this.props.loadCards();
        //this.props.getMembersBasicInfo();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.member.designations) {
            this.state.designations = nextProps.member.designations;
        }
        if (nextProps.memberProfile) {
            this.state.generateMemberId = nextProps.memberProfile.memberId;
        }
    }

    cardChangeHandler = (value) => {
        let generateCardId = parseInt(value);
        let img = this.props.cards.cards.filter(c => c.cardId === generateCardId);
        const src = window.location.protocol + '//' + window.location.host + '/Uploads/GreetingCard/thumb/' + img[0].imageName;
        
        this.setState({
            generateIsSrcUrl: true,
            generateCardId: generateCardId,
            generateSrcCss: {
                ...this.state.generateSrcCss,
                backgroundImage: 'url("' + src + '")'
            }
        });
    }

    memberChangeHandler = (value) => {
        this.state.generateMemberId = parseInt(value);
        //this.setState({
        //    ...this.state,
        //    generateMemberId: parseInt(value[0])
        //});
        //console.log(this.state, value);
        this.props.getMemberDesignations(this.state.generateMemberId);
    }

    designationChangeHandler = (value) => {
        this.state.generateDesignationId = parseInt(value);
    }

    validation = () => {
        if (this.state.generateCardId == null) {
            toast.error('Select greeting card!', {
                closeButton: true
            });
            return true;
        }
        if (this.state.generateMemberId == null) {
            toast.error('Select member code!', {
                closeButton: true
            });
            return true;
        }
        if (this.state.generateDesignationId == null) {
            toast.error('Select member designation!', {
                closeButton: true
            });
            return true;
        }
        return false;
    }

    generateGreetingCard = (ext) => {
        //console.log(this.state);
        if (this.validation())
            return;

        let url = '/api/Report/GenerateGreetingCard/3/'
            + this.state.generateMemberId
            + '/'
            + this.state.generateCardId
            + '/'
            + this.state.generateDesignationId
            + '?format=' + ext;
        window.open(url, '_blank');
    }

    render() {
        return (
            <MDBRow>
                <MDBCol size="12" sm="12" lg="6">
                    <MDBRow>
                        <MDBCol>
                            <MDBSelect label="Greeting Card"
                                name="generateCardId"
                                getValue={this.cardChangeHandler}
                                className="mt-4"
                            >
                                <MDBSelectInput selected="Select Greeting Card" />
                                <MDBSelectOptions>
                                    <MDBSelectOption disabled>Select Greeting Card</MDBSelectOption>
                                    {
                                        this.props.cards.cards &&
                                        this.props.cards.cards.map(cnt => {
                                            return <MDBSelectOption key={cnt.cardId} value={cnt.cardId}>{cnt.cardTitle}</MDBSelectOption>
                                        })
                                    }
                                </MDBSelectOptions>
                            </MDBSelect>
                        </MDBCol>
                    </MDBRow>
                    {
                        !this.props.memberProfile &&
                        <MDBRow>
                            <MDBCol>
                                <MDBSelect label="Member"
                                    name="generateMemberId"
                                    getValue={this.memberChangeHandler}
                                    className="mt-4"
                                >
                                    <MDBSelectInput selected="Select Member" />
                                    {
                                        this.props.memberList && 
                                        <MDBSelectOptions>
                                            <MDBSelectOption disabled>Select Member</MDBSelectOption>
                                            {
                                                this.props.memberList &&
                                                this.props.memberList.map(cnt => {
                                                    return <MDBSelectOption key={cnt.memberId}
                                                        value={cnt.memberId}>{cnt.memberCode}
                                                    </MDBSelectOption>
                                                })
                                            }
                                        </MDBSelectOptions>
                                    }                                    
                                </MDBSelect>
                            </MDBCol>
                        </MDBRow>

                    }
                    <MDBRow>
                        <MDBCol>
                            <MDBSelect label="Designation"
                                name="generateDesignationId"
                                getValue={this.designationChangeHandler}
                                className="mt-4"
                            >
                                <MDBSelectInput selected="Select Designation" />
                                <MDBSelectOptions>
                                    <MDBSelectOption disabled>Select Designation</MDBSelectOption>
                                    {
                                        this.state.designations &&
                                        this.state.designations.map((cnt,index) => {
                                            return <MDBSelectOption key={index} value={cnt.designationId}>{cnt.designation}</MDBSelectOption>
                                        })
                                    }
                                </MDBSelectOptions>
                            </MDBSelect>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <MDBBtn color="success" rounded onClick={ () => this.generateGreetingCard('pdf')}>
                                <MDBIcon icon="download" style={{marginRight:'5px'}} />Download
                            </MDBBtn>
                            <MDBBtn color="info" rounded onClick={() => this.generateGreetingCard('png')}>
                                <MDBIcon icon="image" style={{ marginRight: '5px' }} />Preview
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol size="12" sm="12" lg="4">
                    <MDBRow>
                        <MDBCol>
                            {
                                this.state.generateIsSrcUrl &&
                                <div style={this.state.generateSrcCss}></div>
                            }
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <MDBCol size="12" sm="12" lg="4">
                    <MDBRow>
                        <MDBCol>

                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </MDBRow>   
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    cards: state.cardReducer,
    member: state.memberReducer
})

export default connect(mapStateToProps, {
    loadCards,
    getMemberDesignations
})(Generate);
