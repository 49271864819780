import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn, MDBIcon, toast, ToastContainer
} from "../../../mdbreact";

import { insertDesignation } from '../../../store/actions/settingAction';

let init = {
    designationId: 0,
    designation: '',
    description: '',
    orderNo: '',
    sendText: 'Submit',
    isUpdateTriger : false
}
class Create extends Component {
    constructor(props) {
        super(props);
        this.state = init;
    }

    //componentDidUpdate(prevProps) {
    //    console.log("Start Component did Update");
    //    console.log(prevProps);
    //    console.log("End Component Did Update");
    //    //if (this.props.dwelling !== prevProps.dwelling) {
    //    //    this.setState(() => ({ dwelling: this.props.dwelling }));
    //    //}
    //}

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...init
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("Start Component Receive Props");
        console.log(nextProps);
        console.log(this.state.designationId);
        console.log("End Component Receive Props");

        if (nextProps.updateObject) {
            if (nextProps.updateObject.designationId !== this.state.designationId) {
                this.setState({
                    ...this.state,
                    designationId: nextProps.updateObject.designationId,
                    designation: nextProps.updateObject.designation,
                    description: nextProps.updateObject.description == null ? '' : nextProps.updateObject.description,
                    orderNo: nextProps.updateObject.orderNo == null ? 0 : nextProps.updateObject.orderNo,
                    sendText: 'Update',
                });
            }
        }
        if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Submit'
            && this.state.isUpdateTriger == true
        ) {
            this.loadMessage('Designation saved successfully.');
        }
        else if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Update') {
            this.loadMessage('Designation updated successfully.');
        }
    }

    //static getDerivedStateFromProps(nextProps, prevState) {
    //    console.log('==START STATE PROP==');
    //    console.log(nextProps);      
    //    console.log(prevState);
    //    console.log('== END STATE PROP ==');
    //    if (nextProps.desig) {
    //        //console.log('Hello world');
    //        if (nextProps.desig.designationId !== prevState.designationId) {
    //            return {
    //                designationId: nextProps.desig.designationId,
    //                designation: nextProps.desig.designation,
    //                description: nextProps.desig.description == null ? '' : nextProps.desig.description,
    //                orderNo: nextProps.desig.orderNo == null ? 0 : nextProps.desig.orderNo,
    //                sendText: 'Update',
    //                //isUpdateClick: true
    //            }
    //        }
    //        else if (nextProps.setting.statusCode == 200 && prevState.sendText == 'Submit') {
    //            toast.success('Designation saved successfully.', {
    //                closeButton: true
    //            });
    //            return {
    //                designationId: 0,
    //                designation: '',
    //                description: '',
    //                orderNo: ''
    //            }
    //        }
    //        else if (nextProps.setting.statusCode == 200 && prevState.sendText == 'Update') {
    //            toast.success('Designation updated successfully.', {
    //                closeButton: true
    //            });
    //            return {
    //                designationId: 0,
    //                designation: '',
    //                description: '',
    //                orderNo: '',
    //                sendText: 'Submit',
    //                isUpdateClick: null
    //            }
    //        }
    //    }
    //    else if (nextProps.setting.statusCode == 200 && prevState.sendText == 'Submit') {
    //        toast.success('Designation saved successfully.', {
    //            closeButton: true
    //        });
    //        return {
    //            designationId: 0,
    //            designation: '',
    //            description: '',
    //            orderNo: ''
    //        }
    //    }
    //    else if (nextProps.setting.statusCode == 200 && prevState.sendText == 'Update') {
    //        toast.success('Designation updated successfully.', {
    //            closeButton: true
    //        });
    //        return {
    //            designationId: 0,
    //            designation: '',
    //            description: '',
    //            orderNo: '',
    //            sendText: 'Submit',
    //            isUpdateClick: null
    //        }
    //    }
    //    return null;
    //}

    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }
    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.designation == '') {
            toast.error('Designation is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.orderNo == '') {
            toast.error('Serial no. is required!', {
                closeButton: true
            });
            return error = true;
        }
        return error;
    }
    handleSubmission = () => {
        let error = this.validationHandler();
        if (error)
            return;
        if (this.state.orderNo)
            this.state.orderNo = parseInt(this.state.orderNo);

        let { designationId, designation, description, orderNo } = this.state;

        let entryBy = this.props.auth.user.userId;
        let editBy = this.props.auth.user.userId;

        if (this.state.isUpdateTriger == false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true
            });
        }
        this.props.insertDesignation({ designationId, designation, description, orderNo, entryBy, editBy });
    }

    handleClear = () => {
        this.setState({
            ...init
        });
    }

    render() {
        let { designation, description, orderNo, sendText } = this.state
        return (
            <div id='designationCreate'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Create Designation</MDBCardHeader>
                    <MDBCardBody>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Serial No."
                                    name="orderNo"
                                    className="mt-4"
                                    value={orderNo}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Designation"
                                    name="designation"
                                    className="mt-4"
                                    value={designation}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Description"
                                    type="textarea"
                                    rows="5"
                                    name="description"
                                    className="mt-4"
                                    value={description}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                    {sendText}
                                </MDBBtn>
                                {
                                    //<MDBBtn color="danger" rounded onClick={this.handleClear}>
                                    //    Clear
                                    //</MDBBtn>
                                }
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCardBody>
                </MDBCard>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting
})

export default connect(mapStateToProps, { insertDesignation })(Create);