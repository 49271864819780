import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn, MDBIcon, toast, ToastContainer
} from "../../../mdbreact";

import { insertUnion, loadUpazilas } from '../../../store/actions/settingAction';
import { Form } from 'react-bootstrap';

let init = {
    unionId: 0,
    upazilaId: '0',
    unionName: '',
    description: '',
    orderNo: '',
    sendText: 'Submit',
    isUpdateTriger : false
}

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidMount() {
        this.props.loadUpazilas();
    }

    componentDidUpdate(prevProps) {
    }

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...init
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        if (nextProps.updateObject) {
            if (nextProps.updateObject.unionId !== this.state.unionId) {
                this.setState({
                    ...this.state,
                    unionId: nextProps.updateObject.unionId,
                    unionName: nextProps.updateObject.unionName,
                    upazilaId: nextProps.updateObject.upazilaId,
                    description: nextProps.updateObject.description == null ? '' : nextProps.updateObject.description,
                    orderNo: nextProps.updateObject.orderNo == null ? 0 : nextProps.updateObject.orderNo,
                    sendText: 'Update',
                });
            }
        }
        if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Submit'
            && this.state.isUpdateTriger == true
            ) {
            this.loadMessage('Union saved successfully.');
        }
        else if (nextProps.setting.statusCode == 200
            && this.state.sendText == 'Update') {
            this.loadMessage('Union updated successfully.');
        }
    }
    
    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }
    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.unionName == '') {
            toast.error('Union name is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.upazilaId == '0') {
            toast.error('Upazila is required!', {
                closeButton: true
            });
            return error = true;
        }
        return error;
    }

    handleSubmission = () => {
        let error = this.validationHandler();
        if (error)
            return;
        if (this.state.orderNo)
            this.state.orderNo = parseInt(this.state.orderNo);
        else
            this.state.orderNo = 0;

        this.state.upazilaId = parseInt(this.state.upazilaId);

        let { unionId, unionName, description, orderNo, upazilaId } = this.state;

        let entryBy = this.props.auth.user.userId;
        let editBy = this.props.auth.user.userId;

        if (this.state.isUpdateTriger == false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true
            });
        }
        let districtId = 1;
        this.props.insertUnion({
            unionId, unionName, description, orderNo,
            upazilaId, entryBy, editBy, districtId
        });
    }

    handleClear = () => {
        this.setState({
            ...init
        });
    }

    render() {
        let { unionName, description, orderNo, upazilaId, sendText } = this.state
        return (
            <div id='unionCreate'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Create Union</MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <Form.Group>
                                    <Form.Label>Upazila</Form.Label>
                                    <Form.Control as="select" custom
                                        name="upazilaId"
                                        id="upazilaId"
                                        value={upazilaId}
                                        onChange={this.changeHandler}>
                                        <option value='0'>Select</option>
                                        {
                                            this.props.setting.upazilas && this.props.setting.upazilas.map((c, index) => {
                                                return (<option key={index} value={c.upazilaId}>{c.upazilaName}</option>)
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Union Name"
                                    name="unionName"
                                    className="mt-4"
                                    value={unionName}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Description"
                                    type="textarea"
                                    rows="5"
                                    name="description"
                                    className="mt-4"
                                    value={description}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Serial No."
                                    name="orderNo"
                                    className="mt-4"
                                    value={orderNo}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                                    {sendText}
                                </MDBBtn>
                                {
                                //    <MDBBtn color="danger" rounded onClick={this.handleClear}>
                                //        Clear
                                //  </MDBBtn>
                                }
                            </MDBCol>
                        </MDBRow>

                    </MDBCardBody>
                </MDBCard>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting
})

export default connect(mapStateToProps, { insertUnion, loadUpazilas })(Create);