import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol, MDBInput,
    MDBBtn,toast, ToastContainer
} from "../../../mdbreact";
import Dropzone from 'react-dropzone';
import { Form } from 'react-bootstrap';
import { register } from '../../../store/actions/authAction';

const imgMaxSize = 150000;
const imgMinSize = 80000;

let init = {
    userId: 0,
    name: '',
    mobile: '',
    phone: '',
    email: '',
    userName: '',
    password: '',
    userType: '',
    banner: [],
    srcUrl: '',
    isSrcUrl: {
        'display': 'none'
    },
    sendText: 'Submit',
    isUpdateTriger : false,    
    isBtnDisable:false
}

class Create extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidUpdate(prevProps) {  }

    loadMessage = (msg) => {
        toast.success(msg, {
            closeButton: true
        });
        this.setState({
            ...init
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //console.log("Start Component Receive Props");
        //console.log(nextProps);
        //console.log(this.state.userId);
        //console.log("End Component Receive Props");

        if (nextProps.updateObject) {
            if (nextProps.updateObject.userId !== this.state.userId) {

                if (nextProps.updateObject.imageName) {
                    let path = window.location.protocol + '//' + window.location.host + '/Uploads/Users/';
                    this.setState({
                        ...this.state,
                        userId: nextProps.updateObject.userId,
                        name: nextProps.updateObject.name,
                        mobile: nextProps.updateObject.mobile,
                        phone: nextProps.updateObject.phone,
                        email: nextProps.updateObject.email,
                        userName: nextProps.updateObject.userName,
                        password: nextProps.updateObject.password,
                        userType: nextProps.updateObject.userType,
                        srcUrl: nextProps.updateObject.imageName !== null ? path + nextProps.updateObject.imageName : '',
                        sendText: 'Update',
                        isSrcUrl: {
                            'display': 'block'
                        },
                        //srcUrl: path + nextProps.updateObject.imageName
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        userId: nextProps.updateObject.userId,
                        name: nextProps.updateObject.name,
                        mobile: nextProps.updateObject.mobile,
                        phone: nextProps.updateObject.phone,
                        email: nextProps.updateObject.email,
                        userName: nextProps.updateObject.userName,
                        password: nextProps.updateObject.password,
                        userType: nextProps.updateObject.userType,
                        srcUrl: nextProps.updateObject.imageName !== null ? nextProps.updateObject.imageName : '',
                        sendText: 'Update',
                        isSrcUrl: {
                            'display': 'none'
                        }
                    })
                }
            }
        }
        if (nextProps.auth.statusCode === 200
            && this.state.sendText === 'Sending...'
            && this.state.isUpdateTriger === true
        ) {
            this.loadMessage('User saved successfully.');
        }
        //else if (nextProps.setting.statusCode == 200
        //    && this.state.sendText == 'Update') {
        //    this.loadMessage('Committee updated successfully.');
        //}
    }

    /*=========CHANGE HANDLER==========*/
    changeHandler = event => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    /*=========VALIDATION HANDLER==========*/
    validationHandler = () => {
        let error = false;
        if (this.state.name === '') {
            toast.error('User full name is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.userName === '') {
            toast.error('User name is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.password === '') {
            toast.error('User password is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.email === '') {
            toast.error('Email is required!', {
                closeButton: true
            });
            return error = true;
        }
        if (this.state.userType === '') {
            toast.error('User type is required!', {
                closeButton: true
            });
            return error = true;
        }
        return error;
    }

    handleSubmission = () => {
        //console.log(this.state);
        //return;
        let error = this.validationHandler();
        if (error)
            return;  
        
        let { userId, name, mobile,email, phone, userName, password, userType, banner} = this.state;

        let formData = new FormData();
        formData.append('userId', userId);
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('userName', userName);
        formData.append('password', password);
        formData.append('userType', userType);
        formData.append('userImage', banner);

        if (this.state.isUpdateTriger === false) {
            this.setState({
                ...this.state,
                isUpdateTriger: true,
                isBtnDisable: true,
                sendText:'Sending...'
            });
        }
        this.props.register(formData);
    }

    userTypeChangeHandler = event => {
        this.setState({
            ...this.state,
            userType: event[0]
        })
    }

    handleClear = () => {
        this.setState({
            ...init
        });
    }

    /*============ IMAGE PREVIEW ========== */
    imagePreviewHandler = (files, rejectedFiles) => {
        //console.log(files);
        //return;
        //rejected image
        if (rejectedFiles.length > 0) {
            rejectedFiles[0].errors.map(err => {
                toast.error(err.code, {
                    closeButton: true
                });
            })            
        }
        else {
            //process image preview
            const currentFile = files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                //console.log(reader.result);
                this.setState({
                    ...this.state,
                    banner: currentFile,
                    srcUrl: reader.result,
                    isSrcUrl: {
                        'display':'block'
                    }
                })
            }, false);
            reader.readAsDataURL(currentFile);
        }   
    }

    render() {   

        let { name, isSrcUrl, mobile, phone, email,userName, password, userType, srcUrl, sendText, isBtnDisable } = this.state
        return (
            <div id='nameCreate'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">Create a New Account </MDBCardHeader>
                    <MDBCardBody>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="User Full Name"
                                    name="name"
                                    className="mt-4"
                                    value={name}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="User Name"
                                    name="userName"
                                    className="mt-4"
                                    value={userName}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Password"
                                    type="password"
                                    name="password"
                                    className="mt-4"
                                    value={password}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Mobile"
                                    name="mobile"
                                    className="mt-4"
                                    value={mobile}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Phone"
                                    name="phone"
                                    className="mt-4"
                                    value={phone}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBInput label="Email"
                                    name="email"
                                    className="mt-4"
                                    value={email}
                                    onChange={this.changeHandler}
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <Form.Group>
                                    <Form.Label>User type</Form.Label>
                                    <Form.Control as="select" custom
                                        name="userType"
                                        id="userType"
                                        value={userType}
                                        onChange={this.changeHandler}>
                                        <option value=''>Select user type</option>
                                        <option value='admin'>Admin</option>
                                        <option value='operator'>Operator</option>
                                    </Form.Control>
                                </Form.Group>
                            </MDBCol>
                        </MDBRow>                        

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                {
                                    <div>
                                        <div className="dropzoneImagePreview" style={isSrcUrl}>
                                            <div className="thumbsContainer">
                                                <div className="thumb">
                                                    <div className="thumbInner">
                                                        <img
                                                            src={srcUrl}
                                                            className="img"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Dropzone onDrop={this.imagePreviewHandler} multiple={false} minSize={imgMinSize} maxSize={imgMaxSize} accept="image/*">
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzoneContainer">
                                                    <div
                                                        {...getRootProps({
                                                            className: 'dropzone',
                                                            //onDrop: event => event.stopPropagation()
                                                        })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <p>Drag 'n' drop profile picture</p>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                }
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol size="12" sm="12" lg="12">
                                <MDBBtn color="success" rounded onClick={this.handleSubmission} disabled={isBtnDisable}>
                                    {sendText}
                                </MDBBtn>
                                {
                                    //<MDBBtn color="danger" rounded onClick={this.handleClear}>
                                    //    Clear
                                    //</MDBBtn>
                                }
                            </MDBCol>
                        </MDBRow>
                        
                    </MDBCardBody>
                </MDBCard>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { register })(Create);