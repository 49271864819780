import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDonarMembers } from '../../../store/actions/memberAction';
import DonarList from './DonarList';

class Donar extends Component {

    state = {        
        Donar: '',
        searchTerm: '',
        data: [],
        isLoading: true,
        isSearch:false
    }

    componentDidMount() {
        let Donar = this.props.match.params.cid;
        this.props.getDonarMembers(Donar);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(JSON.stringify(this.props.member.members) !== JSON.stringify(prevProps.member.members) && this.state.isSearch === false) {
            this.setState({
                ...this.state,
                isLoading: false,
                data:this.props.member.members
            });
        }
    }

    // changeHandler = (event) => {
    //     const data = this.props.member.members;
    //     let term = event.target.value.toLowerCase();
    //     //console.log(this.props.member.members);
    //     let filterResult = data.filter(c => ( c.country && c.country.toLowerCase().includes(term)) 
    //         || ( c.designation && c.designation.toLowerCase().includes(term))
    //         || (c.memberCode && c.memberCode.toLowerCase().includes(term))
    //         || (c.memberNameBd && c.memberNameBd.toLowerCase().includes(term))
    //         || (c.memberNameEn && c.memberNameEn.toLowerCase().includes(term))
    //         || (c.mobileNo && c.mobileNo.toLowerCase().includes(term))
    //         || (c.thana && c.thana.toLowerCase().includes(term)));
    //         //console.log(filterResult);
    //     this.setState({
    //         ...this.state,
    //         [event.target.name]: event.target.value,
    //         data: filterResult,
    //         isSearch: true
    //     });
    // }

    searchHandler = (term) => {
        const data = this.props.member.members;
        let result = data.filter(c => (c.immigrationCountry && c.immigrationCountry.toLowerCase().includes(term))
            || (c.occupation && (c.occupation.toLowerCase().includes(term)))
            || (c.memberCode && c.memberCode.toLowerCase().includes(term))
            || (c.memberNameBd && c.memberNameBd.toLowerCase().includes(term))
            || (c.memberNameEn && c.memberNameEn.toLowerCase().includes(term))
            || (c.mobileNo && c.mobileNo.toLowerCase().includes(term))
            || (c.thana && c.thana.toLowerCase().includes(term))
        );
        return result;
    }

    changeHandler = (event) => {      
        let term = event.target.value.toLowerCase();
        let result = this.searchHandler(term);
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            data: result,
            isSearch: true
        });
    }    

    clickHandler = (term) => {
        let result = this.searchHandler(term);
        console.log(result);
        this.setState({
            ...this.state,            
            data: result,
            isSearch: true
        });        
    }

    render() {
        const { data, isLoading} = this.state;
        return (
            <DonarList
                isLoading={isLoading}
                members={data}
                searchTerm={this.state.searchTerm}
                changeHandler={this.changeHandler}
                clickHandler={this.clickHandler}
            />
        );
    }
}
const mapStateToProps = state => ({
    member: state.memberReducer
})

export default connect(mapStateToProps, { getDonarMembers })(Donar);