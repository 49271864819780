import React, { Component } from 'react';

class Dashboard extends Component {

    render() {
        return (
            <div>
                <h1>নারায়ণগঞ্জ জেলা প্রবাসী ও প্রবাস ফেরত কল্যাণ পরিষদে আপনাকে স্বাগতম।</h1>
            </div>  
        )
    }
}
export default Dashboard