import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { InputGroup, FormControl } from 'react-bootstrap';
import { BsSearch } from "react-icons/bs";
import { FaCloudUploadAlt } from "react-icons/fa";

const SearchOneUI = ({ placeholder, searchTerm, isSearch, isOption1, optionText1, optionText2, changeHandler, searchHandler, option1Handler }) => {
    // console.log(isSearch,'Search');
    return (
        <div>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder={placeholder}
                    aria-label="অনুসন্ধান করুন"
                    aria-describedby="basic-addon2"
                    value={searchTerm}
                    name="searchTerm"
                    onChange={(e) => changeHandler(e)}
                />
                {
                    isSearch == true ? (
                        <InputGroup.Append onClick={() => searchHandler(searchTerm)} style={{ cursor: "pointer" }}>
                            <InputGroup.Text id="basic-addon2"><BsSearch />{' ' + optionText1 && optionText1}</InputGroup.Text>
                        </InputGroup.Append>
                    ) : ''
                }
                {
                    isOption1 == true ? (
                        <InputGroup.Append onClick={option1Handler} style={{ cursor: "pointer" }}>
                            <InputGroup.Text id="basic-addon2"><FaCloudUploadAlt />{' ' + optionText2 && optionText2}</InputGroup.Text> 
                        </InputGroup.Append>

                    ) : ''
                }
            </InputGroup>
        </div>
    );
};

SearchOneUI.prototype = {
    isSearch: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    searchTerm: PropTypes.string.isRequired,
    changeHandler: PropTypes.func.isRequired,
    searchHandler: PropTypes.func,
    option1Handler: PropTypes.func
}

export default SearchOneUI;