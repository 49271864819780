import * as Types from './types';
import Axios from 'axios';

export const loadCards = () => dispatch => {
    Axios.get('api/GreetingCard/GetGreetingCards')
        .then(response => {
            dispatch({
                type: Types.LOAD_CARDS,
                payload: {
                    cards: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertCard = (card) => dispatch => {
    //console.log(card);
    //return;
    Axios.post('api/GreetingCard/SaveCard', card)
        .then(response => {
            //console.log(response.data);
            dispatch({
                type: Types.SAVE_CARD,
                payload: {
                    card: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}