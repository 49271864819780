import * as Types from '../actions/types';

const committeeAccessReducer = (state = [], action) => {

    switch (action.type) {
        case Types.LOAD_COMMITTEE_RELATION: {
            return {
                fieldValues: action.payload.fieldValues
            }
        }
        case Types.LOAD_COMMITTEE_ACCESS: {
            return {
                committeeAccess: action.payload.committeeAccess
            }
        }
        case Types.SAVE_COMMITTEE_RELATION: {
            return {
                statusCode: 200,
                message: 'Relation created successfully.',
                fieldValues: action.payload.fieldValues
            }
        }
        case Types.DELETE_COMMITTEE_RELATION: {
            let statusCode = null;
            let fieldValues = [...state.fieldValues];
            
            if (action.payload.isDelete) {
                statusCode = 200;
                fieldValues = fieldValues.filter(c => c.id !== action.payload.id);
            }
            else
                statusCode = 420;

            return {
                statusCode: statusCode,
                message: 'Relation delete successfully.',
                fieldValues: fieldValues
            }
        }
        case Types.SAVE_COMMITTEE_ACCESS: {
            return {
                statusCode: 200,
                message: 'Committee access successfully.',
                committeeAccess: action.payload.committeeAccess
            }
        }
        case Types.DELETE_COMMITTEE_ACCESS: {
            let statusCode = null;
            let committeeAccess = [...state.committeeAccess];
            
            if (action.payload.isDelete) {
                statusCode = 200;
                committeeAccess = committeeAccess.filter(c => c.committeeAccessId !== action.payload.id);
            }
            else
                statusCode = 420;

            return {
                statusCode: statusCode,
                message: 'Committee access delete successfully.',
                committeeAccess: committeeAccess
            }
        }
        default: return state;
    }
}
export default committeeAccessReducer;