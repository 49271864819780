import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBCol, MDBRow, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption,
    MDBIcon, MDBBtn, toast, ToastContainer
} from "../../../mdbreact";
import Chosen from '../../UIMaterial/Chosen';
import { getMembersBasicInfo } from '../../../store/actions/memberAction';

let init = {
    memberId: null,
    cardSite: 1,
}
class Generate extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidMount() {
        this.props.getMembersBasicInfo();
    }

    memberChangeHandler = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: parseInt(event.target.value)
        });
        //console.log(this.state);
        //this.state.generateMemberId = parseInt(value);
        //this.props.getMemberDesignations(this.state.generateMemberId);
    }

    cardSiteChangeHandler = (value) => {
        this.setState({
            ...this.state,
            cardSite: parseInt(value)
        });
        //console.log(this.state);
        //this.state.generateMemberId = parseInt(value);
        //this.props.getMemberDesignations(this.state.generateMemberId);
    }

    //designationChangeHandler = (value) => {
    //    this.state.generateDesignationId = parseInt(value);
    //}

    validation = () => {
        if (this.state.memberId === null) {
            toast.error('Member is required!', {
                closeButton: true
            });
            return true;
        }
        if (this.state.cardSite === null) {
            toast.error('Card site is required!', {
                closeButton: true
            });
            return true;
        }
        return false;
    }

    generateHanler = (ext) => {
        if (this.validation())
            return;
        let cardSite = this.state.cardSite === 1 ? 4 : 5;
        let url = '/api/Report/GenerateMemberShipCard/'
            + cardSite
            + '/'
            + this.state.memberId
            + '?format=' + ext;
        window.open(url, '_blank');
    }

    render() {
        return (
            <MDBRow>
                <MDBCol size="12" sm="12" lg="4">
                    
                    <MDBRow>
                        <MDBCol>
                            <label>Member</label>
                            {
                                <Chosen onChange={this.memberChangeHandler} name='memberId'>
                                    <option value='select'>Select</option>
                                    {
                                        this.props.member.members && this.props.member.members.map((c, index) => {
                                            return (<option key={index} value={c.memberId}>{c.memberCode + ' ' + c.memberNameEn}</option>)
                                        })
                                    }
                                </Chosen>

                                //<Chosen noResultsText="No result" value="Select" onChange={this.memberChangeHandler}>
                                //    <option value="Select">Select</option>
                                //    {
                                //        this.props.member.members &&
                                //        this.props.member.members.map(cnt => {
                                //            return <MDBSelectOption key={cnt.memberId}
                                //                value={cnt.memberId}>{cnt.memberCode + ' ' + cnt.memberNameEn}
                                //            </MDBSelectOption>
                                //        })
                                //    }
                                //</Chosen>
                            }
                            {
                            //<MDBSelect label="Member"
                            //    name="generateMemberId"
                            //    getValue={this.memberChangeHandler}
                            //    className="mt-4"
                            //>
                            //    <MDBSelectInput selected="Select Member" />
                            //    <MDBSelectOptions>
                            //        <MDBSelectOption disabled>Select Member</MDBSelectOption>
                            //        {
                            //            this.props.member.members &&
                            //            this.props.member.members.map(cnt => {
                            //                return <MDBSelectOption key={cnt.memberId}
                            //                    value={cnt.memberId}>{cnt.memberCode + ' ' + cnt.memberNameEn}
                            //                </MDBSelectOption>
                            //            })
                            //        }
                            //    </MDBSelectOptions>
                            //    </MDBSelect>
                            }
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <MDBSelect label="Card Site"
                                getValue={this.cardSiteChangeHandler}>
                                <MDBSelectInput selected="Select" />
                                <MDBSelectOptions>
                                    <MDBSelectOption value="1">Front Site</MDBSelectOption>
                                    <MDBSelectOption value="2">Back Site</MDBSelectOption>
                                </MDBSelectOptions>
                            </MDBSelect>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <MDBBtn color="success" rounded onClick={() => this.generateHanler('pdf')}>
                                <MDBIcon icon="download" style={{marginRight:'5px'}} />Download
                            </MDBBtn>
                            <MDBBtn color="info" rounded onClick={() => this.generateHanler('png')}>
                                <MDBIcon icon="image" style={{ marginRight: '5px' }}/>Preview
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </MDBRow>   
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    member: state.memberReducer
})

export default connect(mapStateToProps, { getMembersBasicInfo })(Generate);
