import * as Types from './types';
import Axios from 'axios';

export const registerMember = (member) => dispatch => {
    //console.log(member);
    //return;
    Axios.post('api/Member/SaveMember', member)
        .then(response => { 
            dispatch({
                type: Types.REGISTER_MEMBER,
                payload: {
                    statusCode: response.data.statusCode,
                    isSuccess: response.data.isSuccess
                }
            });
        })
        .catch(error => {
            console.log(error);
            //if (error.response) {
            //    this.errors(error.response.message);
            //} else if (error.request) {
            //    console.log('error.request');
            //} else {
            //    console.log('Error', error);
            //}
            //console.log("rejected");
        })
}

export const uploadProfile = (member) => dispatch => {
    //console.log(member);
    //return;
    //Axios.post('api/Member/SaveMember', member)
    //    .then(response => {
    //        console.log(response);
    //        let isSaved = false;
    //        let message = 'Registration failed.';
    //        if (response.status) {
    //            isSaved = true;
    //            message = 'Registration completed successfully.';
    //        }
    //        dispatch({
    //            type: Types.REGISTER_MEMBER,
    //            payload: {
    //                message: message,
    //                isSaved: isSaved
    //            }
    //        });
    //    })
    //    .catch(error => {
    //        if (error.response) {
    //            this.errors(error.response.message);
    //        } else if (error.request) {
    //            console.log('error.request');
    //        } else {
    //            console.log('Error', error);
    //        }
    //        console.log("rejected");
    //    })
}