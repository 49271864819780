import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon } from "../../../mdbreact";
import { loadThanas } from '../../../store/actions/settingAction';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first: 0,
            rows: 10,
            totalRecords: 0,
        }
    }
    componentDidMount() {
        this.props.loadThanas();
    }

    /*=======================
     * ACTION TEMPLATE
     * =====================*/
    actionTemplate = (rowData) => {
        return <div id='admin_pending_btn'>
            <MDBBtn tag="a" size="sm" floating
                gradient="aqua"
                onClick={() => this.props.editHandler(rowData.thanaId, this.props.setting.thanas)}>
                <MDBIcon icon="edit" />
            </MDBBtn>
        </div>;
    }

    render() {
        return (
            <div id='designationList'>
                <MDBCard wide >
                    <MDBCardHeader color="success-color">List of Thana</MDBCardHeader>
                    <MDBCardBody>
                        {this.props.setting.thanas && 
                            <DataTable value={this.props.setting.thanas}
                                selectionMode="single"
                                paginator={true}
                                rows={10}
                                first={this.state.first}
                                onPage={(e) => this.setState({ first: e.first })}
                                responsive={true}
                            >
                            <Column field="thana" header="Thana" />
                            <Column field="thanaCode" header="Thana Code" />
                            <Column field="orderNo" header="Ordering" />
                            <Column field="thana" header="" body={this.actionTemplate} headerStyle={{ width: '8em' }}/>
                            </DataTable>
                        }
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting
})

export default connect(mapStateToProps, { loadThanas })(List);