import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import {
    MDBInput, MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBDropdown, MDBDropdownToggle,
    MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBSideNavItem, MDBSideNavCat, MDBSideNavNav,
    MDBSideNav, MDBContainer, MDBSideNavLink
} from '../mdbreact';
import { logout } from '../store/actions/authAction';
import { loadMenus } from '../store/actions/menuAction';
import { SITE } from '../components/common/setup';

class AdminNavMenu extends Component {

    state = {
        toggleStateA: false,
        breakWidth: 1300,
        windowWidth: 0
    }; 

    componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        this.props.loadMenus(this.props.auth.user.userId);
    }

    //componentDidUpdate(prevProps, prevState, snapshot) {              
        
    //    if (this.props.menu.menus) {
    //        if (JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu.menus)) {
    //            //console.log(this.state.menus);
    //            //console.log(this.props.menu.menus); 
    //            this.setState({
    //                ...this.state,
    //                menus: this.props.menu.menus
    //            });
    //        }
           
    //    }
        
    //}

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () =>
        this.setState({
            windowWidth: window.innerWidth
        });

    handleToggleClickA = () => {
        this.setState({
            toggleStateA: !this.state.toggleStateA
        });
    };

    logoutHandler = () => {
        this.props.logout(this.props.history);
    }

    hasChildMenu = (childMenus = []) => {
        let ch = childMenus.map((menu) => {
            let hasChild = this.props.menu.menus.filter(res => res.menuParentId === menu.menuId);           
            if (hasChild.length > 0) {
                return (
                    <>
                        <MDBSideNavCat
                            key={menu.menuId}
                            name={menu.menuName}
                            id={menu.menuName}
                            icon="users" >
                            {this.hasChildMenu(hasChild)}
                        </MDBSideNavCat>
                    </>
                )
            }
            else {
                return (
                    <MDBSideNavLink key={menu.menuId} to={'/admin/' + menu.slug}>{menu.menuName}</MDBSideNavLink>
                )
            }
        })
        return ch;        
    }

    generateMenus = () => {
        let parentMenus = this.props.menu.menus.filter(res => res.menuParentId === null);        
        return ( 
            <>
                <MDBSideNavNav>
                    {
                        parentMenus.map(menu => {
                            let hasChild = this.props.menu.menus.filter(res => res.menuParentId === menu.menuId);                            
                            if (hasChild.length > 0) {
                                //console.log(hasChild);
                                return (
                                    <MDBSideNavCat
                                        key={menu.menuId}
                                        name={menu.menuName}
                                        id={menu.menuName}
                                        icon="users" >
                                        {this.hasChildMenu(hasChild)}
                                    </MDBSideNavCat>
                                )
                            }
                            else {
                                return (
                                    <MDBSideNavLink key={menu.menuId} to={'/admin/' + menu.slug}>
                                        {menu.menuName}
                                    </MDBSideNavLink>
                                )
                            }
                        })
                    }
                    <MDBSideNavItem
                        onClick={this.logoutHandler} >
                        <MDBIcon icon="sign-out-alt" /> Logout
                    </MDBSideNavItem>
                </MDBSideNavNav>
            </>
        );
    }

    render() {
        const navStyle = {
            paddingLeft:
                this.state.windowWidth > this.state.breakWidth ? "210px" : "16px"
        };

        const mainStyle = {
            margin: "0 6%",
            paddingTop: "3.5rem",
            paddingLeft:
                this.state.windowWidth > this.state.breakWidth ? "150px" : "0"
        };

        const specialCaseNavbarStyles = {
            WebkitBoxOrient: "horizontal",
            flexDirection: "row"
        };
        return (
            <Router>
                <div className="fixed-sn light-blue-skin">
                    <MDBSideNav
                        logo={SITE.url + '/Uploads/logo_sm.png'}
                        triggerOpening={this.state.toggleStateA}
                        breakWidth={this.state.breakWidth}
                        bg="https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg"
                        mask="strong"
                        fixed
                    >
                        <li>
                            <ul className="social">
                                <li>
                                    <a href="#!">
                                        <MDBIcon fab icon="facebook-f" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <MDBIcon fab icon="pinterest" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <MDBIcon fab icon="google-plus-g" />
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <MDBIcon fab icon="twitter" />
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <MDBInput
                            type="text"
                            hint="Search"
                            style={{
                                color: "#fff",
                                padding: "0 10px 8px 30px",
                                boxSizing: "border-box"
                            }}
                        />
                        {this.props.menu.menus && this.generateMenus(this.props.menu.menus)}

                    </MDBSideNav>
                    <MDBNavbar style={navStyle} double expand="md" fixed="top" scrolling>
                        <MDBNavbarNav left>
                            <MDBNavItem>
                                <div
                                    onClick={this.handleToggleClickA}
                                    key="sideNavToggleA"
                                    style={{
                                        lineHeight: "32px",
                                        marginRight: "1em",
                                        verticalAlign: "middle"
                                    }}
                                >
                                    <MDBIcon icon="bars" color="white" size="2x" />
                                </div>
                            </MDBNavItem>
                            <MDBNavItem className="d-none d-md-inline" style={{ paddingTop: 5 }}>
                                নারায়ণগঞ্জ জেলা প্রবাসী ও প্রবাস ফেরত কল্যাণ পরিষদ
                            </MDBNavItem>
                        </MDBNavbarNav>
                        <MDBNavbarNav right style={specialCaseNavbarStyles}>
                            <MDBNavItem active>
                                <MDBNavLink to="#!">
                                    <MDBIcon icon="envelope" className="d-inline-inline" />{" "}
                                    <div className="d-none d-md-inline">Contact</div>
                                </MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="#!">
                                    <MDBIcon far icon="comments" className="d-inline-inline" />{" "}
                                    <div className="d-none d-md-inline">Support</div>
                                </MDBNavLink>
                            </MDBNavItem>
                            {
                                //<MDBNavItem>
                                //    <MDBNavLink to="#!">
                                //        <MDBIcon far icon="bell" />{" "}
                                //        <div className="d-none d-md-inline">Notification</div>
                                //    </MDBNavLink>
                                //</MDBNavItem>
                            }
                            <MDBNavItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret>
                                        <div className="d-none d-md-inline">Setting</div>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu right>
                                        <MDBDropdownItem onClick={this.logoutHandler}>Log out</MDBDropdownItem>
                                        <MDBDropdownItem href="/admin/myAccount">My Account</MDBDropdownItem>
                                        <MDBDropdownItem href={SITE.url}>Visit Site</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBNavbar>
                    <main style={mainStyle}>
                        <MDBContainer fluid style={{ height: 2000 }} className="mt-5">
                            { this.props.children }
                        </MDBContainer>
                    </main>
                </div>
            </Router>
        ) 
    }
}
const stateToProps = state => ({
    auth: state.auth,
    menu: state.menuRed
})
export default connect(stateToProps, { logout, loadMenus })(AdminNavMenu)