import * as Types from '../actions/types';
const init = {
    isSuccess: false,
    statusCode: null,
    message: null,
}
const registerReducer = (state = init, action) => {
    switch (action.type) {
        case Types.REGISTER_MEMBER: {
            return {
                statusCode: action.payload.statusCode,
                isSuccess: action.payload.isSuccess
            }
        }
        default: return state;
    }
}
export default registerReducer;
