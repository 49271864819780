import * as Types from './types';
import Axios from 'axios';

export const loadPaymentMode = () => dispatch => {
    Axios.get('api/Transaction/GetPaymentMode')
        .then(response => {
            dispatch({
                type: Types.LOAD_PAYMENT_MODE,
                payload: {
                    paymentModes: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadServiceHolder = (paymentModeId) => dispatch => {
    Axios.get('api/Transaction/GetServiceHolderByPaymentMode/'+paymentModeId)
        .then(response => {
            dispatch({
                type: Types.LOAD_SERVICE_HOLDER_BY_PAYMENT_MODE,
                payload: {
                    serviceHolders: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertDonation = (collection) => dispatch => {
    //console.log(collection);
    //return;
    Axios.post('api/Transaction/SaveDonation', collection)
        .then(response => {
            dispatch({
                type: Types.SAVE_DONATION,
                payload: {
                    status: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}