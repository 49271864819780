import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import {
    MDBRow, MDBCol, MDBInput,
    toast, ToastContainer, MDBBtn
} from "../../../mdbreact";
import { loadCards, insertCard } from '../../../store/actions/greetingCardAction';

const imgMaxSize = 1000000;
const imgMinSize = 200000;

const init = {
    first: 0,
    rows: 10,
    totalRecords: 0,
    cardId: 0,
    cardTitle: '',
    description: '',
    orderNo: '',
    srcUrl: '',
    imageFile: [],
    isSrcUrl: {
        'display': 'none'
    },
    isStateChange: false,
    submitText: 'Submit',    
}
class Create extends Component {

    constructor(props) {
        super(props);
        this.state = init;
    }

    componentDidMount() {
        this.props.loadCards();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.card) {
            if (nextProps.card.statusCode == 200
                && this.state.isStateChange == true) {
                toast.success(nextProps.card.message, {
                    closeButton: true
                });
                this.setState({
                    ...init
                });
            }
        }        
    }

    changeHandler = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    /*===================
     * VALIDATION HANDLER
     * =================*/
    validationHandler = () => {

        if (this.state.cardTitle == '') {
            toast.error('Card title is required!', {
                closeButton: true
            });
            return true;
        }
        if (this.submitText == 'Submit') {
            if (!this.imageFile) {
                toast.error('Card image is required!', {
                    closeButton: true
                });
                return true;
            }
        }
        return false;
    }

    handleSubmission = () => {

        if (this.validationHandler())
            return;

        let userId = this.props.auth.user.userId;

        this.setState({
            ...this.state,
            isStateChange: true,
            submitText: 'Sending...'
        });
        let orderNo = 0;
        if (this.state.orderNo != '')
            orderNo = parseInt(this.state.orderNo)

        let formData = new FormData();
        formData.append('cardId', this.state.cardId);
        formData.append('cardTitle', this.state.cardTitle);
        formData.append('ImageFile', this.state.imageFile);
        formData.append('description', this.state.description);
        formData.append('entryBy', userId);
        formData.append('editBy', userId);
        formData.append('OrderNo', orderNo);

        this.props.insertCard(formData);
    }

    /*============ IMAGE PREVIEW ========== */
    imagePreviewHandler = (files, rejectedFiles) => {
        //console.log(files);
        //return;
        //rejected image
        if (rejectedFiles.length > 0) {
            rejectedFiles[0].errors.map(err => {
                toast.error(err.code, {
                    closeButton: true
                });
            })
        }
        else {
            //process image preview
            const currentFile = files[0];
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                //console.log(reader.result);
                this.setState({
                    ...this.state,
                    imageFile: currentFile,
                    srcUrl: reader.result,
                    isSrcUrl: {
                        'display': 'block'
                    }
                })
            }, false);
            reader.readAsDataURL(currentFile);
        }
    }

    render() {
        let { cardTitle, description, orderNo, srcUrl, isSrcUrl, submitText } = this.state;
        return (
            <div>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        <MDBInput label="Card Title"
                            name="cardTitle"
                            className="mt-4"
                            value={cardTitle}
                            onChange={this.changeHandler}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        <MDBInput label="Serial No."
                            name="orderNo"
                            className="mt-4"
                            value={orderNo}
                            onChange={this.changeHandler}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        {
                            <div>
                                <div className="dropzoneImagePreview" style={isSrcUrl}>
                                    <div className="thumbsContainer">
                                        <div className="thumb">
                                            <div className="thumbInner">
                                                <img
                                                    src={srcUrl}
                                                    className="img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Dropzone onDrop={this.imagePreviewHandler} multiple={false} minSize={imgMinSize} maxSize={imgMaxSize} accept="image/*">
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzoneContainer">
                                            <div
                                                {...getRootProps({
                                                    className: 'dropzone',
                                                    //onDrop: event => event.stopPropagation()
                                                })}
                                            >
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop image here, or click for upload</p>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        }
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        <MDBInput label="Description"
                            type="textarea"
                            rows="5"
                            name="description"
                            className="mt-4"
                            value={description}
                            onChange={this.changeHandler}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12" sm="12" lg="12">
                        <MDBBtn color="success" rounded onClick={this.handleSubmission}>
                            {submitText}
                        </MDBBtn>
                        {
                            //<MDBBtn color="danger" rounded onClick={this.handleClear}>
                            //    Clear
                            //</MDBBtn>
                        }
                    </MDBCol>
                </MDBRow>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={2000}
                />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    card: state.cardReducer
})

export default connect(mapStateToProps, {
    loadCards,
    insertCard    
})(Create);
