import { combineReducers } from 'redux';
import authReducer from './authReducer';
import menuReducer from './menuReducer';
import registerReducer from './registerReducer';
import memberReducer from './memberReducer';
import settingReducer from './settingReducer';
import committeeAccessReducer from './committeeAccessReducer';
import transactionReducer from './transactionReducer';
import greedingCardReducer from './greetingCardReducer';

const rootReducers = combineReducers({
    auth: authReducer,
    menuRed: menuReducer,
    register: registerReducer,
    memberReducer: memberReducer,
    setting: settingReducer,
    committeeAccess:committeeAccessReducer,
    transaction: transactionReducer,
    cardReducer: greedingCardReducer,
})
export default rootReducers