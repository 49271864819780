import React from 'react';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { SearchForm } from '../../UIMaterial/Search';
import { FaList } from 'react-icons/fa';
import { CgMenuGridO } from 'react-icons/cg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './media.css';

const searchMedia = () => {
    return (
        <>
            <Row>
                <Col>
                    <div id="searchContent">
                        <SearchForm
                            placeholder="Search media..."
                            isSearch={true}
                            isOption1={true} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <div id="searchMediaButton">
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="primary">Photo</Button>
                            <Button variant="info">Video</Button>
                        </ButtonGroup>
                    </div>
                </Col>
                <Col md={{ span: 4, offset: 4 }}>
                    <div id="searchMediaView">
                        <ButtonGroup aria-label="Basic example">
                            <Button variant="warning"><CgMenuGridO/></Button>
                            <Button variant="success"><FaList/></Button>
                        </ButtonGroup>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default searchMedia;