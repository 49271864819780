import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon, MDBBtn } from "../../mdbreact";
//import Link from "../../mdbreact/Link";

const Footer = () => {
    return (
        <MDBFooter className="customFooter">
            <MDBContainer style={{padding:20}}>
                <MDBRow>
                    <MDBCol size="12" lg="3" md="6" sm="6">
                        <ul>
                            <li>
                                <a href="/mission">
                                    লক্ষ্য ও উদ্দেশ্য
                                </a>
                            </li>
                            <li>
                                <a href="/donar">
                                    অর্থদাতার তালিকা
                                </a>
                            </li>
                            <li>
                                <a href="/contact">
                                    যোগাযোগ করুন
                                </a>
                            </li>
                        </ul>
                    </MDBCol>

                    <MDBCol size="12" lg="4" md="6" sm="6" xs="12">
                        <ul>
                            <li>
                                <a href="/committee/center_committee">
                                    কেন্দ্রীয় কমিটি
                                </a>
                            </li>
                            <li>
                                <a href="/committee/637245730314622921">
                                    সোনারগাঁও থানা কমিটি
                                </a>    
                            </li>
                            <li>
                                <a href="/committee/637245730225240065">
                                    রূপগঞ্জ থানা কমিটি
                                </a>
                            </li>
                            <li>
                                <a href="/committee/637245730115235334">
                                    সিদ্ধিরগঞ্জ থানা কমিটি
                                </a>
                            </li>
                        </ul>
                    </MDBCol>

                    <MDBCol size="12" lg="4" md="6" sm="6" xs="12">
                        <ul>
                            <li>
                                <a href="/committee/637245730029583428">
                                    সদর থানা কমিটি
                                </a>
                            </li>
                            <li>
                                <a href="/committee/637245729723647182">
                                    বন্দর থানা কমিটি
                                </a>
                            </li>
                            <li>
                                <a href="/committee/637245733079858935">
                                    ফতুল্লা থানা কমিটি
                                </a>
                            </li>
                            <li>
                                <a href="/committee/637245730369555158">
                                    {/*<MDBIcon icon="arrow-right" className="pink-text" />*/ } আড়াইহাজার থানা কমিটি
                                </a>
                            </li>
                        </ul>
                    </MDBCol>
                    <MDBCol size="12" lg="1" md="6" sm="6" xs="12">
                        <ul className="npSocial">
                            <li>
                                <MDBBtn href="https://www.facebook.com/groups/201462730449425/" size="sm" tag="a" floating social="fb">
                                    <MDBIcon fab icon="facebook-f" />
                                </MDBBtn>
                            </li>
                            <li>
                                <MDBBtn size="sm" tag="a" floating social="gplus">
                                    <MDBIcon fab icon="google-plus-g" />
                                </MDBBtn>
                            </li>
                            <li>
                                <MDBBtn size="sm" tag="a" floating social="yt">
                                    <MDBIcon fab icon="youtube" />
                                </MDBBtn>
                            </li>
                        </ul>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className="footer-copyright text-center py-3">
                <MDBContainer fluid>
                    &copy; 2020-{new Date().getFullYear()} Copyright: <a href="https://www.syncseeker.com"> SyncSeeker.com </a>
                </MDBContainer>
            </div>
        </MDBFooter>
    );
}

export default Footer;