import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody,
    MDBBtn, MDBIcon, MDBBtnGroup, toast, ToastContainer
} from "../../../mdbreact";
import { loadUsers } from '../../../store/actions/authAction';
import {
    loadAllMenus,
    loadMenuAccessByMenu,
    loadMenuAccessByUser,
    insertMenuAccess,
    deleteMenuAccess
} from '../../../store/actions/menuAction';
import '../../../assets/css/scrollbar.css';
let timer = 0;
let delay = 200;
let prevent = false;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuLoad: false,
            isUserLoad: false,
            menuColor: 'indigo',
            menuActiveColor: 'unique',
            menuIdActive: null,
            userColor: 'cyan',
            userActiveColor: 'unique',
            userIdActive: null,
            menuAccessColor: 'purple',
            isStateChange: false,
            menuId: null,
            userId: null,
        }
    }

    componentDidMount() {
        this.props.loadAllMenus();
        this.props.loadUsers();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.menu.statusCode == 200 && this.state.isStateChange == true) {
            toast.success(nextProps.menu.message, {
                closeButton: true
            });
            this.setState({
                ...this.state,
                isStateChange:false
            })
        }
    }

    userClickHandler = (id, event) => {
        //console.log(id);
        //return;
        this.setState({
            ...this.state,
            userId: id,
            userIdActive: id,
            isMenuLoad: true,
            isUserLoad: false,
        });
        this.props.loadMenuAccessByUser(id);
    }

    menuClickHandler = (id, event) => {
        let me = this;
        timer = setTimeout(function () {
            if (!prevent) {
                me.setState({
                    ...this.state,
                    menuId: id,
                    menuIdActive: id,
                    isUserLoad: true,
                    isMenuLoad: false
                });
                me.props.loadMenuAccessByMenu(id);
            }
            prevent = false;
        }, delay);
    }

    menuDoubleClick(id, event) {
        clearTimeout(timer);
        prevent = true;

        if (!this.state.userId) {
            toast.error('Please select a user!', {
                closeButton: true
            });
            return;
        }

        this.setState({
            ...this.state,
            menuId: id,
            menuIdActive: id,
            isUserLoad: false,
            isMenuLoad: true,
            isStateChange: true
        });
        let access = {
            userId: this.state.userId,
            menuId: id,
            entryBy: this.props.auth.user.userId
        }
        //console.log(access);
        this.props.insertMenuAccess(access);        
    }

    deleteHandler = event => {
        this.state.isStateChange = true;
        this.props.deleteMenuAccess(event);
    }

    render() {
        let { menuIdActive, menuActiveColor, menuColor,
            userColor, userActiveColor, userIdActive, menuAccessColor,
            isMenuLoad, isUserLoad } = this.state;
        return (
            <div id='committeeAccessDashboard'>

                <MDBRow>
                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Users</MDBCardHeader>
                            <MDBCardBody className="scrollbar scrollbar-pink bordered-pink thin" style={{ height: 600, width: '92%' }}>
                                {
                                    this.props.auth.users &&
                                    this.props.auth.users.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.userId == userIdActive ? userActiveColor : userColor}
                                                id={c.userId}
                                                key={c.userId}
                                                onClick={this.userClickHandler.bind(this, c.userId)}
                                            >
                                                {c.name}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>                            
                        </MDBCard>
                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Menus</MDBCardHeader>
                            <MDBCardBody className="scrollbar scrollbar-pink bordered-pink thin" style={{height:600,width:'92%'}}>
                                {
                                    this.props.menu.menus &&
                                    this.props.menu.menus.map(c => {
                                        return (
                                            <MDBBtn
                                                color={c.menuId == menuIdActive ? menuActiveColor : menuColor}
                                                id={c.menuId}
                                                key={c.menuId}
                                                onClick={this.menuClickHandler.bind(this, c.menuId)}
                                                onDoubleClick={this.menuDoubleClick.bind(this, c.menuId)}
                                            >
                                                {c.menuName}
                                            </MDBBtn>
                                        )
                                    })
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>

                    <MDBCol size="12" sm="12" lg="4">
                        <MDBCard wide >
                            <MDBCardHeader color="success-color">Menu Access</MDBCardHeader>
                            <MDBCardBody className="scrollbar scrollbar-pink bordered-pink thin" style={{ height: 600, width: '92%' }}>
                                {
                                    isMenuLoad &&
                                    this.props.menu.menuAccess &&
                                    this.props.menu.menuAccess.map((c, index) => {
                                        return (
                                            <MDBBtnGroup key={index}>
                                                <MDBBtn
                                                    color={menuAccessColor}
                                                    id={c.menuAccessId}
                                                    className="btnCommitteeAccess"
                                                >
                                                    {c.menuName}
                                                </MDBBtn>
                                                <MDBBtn color="danger"
                                                    className="btnCommitteeAccessDelete"
                                                    onClick={this.deleteHandler.bind(this, c.menuAccessId)}
                                                >
                                                    <MDBIcon icon="trash" />
                                                </MDBBtn>
                                            </MDBBtnGroup>
                                        )
                                    })

                                }
                                {
                                    isUserLoad &&
                                    this.props.menu.menuAccess &&
                                    this.props.menu.menuAccess.map((c, index) => {
                                            return (
                                                <MDBBtnGroup key={index}>
                                                    <MDBBtn
                                                        color={menuAccessColor}
                                                        id={c.menuAccessId}
                                                        className="btnCommitteeAccess"
                                                    >
                                                        {c.userName}
                                                    </MDBBtn>
                                                    <MDBBtn color="danger"
                                                        className="btnCommitteeAccessDelete"
                                                        onClick={this.deleteHandler.bind(this, c.menuAccessId)}
                                                    >
                                                        <MDBIcon icon="trash" />
                                                    </MDBBtn>
                                                </MDBBtnGroup>
                                            )
                                        })

                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <ToastContainer
                        hideProgressBar={false}
                        newestOnTop={true}
                        autoClose={2000}
                    />
                </MDBRow>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    setting: state.setting,
    menu: state.menuRed
})

export default connect(mapStateToProps, {
    loadUsers,
    loadAllMenus,
    loadMenuAccessByMenu,
    loadMenuAccessByUser,
    insertMenuAccess,
    deleteMenuAccess
})(Dashboard);