import * as Types from './types';
import Axios from 'axios';

export const loadMenus = (userId) => dispatch => {
    //console.log(userId);
    Axios.get('api/Menu/LoadMenuByUserId/' + userId)
        .then(response => {
            dispatch({
                type: Types.LOAD_MENUS,
                payload: {
                    menus: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadAllMenus = () => dispatch => {
    //console.log(userId);
    Axios.get('api/Menu/LoadMenus')
        .then(response => {
            dispatch({
                type: Types.LOAD_MENUS,
                payload: {
                    menus: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadMenuAccessByMenu = (menuId) => dispatch => {
    //console.log(committeeAccess);
    Axios.get('api/Menu/LoadMenuAccessByMenuId/' + menuId)
        .then(response => {
            dispatch({
                type: Types.LOAD_MENU_ACCESS,
                payload: {
                    menuAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const loadMenuAccessByUser = (userId) => dispatch => {
    //console.log(userId);
    Axios.get('api/Menu/LoadMenuAccessByUserId/'+ userId)
        .then(response => {
            dispatch({
                type: Types.LOAD_MENU_ACCESS,
                payload: {
                    menuAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const insertMenuAccess = (menuAccess) => dispatch => {
    //console.log(committeeAccess);
    Axios.post('api/Menu/SaveMenuAccess', menuAccess)
        .then(response => {
            dispatch({
                type: Types.SAVE_MENU_ACCESS,
                payload: {
                    menuAccess: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const deleteMenuAccess = (id) => dispatch => {
    Axios.delete('api/Menu/DeleteMenuAccess/' + id)
        .then(response => {
            dispatch({
                type: Types.DELETE_MENU_ACCESS,
                payload: {
                    id: id,
                    isDelete: response.data
                }
            })
        })
        .catch(error => {
            console.log(error);
        })
}