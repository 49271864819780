import * as Types from '../actions/types';

const settingReducer = (state = [], action) => {
    switch (action.type) {
        case Types.LOAD_DESIGNATIONS: {
            return {
                ...state,
                designations: action.payload.designations
            }
        }
        case Types.SAVE_DESIGNATION: {
            let newDesignations = [...state.designations];
            let filterDesignations = newDesignations.filter(up => up.designationId !== action.payload.designation.designationId);
            filterDesignations.unshift(action.payload.designation);
            return {
                designations: filterDesignations,
                statusCode: action.payload.statusCode,
                isSuccess: action.payload.isSuccess
            }
        }
        case Types.LOAD_COMMITTEES: {
            return {
                ...state,
                committees: action.payload.committees
            }
        }
        case Types.SAVE_COMMITTEE: {
            let newCommittees = [...state.committees];
            let filterCommittees = newCommittees.filter(up => up.committeeId !== action.payload.committee.committeeId);
            filterCommittees.unshift(action.payload.committee);
            return {
                committees: filterCommittees,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_OCCUPATIONS: {
            return {
                ...state,
                occupations: action.payload.occupations
            }
        }
        case Types.SAVE_OCCUPATION: {
            let newOccupations = [...state.occupations];
            let filterOccupations = newOccupations.filter(up => up.occupationId !== action.payload.occupation.occupationId);
            filterOccupations.unshift(action.payload.occupation);
            return {
                occupations: filterOccupations,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_COUNTRIES: {
            return {
                ...state,
                countries: action.payload.countries
            }
        }
        case Types.SAVE_COUNTRY: {
            let newCountries = [...state.countries];
            let filterCountries = newCountries.filter(up => up.countryId !== action.payload.country.countryId);
            filterCountries.unshift(action.payload.country);
            return {
                countries: filterCountries,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_UPAZILAS: {
            return {
                ...state,
                upazilas: action.payload.upazilas
            }           
        }
        case Types.SAVE_UPAZILA: {
            let newUpazilas = [...state.upazilas];
            let filterUpazilas = newUpazilas.filter(up => up.upazilaId !== action.payload.upazila.upazilaId);
            filterUpazilas.unshift(action.payload.upazila);
            return {
                upazilas: filterUpazilas,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_UNIONS: {            
            return {
                ...state,
                unions: action.payload.unions
            }
        }
        case Types.LOAD_UNIONS_BY_UPAZILA: {
            return {
                ...state,
                unions: action.payload.unions
            }
        }
        case Types.SAVE_UNION: {
            let newUnions = [...state.unions];
            let filterUnion = newUnions.filter(up => up.unionId !== action.payload.union.unionId); 
            filterUnion.unshift(action.payload.union);
            return {
                unions: filterUnion,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_MUNICIPALITIES: {
            return {
                ...state,
                municipalities: action.payload.municipalities
            }
        }
        case Types.LOAD_MUNICIPALITIES_BY_DISTRICT: {
            return {
                municipalities: action.payload.municipalities
            }
        }
        case Types.SAVE_MUNICIPALITY: {
            let newMunicipalities = [...state.municipalities];
            let filterMunicipalities = newMunicipalities.filter(up => up.municipalityId !== action.payload.municipality.municipalityId);
            filterMunicipalities.unshift(action.payload.municipality);
            return {
                municipalities: filterMunicipalities,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_THANAS: {
            return {
                ...state,
                thanas: action.payload.thanas
            }
        }
        case Types.LOAD_THANAS_BY_DISTRICT: {
            return {
                thanas: action.payload.thanas
            }
        }
        case Types.SAVE_THANA: {
            let newThanas = [...state.thanas];
            let filterThanas = newThanas.filter(up => up.thanaId !== action.payload.thana.thanaId);
            filterThanas.unshift(action.payload.thana);
            return {
                thanas: filterThanas,
                statusCode: action.payload.statusCode
            }
        }
        case Types.LOAD_MEMBER_FIELDS: {
            return {
                ...state,
                fields: action.payload.fields
            }
        }
        default: return state;
    }
}
export default settingReducer;
