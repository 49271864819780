import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon } from "../../../mdbreact";
import { SearchForm } from '../../UIMaterial/Search';

const PendingList = ({ isLoading, members, searchTerm, changeHandler, clickHandler, fbHandler, editHandler, deleteHandler }) => {

    /*=======================
     * PROFILE PIC TEMPLATE
     * =====================*/
    const profileTemplate = (rowData) => {
        let imgData = 'np_default_avatar.jpg';
        if (rowData.userProfileName)
            imgData = rowData.userProfileName;
        const src = window.location.protocol + '//' + window.location.host + '/Uploads/Members/Images/' + imgData;
        return (
            <>
                <img alt={"test purpose"} src={src} width="32" style={{ verticalAlign: 'middle', borderRadius: '50%', width: '50px', height: '50px', border: '3px solid rgba(233,230,230,1)' }} />
                <span style={{ verticalAlign: 'middle', marginLeft: '.5em' }}>{rowData.memberNameBd}</span>
            </>
        );
    }
    /*=======================
     * ACTION TEMPLATE
     * =====================*/
    const actionTemplate = (rowData, column) => {
        return <div id='admin_pending_btn'>
            <MDBBtn tag="a" size="sm" floating
                gradient="blue"
                onClick={() => fbHandler(rowData.fbLink)}>
                <MDBIcon fab icon="facebook" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                gradient="aqua"
                onClick={() => editHandler(rowData.memberId)}>
                <MDBIcon icon="edit" />
            </MDBBtn>
            <MDBBtn tag="a" size="sm" floating
                gradient="peach"
                onClick={() => deleteHandler(rowData.motherName)}>
                <MDBIcon far icon="trash-alt" />
            </MDBBtn>
        </div>;
    }

    return (
        <MDBCard wide >
            <MDBCardHeader color="success-color">
                <div className="listTitle"><h3>Pending Member List</h3></div>
                <div className="searchContainer">
                    <SearchForm
                        placeholder="Search member..."
                        searchTerm={searchTerm}
                        isSearch={true}
                        changeHandler={changeHandler}
                        searchHandler={clickHandler} />
                </div>
            </MDBCardHeader>
            <MDBCardBody>
                {
                    isLoading === true ? (
                        <div><p>Waiting until data loading...</p></div>
                    ) :
                        members &&
                            members.length > 0 ? (
                            <DataTable value={members} paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                                responsive={true}>
                                <Column field="memberNameBd" header="" body={profileTemplate} headerStyle={{ width: '17em' }} />
                                <Column field="memberCode" header="ID" />
                                <Column field="thana" header="Thana" />
                                <Column field="immigrationCountry" header="Country" />
                                <Column field="mobileNo" header="Mobile No" />
                                <Column field="occupation" header="Occupation" />
                                <Column field="thana" header="" body={actionTemplate} headerStyle={{ width: '17em' }} />
                            </DataTable>
                        ) : <div><p>No, Member records found!</p></div>
                }

            </MDBCardBody>
        </MDBCard>
    );
};

export default PendingList;